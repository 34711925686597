import type { Action, App, Workspace } from '@prisma/client'
import { useFormik } from 'formik'
import { useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Badge, Button, Dialog, Select, toast } from '@/client/components'
import { FieldError } from '@/common/components/forms'
import { TIER_ACTIONS_FEATURE_ID } from '@/common/config/tierConstants'
import { useCheckWorkspaceFeatureAccess, useFetchApps, useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { FeatureGate } from '../../actions/FeatureGate'

function CopyActionForm({
  action,
  workspace,
  setOpen,
}: {
  action: Action
  workspace: Workspace
  setOpen: (val: boolean) => void
}) {
  const { apps, isLoading: isLoadingApps } = useFetchApps(workspace.id)

  const appRecord: Record<number, App> = {}
  apps?.forEach((app) => {
    appRecord[app.id] = app
  })

  const copyAction = api.action.copy.useMutation()
  const utils = api.useContext()
  const { user } = useUser()

  const [isLoading, setLoading] = useState(false)

  const { hasFeatureAccess, isLoading: isLoadingFeatureAccess } = useCheckWorkspaceFeatureAccess({
    workspaceId: workspace.id,
    featureId: TIER_ACTIONS_FEATURE_ID,
  })

  function onSuccess(newAction: Action) {
    toast.success({
      title: `${action.name} copied`,
      description: `A forked copy is now available`,
      action: {
        label: 'View',
        onClick: () => {
          void window.open(
            `/${workspace.slug}/apps/${appRecord[newAction.appId]?.slug || ''}/actions/${newAction.slug}`
          )
        },
      },
    })
    setOpen(false)
  }

  const schema = z.object({
    name: z.string().max(200, { message: 'Name must be less than 200 characters' }),
    appId: z.number().min(1, { message: 'Please select an app' }),
  })

  type FormSchema = z.infer<typeof schema>
  const formik = useFormik<FormSchema>({
    initialValues: {
      name: action.name + ' (Copy)',
      appId: action.appId,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      const currentAppId = action.appId
      await copyAction.mutateAsync(
        { ...values, actionId: action.id },
        {
          onSettled: () => {
            setLoading(false)
          },
          onSuccess: (newAction) => {
            if (user) {
              const id = user.id ? user.id : ''
              const email = user.email ? user.email : ''
              const uname = user.name ? user.name : ''
              identify_and_group(id, email, uname, workspace?.slug)
            }
            track(EVENT_NAMES.action_copied, {
              // event attribute basics
              'Workspace ID': workspace.id,
              'Workspace Name': workspace.name,
              'User ID': user?.id,
              'User Email': user?.email,
              'User Name': user?.name,
              // event attribute metadata
              'Original Action Name': action.name,
              'Original Action ID': values.appId,
              'Action Name': values.name,
              'Action Type': action.action_type,
            })
            resetForm()
            onSuccess(newAction)
          },
          onError: (error) => {
            toast.error({
              title: 'An error occurred',
              description: error.message,
            })
          },
        }
      )
      await utils.app.getBySlug.invalidate({
        slug: appRecord[values.appId]?.slug || '',
      })
      await utils.app.getBySlug.invalidate({
        slug: appRecord[currentAppId]?.slug || '',
      })
    },
  })

  if (!hasFeatureAccess) {
    return (
      <FeatureGate
        open={true}
        setOpen={setOpen}
        workspace={workspace}
        loading={isLoadingFeatureAccess}
      />
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="p-6">
        <div>
          <div>
            <h3 className="text-lg font-medium leading-6">Copy Action</h3>
            <p className="text-sm pt-3 text-grey-600 dark:text-zinc-600">
              Copying <Badge>{action.name}</Badge> will duplicate the existing Action, including
              model config, prompt, context, and/or skills. Will not make changes to data, datasets,
              experiments, or fine-tuned models.
            </p>
            <div className="mt-6">
              <p className="text-sm">Action name</p>
              <div className="mt-2">
                <div className="flex flex-row rounded-md">
                  <input
                    name="name"
                    placeholder="Detect Bias in Feature Request"
                    type="text"
                    autoComplete="off"
                    id="key"
                    maxLength={50}
                    className="block w-full min-w-0 flex-1 rounded-md bg:white border-grey-300 focus:border-grey-500 focus:ring-grey-500 dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus-ring-white dark:focus:border-white sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                </div>
                <FieldError fieldName="name" formik={formik} />
              </div>
            </div>
            <div className="mt-6">
              <p className="text-sm font-medium">Copy Action to...</p>
              <div className="mt-2">
                {isLoadingApps ? (
                  <div className="flex animate-pulse space-x-4">
                    <div className="flex-1 space-y-2">
                      <div className="h-2 rounded bg-grey-700 dark:bg-zinc-800" />
                      <div className="h-2 rounded bg-grey-700 dark:bg-zinc-800" />
                    </div>
                  </div>
                ) : (
                  <Select.Field
                    id="appId"
                    hideLabel
                    options={
                      apps
                        ? apps.map((app) => {
                            return {
                              value: app.id.toString(),
                              label: app.name,
                            }
                          })
                        : []
                    }
                    value={formik.values.appId.toString()}
                    onChange={(value) => {
                      void formik.setFieldValue('appId', parseInt(value))
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 bg-grey-50 px-4 py-5 dark:bg-black sm:flex sm:px-6">
        <Button variant={'outline'} onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button disabled={isLoading} type="submit">
          {isLoading ? 'Copying...' : 'Copy'}
        </Button>
      </div>
    </form>
  )
}

export const CopyActionModal = ({
  open,
  setOpen,
  workspace,
  action,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  workspace: Workspace
  action: Action | null
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          {action && <CopyActionForm action={action} workspace={workspace} setOpen={setOpen} />}{' '}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
