import { Badge } from '@/client/components'
import { Loader } from '@/common/components'
import { useFetchIndexStatus } from '@/common/hooks'
import type { DetailedContext } from '@/common/types/context'
import { ProcessingLog } from './ContextProcessingLog'

export function ProcessingStatus({
  context,
  showLog,
}: {
  context: DetailedContext
  showLog?: boolean
}) {
  const { isLoading } = useFetchIndexStatus(context)

  if (isLoading) {
    return (
      <>
        <Loader className="ml-5 h-5 w-5 text-grey-500" />
      </>
    )
  }

  return (
    <div className="inline-flex items-center">
      {/* rounded-full bg-blue-50 px-3 py-0.5 text-sm font-medium text-blue-800 ring-1 ring-blue-300/50 */}
      <Badge color={context.processed ? 'green' : 'default'}>
        {context.processed ? 'Ready' : 'Processing'}
      </Badge>
      {showLog && <ProcessingLog index={context} />}
    </div>
  )
}
