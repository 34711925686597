/* eslint-disable react/jsx-no-undef */
import { Card } from '@tremor/react'
import { Badge } from '@/client/components'
import { useFetchEvalResultsByPeriod, useFetchLatestEvalRunResults } from '@/common/hooks'
import type { DetailedEval } from '@/common/types/eval'

export const EvalPassRateOverview = ({ evalRecord }: { evalRecord: DetailedEval }) => {
  const { data, isLoading } = useFetchEvalResultsByPeriod({
    evalId: evalRecord.id,
    days: 7,
    groupByPeriod: 'lifetime',
  })

  const { data: latestData, isLoading: isLatestLoading } = useFetchLatestEvalRunResults({
    evalId: evalRecord.id,
  })

  if (isLoading || !data || isLatestLoading || !latestData) {
    return null
  }

  const evalTypesNames = (evalRecord.evalTypes || []).map(({ name }) => name)

  const totalPassed = data.reduce((acc, { passed }) => {
    return acc + Number(passed)
  }, 0)
  const total = data.reduce((acc, { total }) => {
    return acc + Number(total)
  }, 0)

  const totalPassRate = totalPassed / total

  const mostRecentData = latestData
  const mostRecentPassRateObj =
    Array.isArray(mostRecentData) && mostRecentData.length > 0
      ? mostRecentData.reduce(
          (acc, val: { passed: number }) => {
            acc.totalPassed += Number(val.passed)
            acc.itemCount += 1
            return acc
          },
          { totalPassed: 0, itemCount: 0 }
        )
      : { totalPassed: 0, itemCount: 0 }

  const mostRecentPassRate =
    mostRecentPassRateObj.itemCount > 0
      ? mostRecentPassRateObj.totalPassed / mostRecentPassRateObj.itemCount
      : 0

  return (
    <Card className="h-full">
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-2">
          <h5 className="text-tremor-content dark:text-dark-tremor-content text-sm">
            Recent Pass Rate
          </h5>
          {isNaN(mostRecentPassRate) ? (
            <div className="flex justify-center h-full items-center text-grey-400">No data.</div>
          ) : (
            <>
              <p className="text-tremor-metric text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
                {(mostRecentPassRate * 100).toFixed(0)}%
              </p>
              <Badge
                variant="delta"
                delta={
                  parseFloat((mostRecentPassRate * 100).toFixed(2)) >=
                  parseFloat((totalPassRate * 100).toFixed(2))
                    ? 'moderateIncrease'
                    : 'moderateDecrease'
                }
                className={{ root: 'mt-1' }}
              >
                {((mostRecentPassRate - totalPassRate) * 100).toFixed(0)}%
              </Badge>
            </>
          )}
        </div>
        <div>
          <h5 className="text-tremor-content dark:text-dark-tremor-content text-sm">
            Average Pass Rate
          </h5>
          {isNaN(totalPassRate) ? (
            <div className="flex justify-center h-full items-center text-grey-400 dark:text-zinc-600">
              No data.
            </div>
          ) : (
            <p className="text-tremor-metric text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
              {(totalPassRate * 100).toFixed(0)}%
            </p>
          )}
        </div>
      </div>

      <div className="mt-6 space-y-4">
        <h5 className="text-tremor-content dark:text-dark-tremor-content text-sm">
          Evaluator Pass Rate
        </h5>
        <div className="flex flex-col gap-2">
          {latestData.length > 0 &&
            evalTypesNames
              .map((evalTypeName) => {
                const filteredData = latestData.filter((d) => d.evalTypeName === evalTypeName)
                const avgRate =
                  filteredData.reduce(
                    (acc, { passed, total }) => acc + Number(passed) / Number(total),
                    0
                  ) / filteredData.length

                return { evalTypeName, avgRate }
              })
              .map(({ evalTypeName, avgRate }, i) => {
                const color = avgRate >= 0.8 ? 'green' : avgRate >= 0.5 ? 'orange' : 'red'
                return (
                  <Badge key={`${evalTypeName ?? 'unknown'}-${i}`} color={color}>
                    {(avgRate * 100).toFixed(0)}% {evalTypeName}
                  </Badge>
                )
              })}
        </div>
      </div>
    </Card>
  )
}
