import { json } from '@codemirror/lang-json'
import type { Action, Prisma } from '@prisma/client'
import CodeMirror from '@uiw/react-codemirror'
import { Plus, Tool01, Trash03, Variable } from '@untitled-ui/icons-react'
import { EditorView } from 'codemirror'
import { Formik } from 'formik'
import type {
  ChatCompletionAssistantMessageParam,
  ChatCompletionContentPartImage,
  ChatCompletionMessageToolCall,
  ChatCompletionTool,
  ChatCompletionToolMessageParam,
  FunctionParameters,
} from 'openai/resources'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { AddMessageIcon } from '@/client/assets/icons/icons'
import {
  Button,
  Dialog,
  Input,
  Loader,
  MultiSelect,
  Select,
  Separator,
  Switch,
  Textarea,
  toast,
} from '@/client/components'
import useCodeMirrorTheme from '@/client/components/Codemirror/hook'
import type { ComboboxMultipleOption } from '@/client/components/Combobox/Multiple/types'
import useTextareaWithAdaptiveHeight from '@/client/components/Textarea/hook'
import { ToolCall } from '@/client/containers/views/Studio/components/Tabs/Chat/components/Main/components/Messages/components/ChatMessage'
import type { MessageType } from '@/client/containers/views/Studio/components/Tabs/Chat/types'
import { cn } from '@/client/utils'
import { EditableImage, imagesPlugin } from '@/client/utils/codemirror/images'
import { FieldError, Select as SimpleSelect } from '@/common/components'
import { useFetchDatasetTags } from '@/common/hooks/datasets'
import type {
  Message as MessageCanonicalType,
  MessageContentItem,
  RefusalContentItem,
} from '@/common/types/messages'
import { DATASET_LABELS } from '@/lib/finetunes'
import type { DatasetItemWithData } from '@/server/api/routers/datasetItem/router'
import type { DatasetItemInput } from '@/server/service/dataset'
import { api } from '@/utils'

type JsonInput = Record<string, unknown> | MessageType[] | DatasetItemInput
type Input = string | JsonInput | undefined

const toolParametersPlaceholder = {
  type: 'object',
  required: ['text', 'to_language'],
  properties: {
    text: {
      type: 'string',
      description: 'The text to translate',
    },
    to_language: {
      type: 'string',
      description: 'The target language, eg. Spanish',
    },
  },
}

const toolCallPlaceholder = {
  id: 'unique_tool_call_id',
  type: 'function',
  function: {
    name: 'tool_name',
    arguments: '{"arg": "arg_value"}',
  },
}

export const DatasetItemInputEditor = ({
  action,
  input,
  onChange,
}: {
  action?: Action
  input: string
  onChange?: (newValue: string) => void
}) => {
  const [messages, setMessages] = useState<MessageCanonicalType[] | undefined>()
  const [promptVariables, setPromptVariables] = useState<Record<string, string>>({})
  const [tools, setTools] = useState<ChatCompletionTool[] | undefined>()

  useEffect(() => {
    let parsedInput: unknown
    try {
      parsedInput = JSON.parse(input) as Prisma.JsonValue
    } catch (error) {
      parsedInput = input
    }

    if (Array.isArray(parsedInput)) {
      setMessages(parsedInput as MessageCanonicalType[])
    } else if ((parsedInput as MessageCanonicalType)?.role) {
      setMessages([parsedInput as MessageCanonicalType])
    } else if (parsedInput && typeof parsedInput === 'object') {
      const { messages, tools, ...vars } = parsedInput as DatasetItemInput
      setMessages(messages as MessageCanonicalType[])
      setPromptVariables(vars as Record<string, string>)
      setTools(tools as ChatCompletionTool[])
    } else {
      setMessages([
        {
          role: 'user',
          content: parsedInput as string,
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const computedInput = useMemo(() => {
    return JSON.stringify(
      {
        messages,
        tools: tools && tools.length > 0 ? tools : undefined,
        ...promptVariables,
      },
      null,
      2
    )
  }, [messages, tools, promptVariables])

  useEffect(() => {
    if (onChange) {
      onChange(computedInput)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedInput])

  const templateMessages = useMemo(() => {
    try {
      return JSON.parse(action?.prompt || '[]') as MessageCanonicalType[]
    } catch (error) {
      return undefined
    }
  }, [action])

  const onToolChange = useCallback(
    (tool: ChatCompletionTool, index: number) => {
      const newTools = [...(tools || [])]
      newTools[index] = tool
      setTools(newTools)
    },
    [tools]
  )
  const onToolRemove = useCallback(
    (index: number) => {
      const newTools = [...(tools || [])]
      newTools.splice(index, 1)
      setTools(newTools)
    },
    [tools]
  )

  return (
    <div className={'flex flex-col h-full justify-between gap-3'}>
      {templateMessages && templateMessages?.length > 0 && (
        <label htmlFor="input" className="py-2 block text-sm text-grey-700">
          Template Messages (defined in the {action?.name} action)
        </label>
      )}
      {templateMessages?.map((message, index) => (
        <Message
          key={index}
          options={['user', 'assistant', 'system', 'tool']}
          message={message}
          isReadonly={true}
        />
      ))}

      {templateMessages && templateMessages?.length > 0 && (
        <>
          <Separator orientation={'horizontal'} className={'mx-auto w-12 mt-4'} />

          <label htmlFor="input" className="py-2 block text-sm text-grey-700">
            Dataset Messages
          </label>
        </>
      )}

      {messages?.map((message, index) => (
        <Message
          key={index}
          onChange={(newValue) => {
            const newMessages = [...(messages ?? [])]
            newMessages[index] = newValue
            setMessages(newMessages)
          }}
          onRemove={() => {
            const newMessages = [...(messages ?? [])]
            newMessages.splice(index, 1)
            setMessages(newMessages)
          }}
          options={['user', 'assistant', 'tool']}
          message={message}
          isReadonly={false}
        />
      ))}

      {tools && tools.length > 0 && (
        <>
          <Separator orientation={'horizontal'} className={'mx-auto w-12 my-4'} />
          <div className="flex flex-col gap-2">
            {tools?.map((tool, index) => (
              <MemoizedTool
                key={index}
                index={index}
                tool={tool}
                onChange={onToolChange}
                onRemove={onToolRemove}
              />
            ))}
          </div>
          {Object.entries(promptVariables).length === 0 && (
            <Separator orientation={'horizontal'} className={'mx-auto w-12 my-4'} />
          )}
        </>
      )}

      {Object.entries(promptVariables).length > 0 && (
        <>
          <Separator orientation={'horizontal'} className={'mx-auto w-12 my-4'} />
          <div className="flex flex-col space-y-2">
            {Object.entries(promptVariables).map(([key, value], index) => (
              <div key={index} className="flex flex-row gap-3">
                <input
                  className="italic text-sm border border-grey-300 bg-transparent rounded-md"
                  type="text"
                  placeholder="Variable Name"
                  value={key}
                  onChange={(e) => {
                    if (e.target.value) {
                      const newVars = { ...promptVariables }
                      newVars[e.target.value] = newVars[key] || ''
                      delete newVars[key]
                      setPromptVariables(newVars)
                    }
                  }}
                />
                <input
                  className="font-mono text-sm border border-grey-300 bg-transparent rounded-md"
                  type="text"
                  placeholder="Variable Value"
                  value={value}
                  onChange={(e) => {
                    const newVars = { ...promptVariables }
                    newVars[key] = e.target.value
                    setPromptVariables(newVars)
                  }}
                />
                <div>
                  <Button
                    size="sm"
                    variant="outline"
                    icon={Trash03}
                    onClick={() => {
                      const newVars = { ...promptVariables }
                      delete newVars[key]
                      setPromptVariables(newVars)
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <Separator orientation={'horizontal'} className={'mx-auto w-12 my-4'} />
        </>
      )}

      <div className={'flex flex-row gap-2 w-full items-center justify-between'}>
        <div className="flex flex-row items-center space-x-3">
          <Button
            className={'w-max'}
            variant={'outline'}
            startIcon={AddMessageIcon}
            onClick={() => {
              const lastMessageRole = messages?.[messages.length - 1]?.role
              const newRole = lastMessageRole === 'user' ? 'assistant' : 'user'
              setMessages([
                ...(messages || []),
                newRole === 'user'
                  ? { content: '', role: newRole }
                  : { role: newRole, content: '', refusal: '' },
              ])
            }}
          >
            Message
          </Button>

          <Button
            className={'w-max'}
            variant={'outline'}
            startIcon={Tool01}
            onClick={() => {
              setTools([
                ...(tools || []),
                {
                  function: {
                    name: `tool_${tools?.length || 0 + 1}`,
                    description: `Tool ${tools?.length || 0 + 1} description`,
                    parameters: toolParametersPlaceholder,
                  },
                  type: 'function',
                },
              ])
            }}
            tooltip={{
              content: 'Add tool definition',
              side: 'top',
            }}
          >
            Tool
          </Button>

          <Button
            className={'w-max'}
            variant={'outline'}
            startIcon={Variable}
            onClick={() => {
              setPromptVariables({
                ...promptVariables,
                [`var_${Object.keys(promptVariables).length + 1}`]: `value_${
                  Object.keys(promptVariables || {}).length + 1
                }`,
              })
            }}
            tooltip={{
              content: 'Add variable',
              side: 'top',
            }}
          >
            Variable
          </Button>
        </div>
      </div>
    </div>
  )
}

export const DatasetItemOutputEditor = ({
  output,
  onChange,
}: {
  output: string
  onChange?: (newValue: string) => void
}) => {
  const [message, setMessage] = useState<MessageCanonicalType | string>(output)

  useEffect(() => {
    let maybeJsonOutput: MessageCanonicalType | undefined
    try {
      maybeJsonOutput = JSON.parse(output) as MessageCanonicalType
      if (maybeJsonOutput?.role) {
        setMessage(maybeJsonOutput)
      }
    } catch (e) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const computedOutput = useMemo(() => {
    if (typeof message == 'object') {
      return JSON.stringify(message, null, 2)
    } else {
      return message
    }
  }, [message])

  useEffect(() => {
    if (onChange) {
      onChange(computedOutput)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedOutput])

  return (
    <div className={'flex flex-col h-full justify-between'}>
      {typeof message === 'object' ? (
        <Message
          onChange={(newValue) => {
            setMessage(newValue)
          }}
          options={['assistant']}
          message={message}
          isReadonly={false}
        />
      ) : (
        <Textarea
          id="output"
          label="Output"
          hideLabel
          autoExpand
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
        />
      )}
    </div>
  )
}

const Message = ({
  options,
  message,
  isReadonly,
  onChange,
  onRemove,
}: {
  options: string[]
  message: MessageCanonicalType
  isReadonly: boolean
  onChange?: (newValue: MessageCanonicalType) => void
  onRemove?: () => void
}) => {
  const [content, setContent] = useState<MessageCanonicalType['content']>(message.content || '')
  const [role, setRole] = useState<'user' | 'function' | 'assistant' | 'system' | 'tool'>(
    message.role
  )
  const [toolCalls, setToolCalls] = useState<ChatCompletionMessageToolCall[] | undefined>(
    (message as ChatCompletionAssistantMessageParam).tool_calls
  )
  const [toolCallId, setToolCallId] = useState<string | undefined>(
    (message as ChatCompletionToolMessageParam).tool_call_id
  )

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  const computedMessage: MessageCanonicalType = useMemo(() => {
    return {
      content,
      role,
      tool_calls: toolCalls,
      tool_call_id: toolCallId,
    } as MessageCanonicalType
  }, [content, role, toolCalls, toolCallId])

  useEffect(() => {
    if (onChange) {
      onChange(computedMessage)
    }
  }, [onChange, computedMessage])

  const onUpdateToolCall = useCallback(
    (toolCall: ChatCompletionMessageToolCall, index: number) => {
      const newToolCalls = [...(toolCalls || [])]
      newToolCalls[index] = toolCall
      setToolCalls(newToolCalls)
    },
    [toolCalls]
  )

  const onRemoveToolCall = useCallback(
    (index: number) => {
      const newToolCalls = [...(toolCalls || [])]
      newToolCalls.splice(index, 1)
      setToolCalls(newToolCalls)
    },
    [toolCalls]
  )

  const addToolCall = useCallback(() => {
    const newToolCalls = [
      ...(toolCalls || []),
      toolCallPlaceholder as ChatCompletionMessageToolCall,
    ]
    setToolCalls(newToolCalls)
  }, [toolCalls])

  const onChangeRole = useCallback((role: 'user' | 'assistant') => {
    setRole(role)
  }, [])

  const onChangeContent = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value)
  }, [])

  const onChangeToolCallId = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setToolCallId(e.target.value)
  }, [])

  const onImageUrlChange = useCallback((url: string, index: number) => {
    const newContent = [...(content as ChatCompletionContentPartImage[])]
    let tmpContent = newContent[index]
    if (!tmpContent) {
      tmpContent = { image_url: { url: '' }, type: 'image_url' }
    }
    tmpContent.image_url.url = url
    newContent[index] = tmpContent
    setContent(newContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex relative flex-row gap-2 group p-1 border dark:border-zinc-800 rounded-md">
      <Select.Root disabled={isReadonly} value={message.role} onValueChange={onChangeRole}>
        <Select.Trigger
          disabled={isReadonly || options.length === 1}
          className={
            'border-0 shadow-none flex-none px-3 capitalize w-36 bg-transparent disabled:bg-transparent disabled:text-grey-500'
          }
        >
          <Select.Value />
        </Select.Trigger>

        <Select.Content>
          {options.map((option, index) => {
            return (
              <Select.Item key={index} value={option} className={'capitalize'}>
                {option}
              </Select.Item>
            )
          })}
        </Select.Content>
      </Select.Root>
      <div className="flex flex-col gap-2 w-full">
        {typeof message.content === 'string' ? (
          <Textarea
            ref={textAreaRef}
            className="[&_textarea]:resize-none w-full font-inter"
            textAreaClassName={cn({ 'text-grey-700 bg-transparent': isReadonly })}
            id="message"
            label="Message"
            disabled={isReadonly}
            hideLabel
            autoExpand
            placeholder={
              isReadonly
                ? ''
                : message.role === 'system'
                  ? 'Enter an additional system message...'
                  : message.role === 'tool'
                    ? 'Enter the tool message...'
                    : message.role === 'user'
                      ? 'Enter your user message...'
                      : message.role === 'assistant'
                        ? 'Enter the assistant message...'
                        : 'Enter the message...'
            }
            variant={'noBorder'}
            value={message.content || ''}
            onChange={onChangeContent}
          />
        ) : (
          (message.content as (MessageContentItem | RefusalContentItem)[])?.map((part, i) => (
            <>
              {part.type === 'text' ? (
                <>{part.text}</>
              ) : part.type === 'image_url' ? (
                <EditableImage
                  url={part.image_url.url}
                  key={i}
                  index={i}
                  onChange={onImageUrlChange}
                />
              ) : (
                <>{part.refusal}</>
              )}
            </>
          ))
        )}

        {message.role == 'tool' && (
          <Input
            id="tool_call_id"
            label="Tool Call ID"
            placeholder="Enter the tool call ID"
            hideLabel
            value={toolCallId}
            onChange={onChangeToolCallId}
          />
        )}

        {message.role == 'assistant' && toolCalls && (
          <div className="flex flex-col gap-2">
            {toolCalls.map((toolCall, i) => (
              <ToolCall
                key={i}
                index={i}
                toolCall={toolCall}
                onUpdated={onUpdateToolCall}
                onRemove={onRemoveToolCall}
              />
            ))}
          </div>
        )}

        {message.role == 'assistant' && (
          <Button
            startIcon={Plus}
            onClick={addToolCall}
            size="md"
            variant="outline"
            className="w-max mb-2"
          >
            Tool Call
          </Button>
        )}
      </div>

      {onRemove && (
        <div className="sticky top-0 p-2 flex gap-3 h-fit">
          <Button
            size="sm"
            className={cn('group-hover:opacity-100', isReadonly ? 'hidden' : 'opacity-0')}
            variant="outline"
            icon={Trash03}
            onClick={() => {
              if (isReadonly || !onRemove) return
              onRemove()
            }}
          />
        </div>
      )}
    </div>
  )
}

const Tool = ({
  index,
  tool,
  onChange,
  onRemove,
  isReadonly,
}: {
  index: number
  tool: ChatCompletionTool
  onChange?: (newValue: ChatCompletionTool, index: number) => void
  onRemove?: (index: number) => void
  isReadonly?: boolean
}) => {
  const [error, setError] = useState<string | null>(null)

  const { extensions, theme } = useCodeMirrorTheme({ extensions: [json()] })

  const functionDefinitionSchema = z
    .object({
      value: z.string({ required_error: 'Cannot be blank' }),
    })
    .superRefine((data, ctx) => {
      const functionSchema = z.object(
        {
          parameters: z.record(z.string(), z.any(), {
            required_error: 'The parameters attribute is required.',
          }),
        },
        {
          required_error: 'The function attribute is required.',
        }
      )

      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const json_definition = JSON.parse(data.value)

        const parsed = functionSchema.safeParse(json_definition)

        if (!parsed.success) {
          console.log('parsed.error', parsed.error.format())
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['value'],
            message: parsed.error?.issues.map((issue) => issue.message).join('\n'),
          })
        }
      } catch (e) {
        if (/%\{[^}]+\}/.test(data.value)) {
          // contains a %{var}, so just let it go through
        } else {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['value'],
            message: 'Definition JSON is not valid',
          })
        }
      }

      return {}
    })

  const onChangeParameters = useCallback(
    (val: string) => {
      const parsed = functionDefinitionSchema.safeParse({ value: val })

      if (parsed.success) {
        const jsonParams = JSON.parse(val) as Record<string, FunctionParameters>

        const updatedTool = {
          ...tool,
          function: {
            ...tool.function,
            parameters: jsonParams.parameters,
          },
        }
        setError(null)
        onChange && onChange(updatedTool, index)
      } else {
        setError(parsed.error?.issues.map((issue) => issue.message).join('\n'))
        console.log('parsed.error', parsed.error)
      }
    },
    [functionDefinitionSchema, tool, onChange, index]
  )

  return (
    <div className="grid grid-cols-1 border divide-y dark:divide-zinc-800 dark:border-zinc-800 rounded-md group overflow-hidden">
      <div className="flex relative flex-row gap-2">
        <div className="text-xs text-grey-800 dark:text-zinc-600 flex gap-1 w-full my-2 p-2">
          <Tool01 className="inline-block w-4 h-4 mx-2 mt-2" />
          <div className="flex flex-col gap-1">
            <input
              className="font-mono text-sm border-none bg-transparent rounded-md dark:focus:ring-white"
              value={tool.function.name}
              onChange={(e) => {
                tool.function.name = e.target.value.replace(/[^a-zA-Z0-9]/g, '_')
                if (tool.function.name != '') {
                  onChange && onChange(tool, index)
                }
              }}
            />

            <div className="italic text-grey-600 dark:text-zinc-600">
              <input
                className="font-mono text-sm border-none bg-transparent rounded-md dark:focus:ring-white"
                value={tool.function.description}
                onChange={(e) => {
                  tool.function.description = e.target.value
                  if (tool.function.description != '') {
                    onChange && onChange(tool, index)
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="sticky top-0 p-3 flex gap-3 h-fit">
          <Button
            size="sm"
            className={cn('group-hover:opacity-100', isReadonly ? 'hidden' : 'opacity-0')}
            variant="outline"
            icon={Trash03}
            onClick={() => {
              if (isReadonly || !onRemove) return
              onRemove(index)
            }}
          />
        </div>
      </div>

      <div className=" text-grey-600 dark:text-zinc-600 space-y-2">
        <CodeMirror
          readOnly={isReadonly}
          placeholder={JSON.stringify(toolParametersPlaceholder, null, 2)}
          height={'250px'}
          value={JSON.stringify({ parameters: tool.function.parameters }, null, 2)}
          onChange={onChangeParameters}
          extensions={extensions}
          theme={theme}
        />
        {error && <div className="text-red-500 text-xs">{error}</div>}
      </div>
    </div>
  )
}
const MemoizedTool = React.memo(Tool)

const schema = z.object({
  input: z.string().min(1),
  output: z.string(),
  labels: z.string().optional(),
  tags: z.array(z.string()).optional(),
})

type FormSchema = z.infer<typeof schema>

export const EditDatasetItem = ({
  datasetItem,
  action,
  workspaceId,
}: {
  datasetItem: DatasetItemWithData
  workspaceId: number
  action?: Action
}) => {
  const [useJsonEditor, setUseJsonEditor] = useState(false)

  const textareaRef = React.useRef<HTMLTextAreaElement>(null)
  const utils = api.useContext()

  const parsedValue =
    typeof datasetItem.input === 'string'
      ? datasetItem.input
      : JSON.stringify(datasetItem.input, null, 2)

  const updateDatasetItem = api.datasetItem.update.useMutation()
  const [loading, setLoading] = useState(false)

  const formikConfig = useMemo(() => {
    return {
      initialValues: {
        input: parsedValue,
        output: datasetItem.output,
        labels: datasetItem.labels.join(', '),
        tags: datasetItem.tags,
      } as FormSchema,
      validationSchema: toFormikValidationSchema(schema),
      onSubmit: async (values: FormSchema) => {
        setLoading(true)
        await updateDatasetItem.mutateAsync(
          {
            id: datasetItem.id,
            data: values,
          },
          {
            onSuccess: () => {
              toast.success({
                title: 'Data item updated',
                description: 'Successfully updated the data item',
              })
            },
            onError: (error) => {
              toast.error({
                title: 'Error',
                description: error.message,
              })
            },
            onSettled: () => {
              setLoading(false)
            },
          }
        )
        await utils.datasetItem.getAll.invalidate({ datasetId: datasetItem.datasetId })
        await utils.datasetItem.getByGuid.invalidate({ guid: datasetItem.guid })
      },
    }
  }, [
    datasetItem.datasetId,
    datasetItem.guid,
    datasetItem.id,
    datasetItem.labels,
    datasetItem.output,
    datasetItem.tags,
    parsedValue,
    updateDatasetItem,
    utils.datasetItem.getAll,
    utils.datasetItem.getByGuid,
  ])

  const labelOptions = Object.values(DATASET_LABELS).map((label) => ({
    name: label,
    id: label,
  }))

  labelOptions.unshift({ name: 'Both', id: '' })
  const selectedLabel =
    datasetItem.labels?.length > 0
      ? {
          name: datasetItem.labels.join(','),
          id: datasetItem.labels.join(','),
        }
      : { name: 'Both', id: '' }

  useTextareaWithAdaptiveHeight(textareaRef, datasetItem, [])

  const { extensions, theme } = useCodeMirrorTheme({
    extensions: [EditorView.lineWrapping, json(), imagesPlugin],
  })

  return (
    <div className="relative" onKeyDown={(e) => e.stopPropagation()}>
      <Formik
        initialValues={formikConfig.initialValues}
        enableReinitialize={true}
        onSubmit={formikConfig.onSubmit}
        validationSchema={formikConfig.validationSchema}
      >
        {(formik) => (
          <div className="py-3 flex flex-col space-y-6">
            {loading && (
              <div className="absolute top-0 right-0 w-4">
                <Loader className="h-4 w-4 text-grey-400" />
              </div>
            )}

            <div className="flex items-center gap-2 mt-3">
              <Switch
                checked={useJsonEditor}
                onCheckedChange={() => {
                  setUseJsonEditor(!useJsonEditor)
                }}
              />
              <label className="text-xs text-grey-500 dark:text-zinc-500">Edit JSON</label>
            </div>

            {useJsonEditor ? (
              <div>
                <label htmlFor="input" className="pb-2 block text-sm font-medium">
                  Input
                </label>

                <CodeMirror
                  className="bg-white dark:bg-black"
                  editable={true}
                  onChange={(val) => {
                    void formik.setFieldValue('input', val)
                  }}
                  value={formik.values.input}
                  extensions={extensions}
                  theme={theme}
                />
                <FieldError fieldName="input" formik={formik} />

                <div className="pt-6">
                  <label htmlFor="output" className="pb-2 block text-sm font-medium">
                    Output
                  </label>
                  <div className="flex-1 block w-full rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:ring-zinc-800 dark:focus:ring-white">
                    <CodeMirror
                      className="bg-white dark:bg-black"
                      editable={true}
                      onChange={(val) => {
                        void formik.setFieldValue('output', val)
                      }}
                      value={formik.values.output}
                      extensions={extensions}
                      theme={theme}
                    />
                  </div>

                  <FieldError fieldName="output" formik={formik} />
                </div>
              </div>
            ) : (
              <>
                <label htmlFor="input" className="pb-2 block text-sm font-medium">
                  Input
                </label>
                <DatasetItemInputEditor
                  action={action}
                  input={formik.values.input}
                  onChange={(val) => {
                    void formik.setFieldValue('input', val)
                  }}
                />

                <label htmlFor="output" className="pb-2 block text-sm font-medium">
                  Output
                </label>
                <DatasetItemOutputEditor
                  output={formik.values.output}
                  onChange={(val) => {
                    void formik.setFieldValue('output', val)
                  }}
                />
              </>
            )}

            <div className="space-y-3">
              <label htmlFor="labels">
                <span className="block text-sm font-medium">Split</span>
                <span className="text-xs text-grey-500 dark:text-zinc-500">
                  Mark this item to be used for Training, Evaluation, or both (default)
                </span>
              </label>
              <SimpleSelect.Single
                defaultOption={selectedLabel}
                fieldName="labels"
                placeholder="Select dataset for evaluations"
                options={labelOptions}
                showAvatars={false}
                formik={formik}
                onSelect={(val) => {
                  void formik.setFieldValue('labels', val)
                }}
              />

              <FieldError fieldName="labels" formik={formik} />
            </div>

            <div className="space-y-3">
              <DatasetItemTagsInput
                datasetId={datasetItem.datasetId}
                workspaceId={workspaceId}
                value={formik.values.tags}
                setTags={(tags) => {
                  void formik.setFieldValue('tags', tags)
                }}
              />

              <FieldError fieldName="tags" formik={formik} />
            </div>

            <div className="w-1/4">
              <Button type="button" onClick={() => formik.handleSubmit()} disabled={loading}>
                Save
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export const DatasetItemTagsInput = ({
  datasetId,
  workspaceId,
  value,
  setTags,
}: {
  datasetId: number
  workspaceId: number
  value?: string[]
  setTags: (tags: string[]) => void
}) => {
  const [createTagModalOpen, setCreateTagModalOpen] = useState(false)
  const [newTags, setNewTags] = useState<string[]>([])
  const { tags, isLoading } = useFetchDatasetTags(datasetId, workspaceId)

  const tagOptions: ComboboxMultipleOption[] = useMemo(() => {
    console.log('tags', tags)
    if (isLoading || !tags) return []
    const opts = tags.map((tag) => ({
      value: tag,
      label: tag,
    }))

    if (newTags?.length > 0) {
      newTags.forEach((tag) => {
        opts.unshift({
          value: tag,
          label: tag,
        })
      })
    }
    return opts
  }, [tags, newTags, isLoading])

  return (
    <div className="relative">
      <NewTagModal
        isOpen={createTagModalOpen}
        setOpen={setCreateTagModalOpen}
        handleSubmit={(tag) => {
          void setTags([...(value ?? []), tag])
          setNewTags([...newTags, tag])
          setCreateTagModalOpen(false)
        }}
      />

      <MultiSelect
        key={value?.join('-') ?? 'tags'}
        className={'w-max mt-2'}
        options={tagOptions}
        title={'Tags'}
        selectedValues={value?.map((v) => String(v)) ?? []}
        labelForCreate={'New Tag'}
        onSelectCreate={() => {
          setCreateTagModalOpen(true)
        }}
        onChange={(newValue) => {
          setTags(newValue)
        }}
      />
    </div>
  )
}

const NewTagModal = ({
  isOpen,
  setOpen,
  handleSubmit,
}: {
  isOpen: boolean
  setOpen: (open: boolean) => void
  handleSubmit(tag: string): void
}) => {
  const [newTagValue, setNewTagValue] = useState('')

  const submitForm = (e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    handleSubmit(newTagValue)
    setNewTagValue('')
    e.preventDefault()
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Content className={'p-6'}>
        <Dialog.Header>
          <Dialog.Title>Add a tag</Dialog.Title>

          <Dialog.Description>
            <div>
              <form onSubmit={submitForm} className="flex items-center gap-3">
                <Input
                  hideLabel={true}
                  label={''}
                  id="newTag"
                  value={newTagValue}
                  onChange={(e) => {
                    setNewTagValue(e.currentTarget.value)
                  }}
                />
                <Button onClick={submitForm}>Add Tag</Button>
              </form>
            </div>
          </Dialog.Description>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
