export const openAIBaseModels = [
  'gpt-4o-mini-2024-07-18',
  'gpt-4o-2024-08-06',
  'gpt-4o-2024-05-13',
  'gpt-4-0613',
  'gpt-3.5-turbo-0125',
  'gpt-3.5-turbo-1106',
  'gpt-3.5-turbo-0613',
  'babbage-002',
  'davinci-002',
]

export const azureBaseModels = [
  'babbage-002',
  'davinci-002',
  'gpt-35-turbo-0613',
  'gpt-35-turbo-1106',
]

export const mistralBaseModels = [
  'open-mistral-7b',
  'mistral-small-latest',
  'mistral-small-2409',
  'codestral-latest',
  'codestral-2405',
  'open-mistral-nemo',
  'mistral-large-latest',
  'mistral-large-2407',
]

export const togetherBaseModels = [
  'togethercomputer/LLaMA-2-7B-32K',
  'togethercomputer/Llama-2-7B-32K-Instruct',
  'togethercomputer/llama-2-7b',
  'togethercomputer/llama-2-7b-chat',
  'togethercomputer/llama-2-13b',
  'togethercomputer/llama-2-13b-chat',
  'togethercomputer/llama-2-70b',
  'togethercomputer/llama-2-70b-chat',
  'meta-llama/Meta-Llama-3-8B',
  'meta-llama/Meta-Llama-3-8B-Instruct',
  'meta-llama/Meta-Llama-3-70B',
  'meta-llama/Meta-Llama-3-70B-Instruct',
  'togethercomputer/CodeLlama-7b',
  'togethercomputer/CodeLlama-7b-Python',
  'togethercomputer/CodeLlama-7b-Instruct',
  'togethercomputer/CodeLlama-13b',
  'togethercomputer/CodeLlama-13b-Python',
  'togethercomputer/CodeLlama-13b-Instruct',
  'mistralai/Mixtral-8x7B-v0.1',
  'mistralai/Mixtral-8x7B-Instruct-v0.1',
  'NousResearch/Nous-Hermes-2-Mixtral-8x7B-DPO',
  'NousResearch/Nous-Hermes-2-Mixtral-8x7B-SFT',
  'mistralai/Mistral-7B-Instruct-v0.2',
  'mistralai/Mistral-7B-Instruct-v0.1',
  'mistralai/Mistral-7B-v0.1',
  'teknium/OpenHermes-2p5-Mistral-7B',
  'HuggingFaceH4/zephyr-7b-beta',
]

export const GCPVertexBaseModels = [
  'gemini-1.5-pro-002',
  'gemini-1.5-flash-002',
  'gemini-1.0-pro-002',
]

export const GeminiAPIBaseModels = ['gemini-1.0-pro-001', 'gemini-1.5-flash-001-tuning']
// I can't find any docs listing which models are available for tuning (https://ai.google.dev/gemini-api/docs/model-tuning/tutorial?lang=rest#tuned-models)
// so I'm just going with this

export const DATASET_LABELS = {
  evaluation: 'Evaluation',
  training: 'Training',
}
