// Yes these IDs are hardcoded
export const llmProviders = [
  { id: 1, name: 'OpenAI' },
  { id: 12, name: 'Azure OpenAI' },
  { id: 3, name: 'Anthropic' },
  { id: 13, name: 'GCP Vertex' },
  { id: 14, name: 'Klu' },
  { id: 2, name: 'AI21' },
  { id: 5, name: 'Cohere' },
  { id: 15, name: 'Replicate' },
  { id: 16, name: 'Amazon Bedrock' },
  { id: 17, name: 'Cloudflare AI' },
  { id: 8, name: 'Hugging Face Hub' },
  { id: 18, name: 'Perplexity AI' },
  { id: 19, name: 'Google AI' },
  { id: 20, name: 'Together AI' },
  { id: 21, name: 'Mistral' },
  { id: 22, name: 'Azure AI Endpoint' },
  { id: 23, name: 'Groq' },
  { id: 24, name: 'API Endpoint' },
  // { id: 6, name: 'GooseAI' },
  // { id: 10, name: 'NLP Cloud' },
  //   { id: 7, name: 'HuggingFace' },
]

export const defaultLlmProviders = [
  { id: 1, name: 'OpenAI' },
  { id: 2, name: 'AI21' },
  { id: 3, name: 'Anthropic' },
  { id: 5, name: 'Cohere' },
  { id: 14, name: 'Klu' },
  { id: 18, name: 'Perplexity AI' },
  { id: 19, name: 'Google AI' },
  { id: 20, name: 'Together AI' },
  { id: 21, name: 'Mistral' },
  { id: 23, name: 'Groq' },
]

export const scaleLlmProviders = [
  { id: 16, name: 'Amazon Bedrock' },
  { id: 22, name: 'Azure AI Endpoint' },
  { id: 12, name: 'Azure OpenAI' },
  { id: 17, name: 'Cloudflare AI' },
  { id: 13, name: 'GCP Vertex' },
  { id: 8, name: 'Hugging Face Hub' },
  { id: 15, name: 'Replicate' },
  { id: 24, name: 'API Endpoint' },
]

export const nlpCloudAIModels = [
  { name: 'en_core_web_lg', id: 'en_core_web_lg' },
  { name: 'fr_core_news_lg', id: 'fr_core_news_lg' },
  { name: 'zh_core_web_lg', id: 'zh_core_web_lg' },
  { name: 'da_core_news_lg', id: 'da_core_news_lg' },
  { name: 'nl_core_news_lg', id: 'nl_core_news_lg' },
  { name: 'de_core_news_lg', id: 'de_core_news_lg' },
  { name: 'el_core_news_lg', id: 'el_core_news_lg' },
  { name: 'it_core_news_lg', id: 'it_core_news_lg' },
  { name: 'ja_ginza_electra', id: 'ja_ginza_electra' },
  { name: 'ja_core_news_lg', id: 'ja_core_news_lg' },
  { name: 'lt_core_news_lg', id: 'lt_core_news_lg' },
  { name: 'nb_core_news_lg', id: 'nb_core_news_lg' },
  { name: 'pl_core_news_lg', id: 'pl_core_news_lg' },
  { name: 'pt_core_news_lg', id: 'pt_core_news_lg' },
  { name: 'ro_core_news_lg', id: 'ro_core_news_lg' },
  { name: 'es_core_news_lg', id: 'es_core_news_lg' },
  { name: 'bart-large-mnli-yahoo-answers', id: 'bart-large-mnli-yahoo-answers' },
  { name: 'xlm-roberta-large-xnli', id: 'xlm-roberta-large-xnli' },
  { name: 'bart-large-cnn', id: 'bart-large-cnn' },
  { name: 'bart-large-samsum', id: 'bart-large-samsum' },
  { name: 't5-base-en-generate-headline', id: 't5-base-en-generate-headline' },
  { name: 'roberta-base-squad2', id: 'roberta-base-squad2' },
  {
    name: 'distilbert-base-uncased-finetuned-sst-2-english',
    id: 'distilbert-base-uncased-finetuned-sst-2-english',
  },
  { name: 'distilbert-base-uncased-emotion', id: 'distilbert-base-uncased-emotion' },
  { name: 'finbert', id: 'finbert' },
  { name: 'gpt-j', id: 'gpt-j' },
  { name: 'gpt-neox-20b', id: 'gpt-neox-20b' },
  { name: 'fast-gpt-j', id: 'fast-gpt-j' },
  { name: 'finetuned-gpt-neox-20b', id: 'finetuned-gpt-neox-20b' },
  { name: 'dolphin', id: 'dolphin' },
  { name: 'nllb-200-3-3b', id: 'nllb-200-3-3b' },
  { name: 'paraphrase-multilingual-mpnet-base-v2', id: 'paraphrase-multilingual-mpnet-base-v2' },
  { name: 'python-langdetect', id: 'python-langdetect' },
  { name: 'stable-diffusion', id: 'stable-diffusion' },
  { name: 'whisper', id: 'whisper' },
]

// openai, the open source alternative to google
export const openAIModels = [
  { id: 'gpt-4o', name: 'gpt-4o' },
  { id: 'gpt-4o-mini', name: 'gpt-4o-mini' },
  { id: 'gpt-4-turbo', name: 'gpt-4-turbo' },
  { id: 'gpt-4', name: 'gpt-4' },
]
export const ai21Models = [{ id: 'jamba-instruct-preview', name: 'jamba-instruct-preview' }]

export const cohereModels = [
  { id: 'command-r-plus', name: 'command-r-plus' },
  { id: 'command-r', name: 'command-r' },
  { id: 'command', name: 'command' },
  { id: 'command-light', name: 'command-light' },
  { id: 'command-nightly', name: 'command-nightly' },
  { id: 'command-light-nightly', name: 'command-light-nightly' },
]

export const anthropicAIModels = [
  { id: 'claude-3-5-sonnet-20240620', name: 'claude-3-5-sonnet-20240620' },
  { id: 'claude-3-opus-20240229', name: 'claude-3-opus-20240229' },
  { id: 'claude-3-sonnet-20240229', name: 'claude-3-sonnet-20240229' },
  { id: 'claude-3-haiku-20240307', name: 'claude-3-haiku-20240307' },
  { id: 'claude-2.1', name: 'claude-2.1' },
  { id: 'claude-2', name: 'claude-2' },
  // { id: 'claude-v1', name: 'claude-v1' },
  // { id: 'claude-v1-100k', name: 'claude-v1-100k' },
  // { id: 'claude-instant-v1', name: 'claude-instant-v1' },
  { id: 'claude-instant-1.2', name: 'claude-instant-1.2' },
]

export const gooseAIModels = [
  { id: 'gpt-neo-20b', name: 'gpt-neo-20b' },
  { id: 'fairseq-125m', name: 'fairseq-125m' },
  { id: 'fairseq-1-3b', name: 'fairseq-1-3b' },
  { id: 'fairseq-2-7b', name: 'fairseq-2-7bB' },
  { id: 'fairseq-6b-7b', name: 'fairseq-6b-7b' },
  { id: 'fairseq-13b', name: 'fairseq-13b' },
  { id: 'gpt-j-6b', name: 'gpt-j-6' },
  { id: 'gpt-neo-125m', name: 'gpt-neo-125' },
  { id: 'gpt-neo-1-3b', name: 'gpt-neo-1-3b' },
  { id: 'gpt-neo-2-7b', name: 'gpt-neo-2-7b' },
]

export const kluModels = [
  { id: 'gpt-4o', name: 'gpt-4o' },
  { id: 'gpt-4-1106-preview', name: 'gpt-4-1106-preview' },
  { id: 'gpt-35-turbo-16k', name: 'gpt-35-turbo-16k' },
  { id: 'gpt-35-turbo-1106', name: 'gpt-35-turbo-1106' },
  { id: 'gpt-4', name: 'gpt-4' },
  { id: 'gpt-4-32k', name: 'gpt-4-32k' },
]

export const amazonBedrockModels = [
  { id: 'ai21.j2-grande-instruct', name: 'ai21.j2-grande-instruct' },
  { id: 'ai21.j2-jumbo-instruct', name: 'ai21.j2-jumbo-instruct' },
  { id: 'ai21.j2-mid', name: 'ai21.j2-mid' },
  { id: 'ai21.j2-mid-v1', name: 'ai21.j2-mid-v1' },
  { id: 'ai21.j2-ultra', name: 'ai21.j2-ultra' },
  { id: 'ai21.j2-ultra-v1:0:8k', name: 'ai21.j2-ultra-v1:0:8k' },
  { id: 'amazon.titan-text-express-v1', name: 'amazon.titan-text-express-v1' },
  { id: 'amazon.titan-text-express-v1:0:8k', name: 'amazon.titan-text-express-v1:0:8k' },
  { id: 'amazon.titan-text-lite-v1', name: 'amazon.titan-text-lite-v1' },
  { id: 'amazon.titan-text-lite-v1:0:4k', name: 'amazon.titan-text-lite-v1:0:4k' },
  { id: 'amazon.titan-text-premier-v1:0', name: 'amazon.titan-text-premier-v1:0' },
  { id: 'amazon.titan-tg1-large', name: 'amazon.titan-tg1-large' },
  {
    id: 'anthropic.claude-3-haiku-20240307-v1:0:200k',
    name: 'anthropic.claude-3-haiku-20240307-v1:0:200k',
  },
  { id: 'anthropic.claude-3-haiku-20240307-v1:0', name: 'anthropic.claude-3-haiku-20240307-v1:0' },
  {
    id: 'anthropic.claude-3-haiku-20240307-v1:0:48k',
    name: 'anthropic.claude-3-haiku-20240307-v1:0:48k',
  },
  {
    id: 'anthropic.claude-3-sonnet-20240229-v1:0',
    name: 'anthropic.claude-3-sonnet-20240229-v1:0',
  },
  {
    id: 'anthropic.claude-3-sonnet-20240229-v1:0:28k',
    name: 'anthropic.claude-3-sonnet-20240229-v1:0:28k',
  },
  {
    id: 'anthropic.claude-3-sonnet-20240229-v1:0:200k',
    name: 'anthropic.claude-3-sonnet-20240229-v1:0:200k',
  },
  // { id: 'anthropic.claude-3-sonnet-20240229-v1:0', name: 'anthropic.claude-3-sonnet-20240229-v1:0' },
  // { id: 'anthropic.claude-3-haiku-20240307-v1:0', name: 'anthropic.claude-3-haiku-20240307-v1:0' },
  { id: 'anthropic.claude-3-opus-20240229-v1:0', name: 'anthropic.claude-3-opus-20240229-v1:0' },
  { id: 'anthropic.claude-instant-v1', name: 'anthropic.claude-instant-v1' },
  { id: 'anthropic.claude-instant-v1:2:100k', name: 'anthropic.claude-instant-v1:2:100k' },
  { id: 'anthropic.claude-v2', name: 'anthropic.claude-v2' },
  { id: 'anthropic.claude-v2:0:18k', name: 'anthropic.claude-v2:0:18k' },
  { id: 'anthropic.claude-v2:0:100k', name: 'anthropic.claude-v2:0:100k' },
  { id: 'anthropic.claude-v2:1', name: 'anthropic.claude-v2:1' },
  { id: 'anthropic.claude-v2:1:18k', name: 'anthropic.claude-v2:1:18k' },
  { id: 'anthropic.claude-v2:1:200k', name: 'anthropic.claude-v2:1:200k' },
  { id: 'cohere.command-light-text-v14', name: 'cohere.command-light-text-v14' },
  { id: 'cohere.command-light-text-v14:7:4k', name: 'cohere.command-light-text-v14:7:4k' },
  { id: 'cohere.command-r-plus-v1:0', name: 'cohere.command-r-plus-v1:0' },
  { id: 'cohere.command-r-v1:0', name: 'cohere.command-r-v1:0' },
  { id: 'cohere.command-text-v14', name: 'cohere.command-text-v14' },
  { id: 'cohere.command-text-v14:7:4k', name: 'cohere.command-text-v14:7:4k' },
  { id: 'meta.llama2-13b-chat-v1', name: 'meta.llama2-13b-chat-v1' },
  { id: 'meta.llama2-13b-chat-v1:0:4k', name: 'meta.llama2-13b-chat-v1:0:4k' },
  { id: 'meta.llama2-13b-v1', name: 'meta.llama2-13b-v1' },
  { id: 'meta.llama2-13b-v1:0:4k', name: 'meta.llama2-13b-v1:0:4k' },
  { id: 'meta.llama2-70b-chat-v1', name: 'meta.llama2-70b-chat-v1' },
  { id: 'meta.llama2-70b-chat-v1:0:4k', name: 'meta.llama2-70b-chat-v1:0:4k' },
  { id: 'meta.llama2-70b-v1', name: 'meta.llama2-70b-v1' },
  { id: 'meta.llama2-70b-v1:0:4k', name: 'meta.llama2-70b-v1:0:4k' },
  { id: 'meta.llama3-70b-instruct-v1:0', name: 'meta.llama3-70b-instruct-v1:0' },
  { id: 'meta.llama3-8b-instruct-v1:0', name: 'meta.llama3-8b-instruct-v1:0' },
  { id: 'meta.llama3-1-8b-instruct-v1:0', name: 'meta.llama3-1-8b-instruct-v1:0' },
  { id: 'meta.llama3-1-70b-instruct-v1:0', name: 'meta.llama3-1-70b-instruct-v1:0' },
  { id: 'meta.llama3-1-405b-instruct-v1:0', name: 'meta.llama3-1-405b-instruct-v1:0' },
  { id: 'mistral.mixtral-8x7b-instruct-v0:1', name: 'mistral.mixtral-8x7b-instruct-v0:1' },
  { id: 'mistral.mistral-7b-instruct-v0:2', name: 'mistral.mistral-7b-instruct-v0:2' },
  { id: 'mistral.mistral-large-2402-v1:0', name: 'mistral.mistral-large-2402-v1:0' },
]

export const DEFAULT_KLU_LLM_KEY = 'klu'

export const GoogleCloudPlatformModels = [
  { id: 'gemini-1.5-flash', name: 'gemini-1.5-flash-latest' },
  { id: 'gemini-1.5-pro', name: 'gemini-1.5-pro-latest' },
  { id: 'gemini-1.0-pro', name: 'gemini-1.0-pro-latest' },
  { id: 'gemini-1.0-pro-vision', name: 'gemini-1.0-pro-vision-latest' },
  { id: 'gemini-pro-experimental', name: 'gemini-pro-experimental' },
  { id: 'gemini-flash-experimental', name: 'gemini-flash-experimental' },
  { id: 'text-bison@001', name: 'text-bison@001' },
]

export const cloudflareAIModels = []

export const perplexityAIModels = [
  { id: 'llama-3.1-sonar-small-128k-online', name: 'llama-3.1-sonar-small-128k-online' },
  { id: 'llama-3.1-sonar-large-128k-online', name: 'llama-3.1-sonar-large-128k-online' },
  { id: 'llama-3.1-sonar-small-128k-chat', name: 'llama-3.1-sonar-small-128k-chat' },
  { id: 'llama-3.1-sonar-large-128k-chat', name: 'llama-3.1-sonar-large-128k-chat' },
]

export const googleAIModels = [
  { id: 'gemini-1.5-flash-latest', name: 'gemini-1.5-flash-latest' },
  { id: 'gemini-1.5-pro-latest', name: 'gemini-1.5-pro-latest' },
  //{ id: 'gemini-pro', name: 'gemini-pro' },
  // { id: 'gemini-pro-vision', name: 'gemini-pro-vision' }, // deprecated
]

export const togetherAIModels = [
  {
    name: 'meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo',
    id: 'meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo',
  },
  {
    name: 'meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo',
    id: 'meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo',
  },
  {
    name: 'meta-llama/Meta-Llama-3.1-405B-Instruct-Turbo',
    id: 'meta-llama/Meta-Llama-3.1-405B-Instruct-Turbo',
  },
  {
    name: 'meta-llama/Meta-Llama-3-8B-Instruct-Turbo',
    id: 'meta-llama/Meta-Llama-3-8B-Instruct-Turbo',
  },
  {
    name: 'meta-llama/Meta-Llama-3-70B-Instruct-Turbo',
    id: 'meta-llama/Meta-Llama-3-70B-Instruct-Turbo',
  },
  {
    name: 'meta-llama/Meta-Llama-3-8B-Instruct-Lite',
    id: 'meta-llama/Meta-Llama-3-8B-Instruct-Lite',
  },
  {
    name: 'meta-llama/Meta-Llama-3-70B-Instruct-Lite',
    id: 'meta-llama/Meta-Llama-3-70B-Instruct-Lite',
  },
  { name: 'zero-one-ai/Yi-34B-Chat', id: 'zero-one-ai/Yi-34B-Chat' },
  { name: 'allenai/OLMo-7B-Instruct', id: 'allenai/OLMo-7B-Instruct' },
  { name: 'allenai/OLMo-7B-Twin-2T', id: 'allenai/OLMo-7B-Twin-2T' },
  { name: 'allenai/OLMo-7B', id: 'allenai/OLMo-7B' },
  { name: 'Austism/chronos-hermes-13b', id: 'Austism/chronos-hermes-13b' },
  {
    name: 'cognitivecomputations/dolphin-2.5-mixtral-8x7b',
    id: 'cognitivecomputations/dolphin-2.5-mixtral-8x7b',
  },
  { name: 'databricks/dbrx-instruct', id: 'databricks/dbrx-instruct' },
  {
    name: 'deepseek-ai/deepseek-coder-33b-instruct',
    id: 'deepseek-ai/deepseek-coder-33b-instruct',
  },
  { name: 'deepseek-ai/deepseek-llm-67b-chat', id: 'deepseek-ai/deepseek-llm-67b-chat' },
  { name: 'garage-bAInd/Platypus2-70B-instruct', id: 'garage-bAInd/Platypus2-70B-instruct' },
  { name: 'google/gemma-2b-it', id: 'google/gemma-2b-it' },
  { name: 'google/gemma-7b-it', id: 'google/gemma-7b-it' },
  { name: 'Gryphe/MythoMax-L2-13b', id: 'Gryphe/MythoMax-L2-13b' },
  { name: 'lmsys/vicuna-13b-v1.5', id: 'lmsys/vicuna-13b-v1.5' },
  { name: 'lmsys/vicuna-7b-v1.5', id: 'lmsys/vicuna-7b-v1.5' },
  { name: 'codellama/CodeLlama-13b-Instruct-hf', id: 'codellama/CodeLlama-13b-Instruct-hf' },
  { name: 'codellama/CodeLlama-34b-Instruct-hf', id: 'codellama/CodeLlama-34b-Instruct-hf' },
  { name: 'codellama/CodeLlama-70b-Instruct-hf', id: 'codellama/CodeLlama-70b-Instruct-hf' },
  { name: 'codellama/CodeLlama-7b-Instruct-hf', id: 'codellama/CodeLlama-7b-Instruct-hf' },
  { name: 'meta-llama/Llama-2-70b-chat-hf', id: 'meta-llama/Llama-2-70b-chat-hf' },
  { name: 'meta-llama/Llama-2-13b-chat-hf', id: 'meta-llama/Llama-2-13b-chat-hf' },
  { name: 'meta-llama/Llama-2-7b-chat-hf', id: 'meta-llama/Llama-2-7b-chat-hf' },
  { name: 'meta-llama/Llama-3-8b-chat-hf', id: 'meta-llama/Llama-3-8b-chat-hf' },
  { name: 'meta-llama/Llama-3-70b-chat-hf', id: 'meta-llama/Llama-3-70b-chat-hf' },
  { name: 'mistralai/Mistral-7B-Instruct-v0.1', id: 'mistralai/Mistral-7B-Instruct-v0.1' },
  { name: 'mistralai/Mistral-7B-Instruct-v0.2', id: 'mistralai/Mistral-7B-Instruct-v0.2' },
  { name: 'mistralai/Mistral-7B-Instruct-v0.3', id: 'mistralai/Mistral-7B-Instruct-v0.3' },
  { name: 'mistralai/Mixtral-8x7B-Instruct-v0.1', id: 'mistralai/Mixtral-8x7B-Instruct-v0.1' },
  { name: 'mistralai/Mixtral-8x22B-Instruct-v0.1', id: 'mistralai/Mixtral-8x22B-Instruct-v0.1' },
  { name: 'NousResearch/Nous-Capybara-7B-V1p9', id: 'NousResearch/Nous-Capybara-7B-V1p9' },
  {
    name: 'NousResearch/Nous-Hermes-2-Mistral-7B-DPO',
    id: 'NousResearch/Nous-Hermes-2-Mistral-7B-DPO',
  },
  {
    name: 'NousResearch/Nous-Hermes-2-Mixtral-8x7B-DPO',
    id: 'NousResearch/Nous-Hermes-2-Mixtral-8x7B-DPO',
  },
  {
    name: 'NousResearch/Nous-Hermes-2-Mixtral-8x7B-SFT',
    id: 'NousResearch/Nous-Hermes-2-Mixtral-8x7B-SFT',
  },
  { name: 'NousResearch/Nous-Hermes-llama-2-7b', id: 'NousResearch/Nous-Hermes-llama-2-7b' },
  { name: 'NousResearch/Nous-Hermes-Llama2-13b', id: 'NousResearch/Nous-Hermes-Llama2-13b' },
  { name: 'NousResearch/Nous-Hermes-2-Yi-34B', id: 'NousResearch/Nous-Hermes-2-Yi-34B' },
  { name: 'openchat/openchat-3.5-1210', id: 'openchat/openchat-3.5-1210' },
  { name: 'Open-Orca/Mistral-7B-OpenOrca', id: 'Open-Orca/Mistral-7B-OpenOrca' },
  { name: 'Qwen/Qwen1.5-0.5B-Chat', id: 'Qwen/Qwen1.5-0.5B-Chat' },
  { name: 'Qwen/Qwen1.5-1.8B-Chat', id: 'Qwen/Qwen1.5-1.8B-Chat' },
  { name: 'Qwen/Qwen1.5-4B-Chat', id: 'Qwen/Qwen1.5-4B-Chat' },
  { name: 'Qwen/Qwen1.5-7B-Chat', id: 'Qwen/Qwen1.5-7B-Chat' },
  { name: 'Qwen/Qwen1.5-14B-Chat', id: 'Qwen/Qwen1.5-14B-Chat' },
  { name: 'Qwen/Qwen1.5-32B-Chat', id: 'Qwen/Qwen1.5-32B-Chat' },
  { name: 'Qwen/Qwen1.5-72B-Chat', id: 'Qwen/Qwen1.5-72B-Chat' },
  { name: 'Qwen/Qwen1.5-110B-Chat', id: 'Qwen/Qwen1.5-110B-Chat' },
  { name: 'Qwen/Qwen2-72B-Instruct', id: 'Qwen/Qwen2-72B-Instruct' },
  { name: 'snorkelai/Snorkel-Mistral-PairRM-DPO', id: 'snorkelai/Snorkel-Mistral-PairRM-DPO' },
  { name: 'Snowflake/snowflake-arctic-instruct', id: 'Snowflake/snowflake-arctic-instruct' },
  { name: 'togethercomputer/alpaca-7b', id: 'togethercomputer/alpaca-7b' },
  { name: 'teknium/OpenHermes-2-Mistral-7B', id: 'teknium/OpenHermes-2-Mistral-7B' },
  { name: 'teknium/OpenHermes-2p5-Mistral-7B', id: 'teknium/OpenHermes-2p5-Mistral-7B' },
  {
    name: 'togethercomputer/Llama-2-7B-32K-Instruct',
    id: 'togethercomputer/Llama-2-7B-32K-Instruct',
  },
  {
    name: 'togethercomputer/RedPajama-INCITE-Chat-3B-v1',
    id: 'togethercomputer/RedPajama-INCITE-Chat-3B-v1',
  },
  {
    name: 'togethercomputer/RedPajama-INCITE-7B-Chat',
    id: 'togethercomputer/RedPajama-INCITE-7B-Chat',
  },
  { name: 'togethercomputer/StripedHyena-Nous-7B', id: 'togethercomputer/StripedHyena-Nous-7B' },
  { name: 'Undi95/ReMM-SLERP-L2-13B', id: 'Undi95/ReMM-SLERP-L2-13B' },
  { name: 'Undi95/Toppy-M-7B', id: 'Undi95/Toppy-M-7B' },
  { name: 'WizardLM/WizardLM-13B-V1.2', id: 'WizardLM/WizardLM-13B-V1.2' },
  { name: 'upstage/SOLAR-10.7B-Instruct-v1.0', id: 'upstage/SOLAR-10.7B-Instruct-v1.0' },
]

export const mistralModels = [
  { id: 'open-mistral-nemo', name: 'open-mistral-nemo' },
  { id: 'mistral-large-latest', name: 'mistral-large-latest' },
  { id: 'open-mistral-nemo-2407', name: 'open-mistral-nemo-2407' },
  { id: 'mistral-large-2407', name: 'mistral-large-2407' },
]

export const groqModels = [
  { id: 'gemma2-9b-it', name: 'gemma2-9b-it' },
  // { id: 'gemma-7b-it', name: 'gemma-7b-it' },
  // { id: 'llama-3.1-405b-reasoning', name: 'llama-3.1-405b-reasoning' },
  { id: 'llama-3.1-70b-versatile', name: 'llama-3.1-70b-versatile' },
  { id: 'llama-3.1-8b-instant', name: 'llama-3.1-8b-instant' },
  // { id: 'llama3-70b-8192', name: 'llama3-70b-8192' },
  // { id: 'llama3-8b-8192', name: 'llama3-8b-8192' },
  // { id: 'llama3-groq-70b-8192-tool-use-preview', name: 'llama3-groq-70b-8192-tool-use-preview' },
  // { id: 'llama3-groq-8b-8192-tool-use-preview', name: 'llama3-groq-8b-8192-tool-use-preview' },
  { id: 'mixtral-8x7b-32768', name: 'mixtral-8x7b-32768' },
  //  { id: 'whisper-large-v3', name: 'whisper-large-v3' }, // voice for later
]

export const llmErrorsDict = {
  timeout: 'Request timed out. Please try this in the Studio and/or reduce prompt size.',
  invalid_response: 'The model returned an invalid response.',
  long_prompt: 'The prompt is too long for this model.',
}

export const llmErrors = [
  'Request timed out. Please try this in the Studio and/or reduce prompt size.',
  'The model returned an invalid response.',
  'The prompt is too long for this model.',
]

export const modelContextLengths = {
  openai: {
    'gpt-4o': 128000,
    'gpt-4o-2024-05-13': 128000,
    'gpt-4o-2024-08-06': 128000,
    'gpt-4o-mini': 128000,
    'gpt-4o-mini-2024-07-18': 128000,
    'gpt-4-turbo': 128000,
    'gpt-4-1106-preview': 128000,
    'gpt-4-turbo-preview': 128000,
    'gpt-4-turbo-2024-04-09': 128000,
    'gpt-4-32k': 32768,
    'gpt-4-8k': 8192,
    'gpt-4': 8192,
    'gpt-3.5-turbo-16k': 16384,
    'gpt-3.5-turbo-8k': 8192,
    'gpt-3.5-16k': 16384,
    'gpt-4-0125-preview': 128000, // added support for gpt-4-0125-preview
    'gpt-3.5-turbo-1106': 16384, // added support for gpt-3.5-turbo-1106
    'gpt-3.5-turbo-0125': 16384, // added support for gpt-3.5-turbo-0125
    davinci: 4096,
    curie: 2048,
    babbage: 2048,
    ada: 2048,
  },
  perplexity: {
    'llama-3.1-sonar-small-128k-online': 127072,
    'llama-3.1-sonar-small-128k-chat': 131072,
    'llama-3.1-sonar-large-128k-online': 127072,
    'llama-3.1-sonar-large-128k-chat': 131072,
  },
  azure_openai: {
    'davinci-003': 4096,
    'gpt-35-turbo': 4096,
    embeddings: 1024,
    'text-curie-001': 2048,
    'text-babbage-001': 2048,
  },
  klu: {
    'gpt-4o': 128000,
    'gpt-4-1106-preview': 128000,
    'gpt-4-32k': 32768,
    'gpt-4': 8192,
    'gpt-3.5': 4096,
    'gpt-3.5-16k': 16384,
  },
  mistral: {
    'mistral-large-latest': 128000,
    'open-mistral-nemo-2407': 128000,
    'mistral-large-2407': 128000,
    'open-mistral-nemo': 128000,
    'mistral-large-2402': 128000,
  },
  google: {
    'gemini-1.0-pro-latest': 30720,
    'gemini-1.0-pro': 30720,
    'gemini-1.0-pro-001': 30720,
    'gemini-1.0-pro-vision-latest': 30720,
    'gemini-1.5-pro-2m-latest': 2097152,
    'gemini-1.5-pro-001': 2097152,
    'gemini-1.5-pro': 2097152,
    'gemini-1.5-flash-001': 1048576,
    'gemini-1.5-flash': 1048576,
  },
  anthropic: {
    'claude-3-opus-20240229': 200000,
    'claude-3-sonnet-20240229': 200000,
    'claude-3-haiku-20240307': 2000000,
    'claude-2.1': 100000,
    'claude-2': 100000,
    'claude-instant-1.2': 100000,
    'claude-3-5-sonnet-20240620': 200000,
  },
}

export const openAIModelOutputTokenLimits: { [key: string]: number } = {
  'gpt-4o': 4096,
  'gpt-4o-2024-05-13': 4096,
  'gpt-4o-2024-08-06': 16384,
  'chatgpt-4o-latest': 16384,
  'gpt-4o-mini': 16384,
  'gpt-4o-mini-2024-07-18': 16384,
  'o1-preview': 32768,
  'o1-preview-2024-09-12': 32768,
  'o1-mini': 65536,
  'o1-mini-2024-09-12': 65536,
  'gpt-4-turbo': 4096,
  'gpt-4-turbo-2024-04-09': 4096,
  'gpt-4': 8192,
  'gpt-4-0613': 8192,
  'gpt-3.5-turbo': 4096,
  'gpt-3.5-turbo-0125': 4096,
  'gpt-3.5-turbo-1106': 4096,
}
