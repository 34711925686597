/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as Diff from 'diff'
import * as Diff2Html from 'diff2html'
import { cn } from '@/client/utils'
import 'diff2html/bundles/css/diff2html.min.css'
import type { ColorSchemeType } from 'diff2html/lib/types'
import DOMPurify from 'dompurify'
import React from 'react'
import { useTheme } from '@/common/components/ui/context'

export const DiffViewer = ({
  title,
  data,
  config,
  className,
}: {
  title: {
    old: string
    new: string
  }
  data: {
    new: object | string
    old: object | string
  }
  config?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stringReplacer: (this: any, key: string, value: any) => any
  }
  className?: string
}) => {
  const diff = React.useMemo(
    () =>
      Diff.createTwoFilesPatch(
        title.old,
        title.new,
        typeof data.old === 'string'
          ? data.old
          : JSON.stringify(data.old, config?.stringReplacer ?? undefined, 2),
        typeof data.new === 'string'
          ? data.new
          : JSON.stringify(data.new, config?.stringReplacer ?? undefined, 2)
      ),
    [title, data, config?.stringReplacer]
  )

  const theme = useTheme()

  const html = React.useMemo(
    () =>
      Diff2Html.html(diff, {
        drawFileList: false,
        matching: 'lines',
        outputFormat: 'side-by-side',
        renderNothingWhenEmpty: false,
        diffStyle: 'word',
        colorScheme: (theme?.state.isDark ? 'dark' : 'light') as ColorSchemeType,
      }),
    [diff, theme?.state.isDark]
  )

  return (
    <div
      className={cn('w-full', className)}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
    />
  )
}

export default DiffViewer
