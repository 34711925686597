import type { Workspace } from '@prisma/client'
import React from 'react'
import { Dialog } from '@/client/components'
import { Form } from '../Form'

const useCreateDatabaseProviderModal = ({ workspace }: { workspace: Workspace }) => {
  const [isOpen, setOpen] = React.useState(false)

  const render = ({ trigger }: { trigger?: () => React.ReactNode } = {}) => (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      {trigger ? <Dialog.Trigger asChild>{trigger()}</Dialog.Trigger> : null}

      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          <div className="flex flex-col space-y-6">
            <Dialog.Title asChild>
              <h3 className="title3 grow px-6 pt-6">Add Database Connection</h3>
            </Dialog.Title>
            <Form workspace={workspace} onIsOpenChange={setOpen} />
          </div>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )

  return { setOpen, render }
}

export default useCreateDatabaseProviderModal
