import React from 'react'
import { cn } from '@/client/utils'
import type { DialogFooterProps } from './types'

const DialogFooter = ({ className, ...props }: DialogFooterProps) => (
  <div
    className={cn(
      'justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5 sm:flex',
      className
    )}
    {...props}
  />
)

DialogFooter.displayName = 'DialogFooter'

export { DialogFooter }
