import { AlertTriangle, InfoCircle } from '@untitled-ui/icons-react'
import React from 'react'
import { cn } from '@/client/utils'
import { Button } from '../Button'
import { Dialog } from '../Dialog'

interface ConfirmActionDialogProps {
  onConfirm: () => Promise<void> | void
  header?: string
  message?: string
  variant?: 'alert' | 'info'
  children: (props: { setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode
  text?: {
    confirm?: string
    loading?: string
  }
  content?: React.ReactNode
}

const ConfirmActionDialog: React.FC<ConfirmActionDialogProps> = ({
  header,
  message,
  children,
  ...props
}) => {
  const [open, setOpen] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const variant = props.variant ?? 'alert'

  const onConfirm = async () => {
    setLoading(true)
    try {
      await props.onConfirm()
    } catch (e) {
      console.error(e)
    }
    setOpen(false)
    setLoading(false)
  }

  return (
    <React.Fragment>
      {children({ setOpen })}
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Content className="gap-0 max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
          <Dialog.Header>
            <div
              className={cn('px-4 sm:p-6', {
                'pb-4': !props.content,
              })}
            >
              <div className="w-full sm:flex sm:items-start h-full">
                {variant === 'alert' ? (
                  <div className="flex items-center justify-center flex-shrink-0 w-14 h-14 mx-auto bg-red-100 dark:bg-red-900 ring-red-300 dark:ring-red-700 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <AlertTriangle
                      className="w-6 h-6 text-red-600 dark:text-red-300"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-center flex-shrink-0 w-14 h-14 mx-auto bg-zinc-100 dark:bg-zinc-900 ring-zinc-300 dark:ring-zinc-700 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <InfoCircle
                      className="w-6 h-6 text-zinc-600 dark:text-zinc-300"
                      aria-hidden="true"
                    />
                  </div>
                )}
                <div className="flex flex-col justify-center-center flex-grow h-full text-center sm:ml-4 sm:text-left">
                  <h3 className="text-lg my-auto font-medium leading-6 text-black dark:text-white">
                    {header || `Confirm action`}
                  </h3>
                  {message ? (
                    <div className="mt-2">
                      <p className="text-sm text-grey-700 dark:text-zinc-400">{message}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Dialog.Header>
          {props.content ? <div className="px-4 pb-4 sm:pt-3 sm:p-6">{props.content}</div> : null}
          <Dialog.Footer>
            <Button variant="outline" onClick={() => setOpen(false)}>
              Close
            </Button>
            <Button
              variant={variant === 'alert' ? 'destructive' : 'primary'}
              disabled={isLoading}
              loading={isLoading}
              onClick={() => void onConfirm()}
            >
              {isLoading ? props.text?.loading ?? 'Processing' : props.text?.confirm ?? 'Confirm'}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </React.Fragment>
  )
}

export { ConfirmActionDialog }
