import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { Context, ContextType, User } from '@prisma/client';
import { ChevronRight } from '@untitled-ui/icons-react';
import { type NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { Button, Slider } from '@/client/components';
import { OpenMobileSidebarButton, Sidebar } from '@/common/components';
import { ResponseModeSelect } from '@/common/components/contexts/ResponseModeSelect';
import { Context404 } from '@/common/components/notFound/404';
import { MessagePromptRenderer } from '@/common/components/tables/v2/drawer';
import { FullLoaderPage } from '@/common/components/ui/FullLoaderPage';
import { useCurrentWorkspace, useFetchContextBySlug, useUser } from '@/common/hooks';
import { withContext } from '@/common/lib/ssr';
import type { WorkspaceContextIParams } from '@/common/types/queryparams';
import type { ContextPromptResult } from '@/server/service/types';
import { api } from '@/utils';
import { ContextProcessingPlaceholder, responseModes } from '.';
import { MetadataItem } from './embeddings';
type DetailedDataContext = Context & {
  type: ContextType;
  createdBy: User;
  responseMode: string;
  responseLength: number;
  similarityTopK: number;
};
export function ContextTestForm({
  context
}: {
  context: DetailedDataContext;
}) {
  const [contextResponse, setDataContextResponse] = useState<ContextPromptResult | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [prompt, setPrompt] = useState('');
  const [responseMode, setResponseMode] = useState(context.responseMode);
  const [responseLength, setResponseLength] = useState(context.responseLength);
  const [similarityTopK, setSimilarityTopK] = useState<number | undefined>(context.similarityTopK);
  const [similarityCutoff, setSimilarityCutoff] = useState<number | null>(context.similarityCutoff);
  // const [rerankLlmTopN, setRerankLlmTopN] = useState<number | null>(context.rerankLlmTopN)
  const {
    user
  } = useUser();
  const promptDataContext = api.context.prompt.useMutation();
  const {
    workspace
  } = useCurrentWorkspace();
  const [isLoading, setLoading] = useState(false);
  const handleDataContextInput = useCallback(async () => {
    if (!workspace || !context) return null;
    setLoading(true);
    await promptDataContext.mutateAsync({
      contextGuid: context.guid || '',
      prompt: prompt,
      responseMode: responseMode,
      responseLength: responseLength,
      similarityTopK: similarityTopK || 5,
      similarityCutoff: similarityCutoff || undefined
      // rerankLlmTopN: rerankLlmTopN || undefined,
    }, {
      onSettled: () => void setLoading(false),
      onError: err => {
        console.log(err);
        setError(err.message);
      },
      onSuccess: res => {
        console.log(res);
        setDataContextResponse(res);
      }
    });
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptDataContext, context.guid, workspace, prompt]);
  if (!context.processed) {
    return <ContextProcessingPlaceholder />;
  }
  return <>
      <form className="p-6" onSubmit={e => {
      e.preventDefault();
      void handleDataContextInput();
    }}>
        <div className="mb-6">
          <p className="text-lg font-medium">Retrieval Test</p>
        </div>
        <p className="text-sm text-grey-700 dark:text-zinc-400">Context Input</p>
        <div className="mt-2">
          <div className="flex flex-row rounded-md">
            <input name="name" type="text" className="block w-full min-w-0 flex-1 rounded-md bg:white border-grey-300 focus:border-grey-500 focus:ring-grey-500 dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus-ring-white dark:focus:border-white sm:text-sm" onChange={e => setPrompt(e.target.value)} />
          </div>
        </div>

        <div className="mt-6">
          <p className="text-sm text-grey-700 dark:text-zinc-400">Response Mode</p>
          <div className="mt-2">
            <div className="flex flex-row gap-6 relative group">
              <ResponseModeSelect responseModes={responseModes} setResponseMode={setResponseMode} responseMode={responseMode} />
            </div>
          </div>
        </div>

        {user && user.isStaff && <>
            <div className="mt-6">
              <p className="text-sm text-grey-700 dark:text-zinc-400">Similarity Cutoff</p>
              <div className="mt-2 grid grid-cols-4">
                <div className="col-span-1">
                  <input name="similarityCutoff" type="number" value={similarityCutoff ?? ''} min="0" step="0.01" max="1" className="w-full rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:ring-zinc-800 dark:focus:ring-white" onChange={e => {
                if (e.target.value === '') return setSimilarityCutoff(null);
                setSimilarityCutoff(parseFloat(e.target.value));
              }} />
                  <p className="text-sm text-grey-500 dark:text-zinc-600 mt-2 ">
                    Minimum similarity score to consider a document as a candidate for the LLM.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="mt-6">
              <p className="text-sm text-grey-700 dark:text-zinc-400">Rerank Nodes using LLM?</p>
              <div className="mt-2">
                <input
                  name="rerankLlmTopN"
                  type="number"
                  value={rerankLlmTopN ?? ''}
                  min="1"
                  max="100"
                  className="w-80 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus:ring-white"
                  onChange={(e) => {
                    if (e.target.value === '') return setRerankLlmTopN(null)
                     setRerankLlmTopN(parseInt(e.target.value))
                  }}
                />
                <p className="text-sm text-grey-500 dark:text-zinc-600 mt-2 ">
                  After retrieving the top K documents, re-rank them using the LLM, and keep the top
                  N.
                </p>
              </div>
             </div> */}
          </>}

        <div className="mt-6">
          <p className="text-sm text-grey-700 dark:text-zinc-400">Max Response Length</p>
          <div className="mt-2 grid grid-cols-4">
            <div className="col-span-1">
              <input name="responseLength" type="number" value={responseLength} min="50" max="1024" className="w-full rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:ring-zinc-800 dark:focus:ring-white" onChange={e => setResponseLength(parseInt(e.target.value))} />
            </div>

            <div className="col-span-3 flex align-middle px-3">
              <Slider min={50} max={1024} step={1} value={[responseLength]} onValueChange={([value]) => {
              setResponseLength(value || 256);
            }} />
            </div>
          </div>
        </div>

        <div className="mt-6">
          <p className="text-sm text-grey-700 dark:text-zinc-400">Docs to Retreive</p>
          <div className="mt-2 grid grid-cols-4">
            <div className="col-span-1">
              <input name="similarityTopK" type="number" value={similarityTopK || ''} min="1" max="100" className="w-full rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:ring-zinc-800 dark:focus:ring-white" onChange={e => {
              if (!e.target.value) {
                setSimilarityTopK(undefined);
              }
              setSimilarityTopK(parseInt(e.target.value));
            }} />
              <p className="text-sm text-grey-500 dark:text-zinc-600 mt-2 ">
                How many context documents to use as context for the LLM.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-6 border-t border-grey-200 dark:border-zinc-800 pt-6">
          <Button disabled={isLoading} type="submit" loading={isLoading} onClick={() => void handleDataContextInput()}>
            {isLoading ? 'Running...' : 'Test'}
          </Button>
        </div>

        {error && <div className="p-6 mt-6 border shadow-md rounded-md">
            <p className="text-red-500">{error}</p>
          </div>}
      </form>
      {/* {contextResponse && (
        <div className="p-6">
          <MessagePromptRenderer
            role={contextResponse.message.role}
            content={contextResponse.message.content}
          />
        </div>
       )} */}

      {contextResponse && <div>
          {contextResponse.message && <div className="p-6 m-6 border shadow-md rounded-md text-sm">
              <MessagePromptRenderer role={contextResponse.message.role} content={contextResponse.message.content!} />
            </div>}

          {contextResponse.sourceNodes && <div className="min-w-full overflow-clip rounded-b-md border-t border-t-grey-200">
              <table className="w-full min-w-full table-fixed divide-y divide-grey-200 dark:divide-zinc-800"></table>
              <thead className="bg-grey-50 dark:bg-zinc-900">
                <tr>
                  <th scope="col" className="
                  mw-1/2 w-full px-6 py-3 text-left text-xs font-medium uppercase
                  tracking-wider text-grey-900 dark:text-white
                ">
                    Embedding
                  </th>
                  <th scope="col" className="
                w-3/6 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider
                text-grey-900">
                    Metadata
                  </th>
                </tr>
              </thead>
              <tbody>
                {contextResponse.sourceNodes.length < 1 && <tr>
                    <td colSpan={2} className="px-6 py-4 align-top text-xs text-grey-500">
                      No documents found
                    </td>
                  </tr>}
                {contextResponse.sourceNodes.map(node => <tr key={node.guid} className="bg-white hover:bg-grey-50 dark:bg-black hover:bg-zinc-800">
                    <td className="text-sm text-grey-900 dark:text-zinc-600">
                      <div className="
                                  h-[200px]
                                  overflow-y-scroll whitespace-pre-wrap
                                  px-6
                                  py-6
                                  font-mono
                                  text-xs">
                        {node.content}
                      </div>
                    </td>
                    <td className="overflow-scroll px-6 py-4 align-top text-xs text-grey-500 ">
                      <div className="flex h-[200px] flex-col gap-2 overflow-y-scroll">
                        {node.score && <MetadataItem name="score" value={node.score} />}

                        {node.metadata && Object.entries(node.metadata).map(([key, value]) => <MetadataItem key={`metadata-${key}`} name={key} value={value} />)}
                      </div>
                    </td>
                  </tr>)}
              </tbody>
            </div>}
        </div>}
    </>;
}
const DataContextTestPage: NextPage = () => {
  const router = useRouter();
  const {
    contextSlug
  } = (router.query as WorkspaceContextIParams);
  const {
    context,
    isLoading
  } = useFetchContextBySlug(contextSlug);
  const {
    workspace
  } = useCurrentWorkspace();
  if (isLoading) {
    return <FullLoaderPage />;
  }
  if (!context) {
    return null;
  }
  return <Sidebar>
      <Head>
        <title>{context.name} / Test Skill</title>
        <meta name="description" content="Skill Update" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="sticky top-0 flex h-16 flex-shrink-0 border-b border-grey-200 dark:border-zinc-800 bg-white dark:bg-black bg-opacity-50 backdrop-blur">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-6">
            <OpenMobileSidebarButton />
            {workspace && <div className="flex flex-1 items-center">
                <Link href={`/${workspace.slug}/context`}>
                  <h1 className="text-xl font-semibold text-grey-800 dark:text-white">
                    Context Library
                  </h1>
                </Link>
                <ChevronRight className="h-5 w-5 mx-2 text-grey-400" aria-hidden="true" />
                <h2 className="text-xl font-semibold text-grey-800 dark:text-white">
                  <Link href={`/${workspace.slug}/context/${context.slug}`}>{context.name}</Link>
                </h2>
              </div>}
          </div>
        </div>
        <main>
          <div>
            <div className="max-w-8xl mx-auto mt-4 px-4">
              <ContextTestForm context={context} />
            </div>
          </div>
        </main>
      </div>
    </Sidebar>;
};
const Context404Wrapper = ({
  dne,
  ...props
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <Context404 />;
  }
  return <DataContextTestPage {...props} />;
};
export default _withSuperJSONPage(Context404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withContext(), []);