import { CalendarDate, XClose } from '@untitled-ui/icons-react'
import { startOfYesterday } from 'date-fns'
import intervalToDuration from 'date-fns/intervalToDuration'
import startOfMonth from 'date-fns/startOfMonth'
import startOfToday from 'date-fns/startOfToday'
import startOfYear from 'date-fns/startOfYear'
import sub from 'date-fns/sub'
import React from 'react'
import { type DateRange } from 'react-day-picker'
import { Button, Calendar, DropdownMenu, Popover } from '@/client/components'
import { cn } from '@/client/utils'
import type { DateRangeOption } from './type'

export const DateRangePicker = (props: {
  placeholder?: string
  value: DateRange | undefined
  onValueChange: (value: DateRange) => void
  options?: DateRangeOption[]
  minDate?: Date
  maxDate?: Date
  config?: {
    hideDeleteButton?: boolean
  }
}) => {
  const [value, setValue] = React.useState<DateRange | undefined>(props.value)
  const [range, setRange] = React.useState<DateRangeOption | undefined>(undefined)

  const options =
    props.options ??
    ([
      {
        value: 'tdy',
        label: 'Yesterday',
        startDate: startOfYesterday(),
      },
      {
        value: 'w',
        label: 'Last 7 days',
        startDate: sub(startOfToday(), { days: 8 }),
      },
      {
        value: 't',
        label: 'Last 30 days',
        startDate: sub(startOfToday(), { days: 31 }),
      },
      {
        value: 'm',
        label: 'Month to Date',
        startDate: sub(startOfMonth(startOfToday()), { days: 1 }),
      },
      {
        value: 'y',
        label: 'Year to Date',
        startDate: sub(startOfYear(startOfToday()), { days: 1 }),
      },
    ] satisfies DateRangeOption[])

  const noValueSelected = !value?.to && !value?.from

  const handleClearValue = () => {
    setValue({
      from: undefined,
      to: undefined,
    })
    setRange(undefined)
  }

  const onChangeValue = (value: DateRange | undefined) => {
    if (!value) {
      handleClearValue()
    } else {
      setValue(value)
      props.onValueChange?.(value)
    }
  }

  return (
    <div className="flex items-center ">
      {noValueSelected ? null : props.config?.hideDeleteButton ? null : (
        <Button
          variant="outline"
          icon={XClose}
          className="rounded-r-none"
          onClick={handleClearValue}
        />
      )}
      <Popover.Root>
        <Popover.Trigger asChild>
          <Button
            variant="outline"
            startIcon={CalendarDate}
            className={cn('font-normal', {
              'dark:text-zinc-600 text-zinc-400': noValueSelected,
              '-ml-1 rounded-l-none': !noValueSelected && !props.config?.hideDeleteButton,
            })}
          >
            {(() => {
              if (noValueSelected) return props.placeholder ?? 'Select a timeframe'

              const from = value.from

              const to = value.to

              if (!to) return from?.toDateString()

              if (!from) return to.toDateString()

              if (from.toLocaleDateString() === to.toLocaleDateString()) return from?.toDateString()

              return (
                from.toLocaleDateString(undefined, {
                  month: 'short',
                  day: 'numeric',
                  weekday: undefined,
                  year: 'numeric',
                  timeZone: undefined,
                }) +
                ' - ' +
                to.toLocaleDateString(undefined, {
                  month: 'short',
                  day: 'numeric',
                  weekday: undefined,
                  year: 'numeric',
                  timeZone: undefined,
                })
              )
            })()}
          </Button>
        </Popover.Trigger>
        <Popover.Content align="start" className="p-0">
          <Calendar
            mode="range"
            selected={value}
            onSelect={(value) => onChangeValue(value)}
            defaultMonth={value?.from}
            numberOfMonths={2}
            min={
              props.minDate
                ? intervalToDuration({
                    start: props.minDate,
                    end: new Date(),
                  }).days
                : undefined
            }
            max={
              props.maxDate
                ? intervalToDuration({
                    start: new Date(),
                    end: props.maxDate,
                  }).days
                : undefined
            }
          />
        </Popover.Content>
      </Popover.Root>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <Button
            variant="outline"
            className={cn('font-normal -ml-1 rounded-l-none border-l-none', {
              'dark:text-zinc-600 text-zinc-400': !range,
            })}
          >
            {range ? range.label : 'Select a range'}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {options.map((o) => (
            <DropdownMenu.Item
              key={o.value}
              onClick={() => {
                setRange(o)
                onChangeValue({
                  ...value,
                  from: o.startDate,
                })
              }}
            >
              {o.label}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  )
}
