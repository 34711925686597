import type { Workspace } from '@prisma/client'
import { Dialog } from '@/client/components'
import type { DetailedActionVersion } from '@/common/components/actions/types'
import { Form } from './Form'

export const RevertVersion = ({
  workspace,
  version,
  isOpen,
  onIsOpenChange,
}: {
  workspace: Workspace
  version: DetailedActionVersion
  isOpen: boolean
  onIsOpenChange: (val: boolean) => void
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          <div className="flex flex-col space-y-6">
            <Dialog.Title asChild>
              <h3 className="title3 px-6 pt-6">Revert to Version {version.version_number}</h3>
            </Dialog.Title>
            <Form
              workspace={workspace}
              actionVersion={version}
              onIsOpenChange={onIsOpenChange}
              isOpen={isOpen}
            />
          </div>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
