import type { ToasterProps } from 'sonner'
import { toast as primitiveToast, Toaster as ToasterPrimitive } from 'sonner'

export function Toaster(props: ToasterProps) {
  return <ToasterPrimitive {...props} position="bottom-right" closeButton />
}

interface ToastProps {
  id?: string
  title: string
  description?: string
  action?: {
    label: string
    onClick: () => void
  }
}

export const toast = {
  success: (props: ToastProps) =>
    primitiveToast.success(props.title, {
      id: props.id,
      description: props.description,
      action: props.action,
      classNames: {
        toast: 'border-1 bg-white dark:bg-black dark:border-zinc-800 border-zinc-200 gap-3',
        title: 'font-semibold text-black dark:text-white',
        description: 'font-normal text-zinc-500 dark:text-zinc-400',
        actionButton:
          'text-white hover:!bg-zinc-700 transition ease-in-out duration-300 dark:!bg-white dark:!text-black dark:hover:!bg-zinc-200',
        closeButton:
          'bg-white hover:!bg-zinc-100 !text-zinc-800 border-zinc-200 dark:bg-black dark:!text-zinc-300 dark:border-zinc-800 dark:hover:!bg-zinc-800 dark:hover:border-zinc-800 transition ease-in-out duration-200',
        icon: 'text-green-700 dark:text-green-500',
      },
    }),
  info: (props: ToastProps) =>
    primitiveToast.info(props.title, {
      description: props.description,
      action: props.action,
      classNames: {
        toast: 'border-1 bg-white dark:bg-black dark:border-zinc-800 border-zinc-200 gap-3',
        title: 'font-semibold text-black dark:text-white',
        description: 'font-normal text-zinc-500 dark:text-zinc-400',
        actionButton:
          'text-white hover:!bg-zinc-700 transition ease-in-out duration-300 dark:!bg-white dark:!text-black dark:hover:!bg-zinc-200',
        closeButton:
          'bg-white hover:!bg-zinc-100 !text-zinc-800 border-zinc-200 dark:bg-black dark:!text-zinc-300 dark:border-zinc-800 dark:hover:!bg-zinc-800 dark:hover:border-zinc-800 transition ease-in-out duration-200',
        icon: 'text-black dark:text-white',
      },
    }),
  error: (props: ToastProps) =>
    primitiveToast.error(props.title, {
      description: props.description,
      action: props.action,
      classNames: {
        toast:
          'bg-red-800 text-red-50 border-1 border-red-800 dark:bg-red-900 dark:text-red-100 dark:border-red-800',
        title: 'font-semibold',
        description: 'font-normal opacity-50',
        actionButton:
          '!bg-red-50 !text-red-800 hover:!bg-red-100 dark:!bg-red-100 dark:!text-red-800',
        closeButton:
          'bg-white hover:!bg-red-50 !text-red-600 dark:!text-red-800 border-red-200 hover:!border-red-200 transition ease-in-out duration-200',
      },
    }),
  warning: (props: ToastProps) =>
    primitiveToast.warning(props.title, {
      description: props.description,
      action: props.action,
      classNames: {
        toast:
          'bg-orange-800 text-orange-50 border-1 border-orange-800 dark:bg-orange-900 dark:text-orange-100 dark:border-orange-800',
        title: 'font-semibold',
        description: 'font-normal opacity-50',
        actionButton:
          '!bg-orange-50 !text-orange-800 hover:!bg-orange-100 dark:!bg-orange-100 dark:!text-orange-800',
        closeButton:
          'bg-white hover:!bg-orange-50 !text-orange-600 dark:!text-orange-800 border-orange-200 hover:!border-orange-200 transition ease-in-out duration-200',
      },
    }),
}
