import type { Workspace } from '@prisma/client'
import { PlusCircle } from '@untitled-ui/icons-react'
import { useState } from 'react'
import { Button, Dialog } from '@/client/components'
import { TIER_CONTEXTS_FEATURE_ID } from '@/common/config/tierConstants'
import { useCheckWorkspaceFeatureAccess } from '@/common/hooks'
import { FeatureGateContent } from '../FeatureGate'
import { Form } from './Form'

export const CreateContext = ({ workspace }: { workspace: Workspace }) => {
  const [isOpen, onIsOpenChange] = useState(false)

  return (
    <>
      <Button
        startIcon={PlusCircle}
        onClick={() => {
          onIsOpenChange(true)
        }}
      >
        Add Context
      </Button>
      <CreateContextModal workspace={workspace} isOpen={isOpen} onIsOpenChange={onIsOpenChange} />
    </>
  )
}

export const CreateContextModal = ({
  workspace,
  isOpen,
  onIsOpenChange,
}: {
  workspace: Workspace
  isOpen: boolean
  onIsOpenChange: (val: boolean) => void
}) => {
  const { hasFeatureAccess } = useCheckWorkspaceFeatureAccess({
    workspaceId: workspace.id,
    featureId: TIER_CONTEXTS_FEATURE_ID,
  })

  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      {!hasFeatureAccess ? (
        <FeatureGateContent onOpenChange={onIsOpenChange} />
      ) : (
        <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
          <Dialog.Header>
            <div className="flex flex-col space-y-6">
              <Dialog.Title asChild>
                <h3 className="title3 text-left grow px-6 pt-6">Add Context</h3>
              </Dialog.Title>
              <span className="px-6 text-sm text-grey-600 dark:text-zinc-600">
                Add files or sources after creating the Context container.
              </span>
              <Form workspace={workspace} onIsOpenChange={onIsOpenChange} />
            </div>
          </Dialog.Header>
        </Dialog.Content>
      )}
    </Dialog.Root>
  )
}
