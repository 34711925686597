import { useFetchDataResult } from '@/common/hooks'
import { CompletionRenderer } from './datatable-completion-render'

export function PendingCompletionRenderer({ dataId }: { dataId: number }) {
  const { data, isLoading } = useFetchDataResult(dataId, 2000)

  if (isLoading || (data && data.output === '' && !('error_log' in (data.metadata as object)))) {
    return (
      <div className="mt-2 flex w-1/3 animate-pulse space-x-4">
        <div className="flex-1 space-y-2">
          <div className="h-2 w-full rounded bg-grey-700 dark:bg-zinc-800"></div>
          <div className="h-2 w-4/5 rounded bg-grey-700 dark:bg-zinc-800"></div>
          <div className="h-2 w-3/5 rounded bg-grey-700 dark:bg-zinc-800"></div>
        </div>
      </div>
    )
  }

  if (data) {
    return (
      <CompletionRenderer
        completion={data.output}
        numOutputTokens={data.num_output_tokens ? data.num_output_tokens : undefined}
        metadata={data.metadata as Record<string, unknown>}
      />
    )
  } else {
    return <>Could not load result</>
  }
}
