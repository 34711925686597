import { Button, Input, Textarea } from '@/client/components'
import { useEditVersionForm } from './form'
import type { FormProps } from './types'

export const Form = ({ workspace, actionVersion, onIsOpenChange }: FormProps) => {
  const { formik, saveDisabled } = useEditVersionForm({
    workspace,
    version: actionVersion,
    onIsOpenChange,
  })
  const { values, errors, handleChange, handleSubmit } = formik

  return (
    <div className="flex flex-col">
      <div className="px-6 pb-4">
        <Input
          id="name"
          name="name"
          className="font-inter"
          label={`Version name (Version ${actionVersion.version_number})`}
          placeholder="Changes from sama"
          message={errors.name}
          hasError={!!errors.name}
          value={values.name}
          autoComplete="off"
          onChange={handleChange}
        />
      </div>

      <div className="px-6 pb-4">
        <Textarea
          id="description"
          name="description"
          className="font-inter"
          label="Optional version notes"
          placeholder="Add change commentary and notes"
          message={errors.description}
          hasError={!!errors.description}
          value={values.description}
          onChange={handleChange}
        />
      </div>

      <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          disabled={saveDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
