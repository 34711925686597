import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import type {
  CellClickedEvent,
  ColDef,
  GridApi,
  SelectionColumnDef,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core'
import type { CustomCellRendererProps } from '@ag-grid-community/react'
import { AgGridReact } from '@ag-grid-community/react' // React Grid Logic
import { Button, Checkbox, ConfirmActionDialog, Icon, Popover, toast } from '@/client/components'
import { useFetchEvalRuns } from '@/common/hooks/evalRuns'
import { Room } from '@/common/Room'
import type {
  DetailedEval,
  DetailedEvalRun,
  EvalRunEvent,
  EvalRunMetadata,
} from '@/common/types/eval'
import { RetryFailedItemsButton } from '@/pages/[workspaceSlug]/apps/[slug]/evaluate/evals/[evalSlug]/runs/[runId]'
import type { ActionVersionMetadata } from '@/server/service/types'
import { api, dateAgo } from '@/utils'
import '@ag-grid-community/styles/ag-grid.css' // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css' // Theme

import { useEventListener, useThreads } from '@liveblocks/react/suspense'
import type { ActionVersion, EvalRun, User } from '@prisma/client'
import { AlertTriangle, ChevronDown, MessageSquare02 } from '@untitled-ui/icons-react'
import { format } from 'date-fns'
import { useMemo, useRef, useState } from 'react'
import { cn } from '@/client/utils'
import { Pagination } from '../../pagination/pagination'
import { useTheme, useWorkspace } from '../../ui/context'
import { ResultCell } from './cells/ResultCell'

type DymanicScore = Record<string, any>

export type EvalRunGridRow = {
  evalRun: EvalRun & {
    actionVersion: ActionVersion
    createdBy: User
  }
  id: number
  actionVersionName: string
  versionDate: string
  modelName?: string
  total_cost: number
  average_cost: number
  total_latency: number
  average_latency: number
  date: string
  scores: DymanicScore
  createdBy: string
}

export const EvalRunGrid = ({
  evalRecord,
  initialPage,
  setSelectedEvalRunId,
}: {
  evalRecord: DetailedEval
  initialPage?: number
  setSelectedEvalRunId: (id: number) => void
}) => {
  const theme = useTheme()
  const [page, setPage] = useState(initialPage || 1)
  const [perPage, setPerPage] = useState(50)

  const { evalRuns, totalCount, isLoading } = useFetchEvalRuns({
    evalId: evalRecord.id,
    page,
    perPage,
    workspaceId: evalRecord.workspaceId,
  })

  const completedEvalRuns = useMemo(() => {
    return evalRuns
      ? evalRuns.filter(
          (run) => run.metadata && (run.metadata as EvalRunMetadata).status == 'completed'
        )
      : []
  }, [evalRuns])

  const workspace = useWorkspace()

  const gridRef = useRef<AgGridReact>(null)

  const rows: EvalRunGridRow[] = useMemo(() => {
    if (isLoading || !completedEvalRuns) return []
    return completedEvalRuns.map((evalRun) => {
      const actionVersion = evalRun.actionVersion
      const actionVersionName =
        actionVersion.name == evalRecord.action?.name
          ? `${evalRecord.action?.name} v${actionVersion.version_number}`
          : actionVersion.name

      const { modelName } = actionVersion.metadata as ActionVersionMetadata

      const {
        total_cost,
        average_cost,
        average_latency,
        total_latency,
        scores,
        evalRunItemsTotal,
      } = (evalRun.metadata || {}) as EvalRunMetadata

      const dateObject = new Date(evalRun.createdAt)

      const res = {
        evalRun,
        id: evalRun.id,
        actionVersionName,
        versionDate: format(new Date(actionVersion.createdAt), 'MMMM dd yyyy HH:mm'),
        modelName,
        total_cost,
        average_cost,
        total_latency: (total_latency || 0) / 1000,
        average_latency: (average_latency || 0) / 1000,
        date: dateObject.toISOString().split('T')[0] || 'unknown',
        evalRunItemsTotal,
        scores: {} as DymanicScore,
        createdBy: evalRun.createdBy?.name || 'unknown',
      }

      if (scores) {
        Object.keys(scores).forEach((key) => {
          res.scores[`${key}`] = scores[key] as number
        })
      }

      return res
    })
  }, [isLoading, evalRecord, completedEvalRuns])

  const columns: ColDef[] = useMemo((): ColDef[] => {
    if (!workspace || !rows || rows.length < 1) return []

    const evalTypes =
      evalRecord.evalTypes?.map((x) => {
        return {
          ...x,
          name: x.name || x.evalType.name, //fall back to EvalType name if not present
        }
      }) || []

    return (
      [
        {
          cellRenderer: EvalRunInfoCellRenderer,
          field: 'evalRun',
          filter: false,
          headerName: 'Eval Run',
          pinned: 'left',
          width: 130,
          flex: 1,
        },
        {
          field: 'createdBy',
          headerName: 'Triggered By',
          width: 170,
          flex: 1,
          pinned: 'left',
        },
        {
          field: 'actionVersionName',
          headerName: 'Version',
          width: 150,
          flex: 1,
        },
        {
          field: 'modelName',
          headerName: 'Model',
          width: 150,
          flex: 1,
        },
        {
          field: 'versionDate',
          headerName: 'Version Date',
          flex: 1,
          hide: true,
        },
        {
          field: 'total_cost',
          headerName: 'Total Run Cost',
          type: 'numericColumn',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          valueGetter: (params: ValueGetterParams) => Number(params.data.total_cost),
          cellRenderer: ResultCell,
          flex: 1,
          hide: true,
        },
        {
          field: 'total_latency',
          headerName: 'Total Run Time',
          type: 'numericColumn',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          valueGetter: (params: ValueGetterParams) => Number(params.data.total_latency),
          valueFormatter: (params: ValueFormatterParams) => `${Number(params.value).toFixed(0)}ms`,
          // cellRenderer: ResultCell,
          flex: 1,
          hide: true,
        },
        {
          field: 'average_cost',
          headerName: 'Cost',
          type: 'numericColumn',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          valueGetter: (params: ValueGetterParams) => Number(params.data.average_cost),
          cellRenderer: ResultCell,
          flex: 1,
          hide: true,
        },

        {
          field: 'average_latency',
          headerName: 'Latency',
          type: 'numericColumn',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          valueGetter: (params: ValueGetterParams) => Number(params.data.average_latency),
          cellRenderer: ResultCell,
          maxWidth: 140,
          flex: 1,
        },

        ...evalTypes?.map((evalType) => {
          return {
            valueGetter: (params: ValueGetterParams) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
              const score = params.data.scores[evalType.id.toString()]
              return typeof score === 'number' || typeof score === 'string' ? Number(score) : null
            },
            type: 'numericColumn',
            cellRenderer: ResultCell,
            field: `scores.${evalType.name}`,
            headerName: `${evalType.name} Score`,
            autoHeight: true,
          }
        }),

        {
          headerName: '# of Dataset Items',
          field: 'evalRunItemsTotal',
          flex: 1,
        },

        {
          field: 'actions',
          headerComponent: TableColumnSelector,
          sortable: false,
          resizable: false,
          width: 50,
          minWidth: null,
          suppressNavigable: true,
          lockPosition: 'right',
          suppressMovable: true,
        },
      ] as ColDef[]
    ).filter((x) => !!x)
  }, [workspace, rows, evalRecord.evalTypes])

  const defaultColDef = useMemo(() => {
    return {
      headerComponentParams: {},
      filter: true,
      minWidth: 100,
    }
  }, [])

  const selectionColumnDef = useMemo(() => {
    return {
      field: 'selection',
      suppressHeaderMenuButton: false,
      pinned: 'left',
      suppressNavigable: true,
    } as SelectionColumnDef
  }, [])

  const components = useMemo(() => {
    return {}
  }, [])

  const gridOptions = useMemo(() => {
    return {}
  }, [])

  return (
    <>
      {rows.length > 0 && (
        <>
          <div
            className={cn({
              'ag-theme-quartz': theme?.state.isLight,
              'ag-theme-quartz-dark': theme?.state.isDark,
            })}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rows}
              columnDefs={columns}
              domLayout="autoHeight"
              defaultColDef={defaultColDef}
              components={components}
              suppressRowHoverHighlight={true}
              enableCellTextSelection={false}
              ensureDomOrder={true}
              gridOptions={gridOptions}
              modules={[ClientSideRowModelModule]}
              selection={{
                mode: 'multiRow',
              }}
              selectionColumnDef={selectionColumnDef}
              // onRowClicked={(e: RowClickedEvent) => {
              //   if (e.data)
              //   setSelectedEvalRunId((e.data as EvalRunGridRow).id)
              // }}
              onCellClicked={(e: CellClickedEvent) => {
                if (e.colDef.field != 'selection') {
                  setSelectedEvalRunId((e.data as EvalRunGridRow).id)
                } else {
                  // e.api.deselectAll()
                  e.node?.setSelected(!e.node?.isSelected())
                }
              }}
            />
          </div>

          <Pagination
            onPageChange={setPage}
            total={totalCount || 0}
            perPage={perPage}
            setPerPage={setPerPage}
            currentPage={page}
          />
        </>
      )}
    </>
  )
}

const TableColumnSelector = ({ api: gridApi }: { api: GridApi }) => {
  const utils = api.useContext()

  const [open, setOpen] = useState(false)
  const [refresh, setRefresh] = useState('')
  const [selectedRows, setSelectedRows] = useState<EvalRunGridRow[]>([])
  const bulkDelete = api.evalRun.bulkDelete.useMutation()

  if (!gridApi) return null

  gridApi.addEventListener('columnVisible', () => {
    setRefresh(Math.random().toString())
    // setRefresh('101112112121212121212121212121')
  })

  gridApi.addEventListener('selectionChanged', () => {
    setSelectedRows(gridApi.getSelectedRows())
  })

  const columns: ColDef[] =
    gridApi
      .getColumns()
      ?.map((x) => x.getColDef())
      ?.filter((x) => !['spacer', 'actions'].includes(x.field!) && !x.pinned)
      .filter(Boolean) || []

  const visibleColumns = gridApi
    .getAllDisplayedColumns()
    .map((x) => x.getColDef())
    .map((x) => x.field)

  const toggleColumn = (field: string) => {
    gridApi.setColumnVisible(field, !gridApi.getColumn(field)?.isVisible())
  }

  const handleDelete = async () => {
    await bulkDelete.mutateAsync({
      ids: selectedRows.map((r) => r.id),
    })

    if (selectedRows) {
      await utils.evalRun.getAll.invalidate({ evalId: selectedRows[0]?.evalRun.evalId })
    }

    toast.success({
      title: 'Eval runs deleted',
      description: 'Eval runs were deleted successfully',
    })
  }

  return (
    <>
      {selectedRows?.length > 0 && (
        <div className="fixed bottom-3 left-1/2 -translate-x-1/2 z-[9999]">
          <div className="flex items-center gap-4 p-4 bg-white border rounded-md shadow-md dark:bg-black dark:border-zinc-800">
            {selectedRows.length} selected
            <Button
              onClick={() => {
                window.location.href = `${window.location.href}/compare?evalRunIds=${selectedRows.map((r) => r.id).join(',')}`
              }}
            >
              Compare
            </Button>
            <ConfirmActionDialog
              variant={'alert'}
              header={`Delete ${selectedRows?.length} Selected Eval Runs`}
              message={
                'Are you sure you want to delete these eval runs? This action is irreversible.'
              }
              onConfirm={handleDelete}
            >
              {({ setOpen }) => (
                <Button onClick={() => setOpen(true)} variant="destructive">
                  Delete
                </Button>
              )}
            </ConfirmActionDialog>
          </div>
        </div>
      )}
      <Popover.Root open={open} onOpenChange={setOpen} modal={true}>
        <Popover.Trigger>
          <Icon size="md" component={ChevronDown} />
        </Popover.Trigger>

        <Popover.Content collisionPadding={20} sticky="always">
          <ul className="flex flex-col gap-2" key={refresh}>
            {columns.map((column) => (
              <li key={column.field} className="text-xs text-grey-800 dark:text-zinc-400">
                <label className="flex items-center gap-2 cursor-pointer select-none">
                  <Checkbox
                    checked={visibleColumns.includes(column.field)}
                    onCheckedChange={() => {
                      toggleColumn(column.field!)
                    }}
                  />
                  {column.headerName}
                </label>
              </li>
            ))}
          </ul>
        </Popover.Content>
      </Popover.Root>
    </>
  )
}

interface EvalRunInfoCellParams extends CustomCellRendererProps {
  data: { evalRun: DetailedEvalRun }
}

const EvalRunInfoCellRenderer = ({ data }: EvalRunInfoCellParams) => {
  const { evalRun } = data

  const { status } = (evalRun.metadata as EvalRunMetadata) || {}

  return (
    <>
      <div className="flex items-center gap-2 cursor-pointer">
        {status == 'cancelled' ? (
          <>
            <AlertTriangle className="w-4 h-4 text-red-500 cursor-pointer" aria-hidden="true" />
            Cancelled
          </>
        ) : (
          <Room id={`evalRun-${evalRun.id}`} hideLoader={true}>
            <EvalRunCommenters />
            <EvalRunStatus evalRun={evalRun} />
            &nbsp;
          </Room>
        )}
      </div>
    </>
  )
}

export const EvalRunStatus = ({ evalRun }: { evalRun: EvalRun }) => {
  const utils = api.useContext()

  const {
    evalRunItemsTotal,
    evalRunItemsCompleted,
    evalRunItemsFailed,
    executionTimePerItem,
    executionTime,
    scores,
    status,
    resumeAt,
  } = (evalRun.metadata as EvalRunMetadata) || {}
  const initialProgress = evalRunItemsTotal ? evalRunItemsCompleted / evalRunItemsTotal : 0
  const [progress, setProgress] = useState(initialProgress)

  useEventListener(({ event }) => {
    const { status, progress: runProgress } = event as EvalRunEvent

    if (status === 'completed' || status === 'rate_limited') {
      void utils.evalRun.getAll.invalidate({ evalId: evalRun.evalId })
      void utils.evalRun.getAllBySlug.invalidate()
      void utils.eval.getResultsByRun.invalidate({ evalId: evalRun.evalId })
      void utils.evalRun.getDelta2.invalidate({ evalId: evalRun.evalId })
      void utils.evalRun.get.invalidate({ id: evalRun.id })
      void utils.evalRunItem.getAll.invalidate({ evalRunId: evalRun.id })
      void utils.eval.getResultsByPeriod.invalidate({ evalId: evalRun.evalId })
    } else if (status === 'running') {
      setProgress(parseFloat(runProgress || '0'))
    }
  })

  const executionStats = !isNaN(Number(executionTimePerItem))
    ? `(${(executionTimePerItem / 1000).toFixed(0)}ms per item, ${(executionTime / 1000 / 1000).toFixed(1)}s total)`
    : ''

  return (
    <div
      className="flex items-center w-full gap-2 space-between"
      title={`${(progress * 100).toFixed(2)}% complete ${executionStats}`}
    >
      {evalRunItemsFailed > 0 && (
        <AlertTriangle
          className="flex-shrink-0 w-4 h-4 text-red-500 cursor-pointer"
          aria-hidden="true"
        />
      )}
      {!scores || status == 'running' ? (
        <div className="flex flex-col w-full space-y-2 items-left">
          {evalRun && evalRunItemsFailed > 0 && (
            <RetryFailedItemsButton evalRun={evalRun as DetailedEvalRun} />
          )}
          <div className="flex space-x-2 align-bottom items-center">
            <span className="text-xl text-left font-semibold text-grey-800 dark:text-white">
              {(progress * 100).toFixed(1)}%
            </span>
            <span>{executionStats}</span>
          </div>
          <div className="w-full h-3 transition duration-300 ease-in-out rounded-lg bg-zinc-300 dark:bg-zinc-900">
            <div
              className={`bg-grey-900 dark:bg-zinc-400 h-3 rounded-lg transition-width duration-200 ease-in-out`}
              style={{ width: `${progress * 100}%` }}
            ></div>
          </div>
        </div>
      ) : (
        <>{dateAgo(new Date(evalRun.createdAt))}</>
      )}
      {status == 'rate_limited' && (
        <div className="flex items-center gap-2 text-yellow-500">
          <>
            Rate limited {new Date(resumeAt) > new Date() ? 'until' : ''}{' '}
            {dateAgo(new Date(resumeAt))}
          </>
        </div>
      )}
    </div>
  )
}

const EvalRunCommenters = () => {
  const { threads } = useThreads()

  if (!threads) return null

  const commentsCount = threads
    ? threads.reduce((acc, thread) => acc + thread.comments.length, 0)
    : 0

  return (
    <>
      {threads.length > 0 && (
        <span className="flex items-center gap-1 text-grey-400 dark:text-zinc-500">
          <MessageSquare02 className="w-4 h-4 text-grey-400" aria-hidden="true" />
          {commentsCount}
        </span>
      )}
    </>
  )
}
