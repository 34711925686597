import { Formik, Form as FormikForm } from 'formik'
import { useState } from 'react'
import { Button, Combobox, Input } from '@/client/components'
import type { SelectOption } from '@/common/components/forms'
import { FieldError } from '@/common/components/forms'
import { databaseProviders } from '@/utils/databases'
import { useCreateDatabaseProviderForm } from './form'
import { DatabaseProviderMetadataForm } from './metadata-form'
import type { FormProps } from './types'

export const Form = ({ workspace, onIsOpenChange }: FormProps) => {
  const { formikConfig, saveDisabled, setRequiredMetadata } = useCreateDatabaseProviderForm({
    workspace,
    onIsOpenChange,
  })
  const [selectedProvider, setSelectedProvider] = useState<SelectOption | null>(null)

  return (
    <Formik
      initialValues={formikConfig.initialValues}
      onSubmit={formikConfig.onSubmit}
      validationSchema={formikConfig.validationSchema}
      validateOnBlur={false}
      validateOnMount={false}
      validateOnChange={false}
    >
      {(formik) => (
        <FormikForm>
          <div className="flex flex-col">
            <div className="px-6 pb-4">
              <Combobox.Single
                classNameContent="bg-white dark:bg-black text-sm"
                label="Database Type"
                options={databaseProviders.map((provider) => ({
                  value: String(provider.id),
                  label: provider.name,
                  // icon: getDatabaseIcon(provider.name),
                }))}
                value={selectedProvider ? String(selectedProvider.id) : undefined}
                placeholder="Select a provider"
                onChange={(value) => {
                  if (!value) return
                  const selectedProvider = databaseProviders.find(
                    (providers) => providers.id === parseInt(value)
                  )
                  if (!selectedProvider) {
                    throw new Error('Provider not found')
                  }
                  setSelectedProvider(selectedProvider)
                  void formik.setFieldValue('provider', selectedProvider.name, false)
                  void formik.setFieldValue('name', selectedProvider.name, false)
                  void setRequiredMetadata(selectedProvider.name, formik)
                }}
              />
              <FieldError fieldName="provider" formik={formik} />
            </div>

            <div className="px-6 pb-4">
              <Input
                id="name"
                name="name"
                label="Name"
                placeholder="Production Database"
                message={formik.errors.name}
                hasError={!!formik.errors.name}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>

            <DatabaseProviderMetadataForm formik={formik} className="px-6 pb-4" />

            <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
              <Button
                variant="outline"
                onClick={() => {
                  onIsOpenChange(false)
                }}
              >
                Close
              </Button>
              <Button type="submit" disabled={saveDisabled || !formik.isValid}>
                Save
              </Button>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
}
