import * as SelectPrimitive from '@radix-ui/react-select'
import React from 'react'
import { cn } from '@/client/utils'
import type { SelectSeparatorProps } from './types'

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  SelectSeparatorProps
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-grey-100 dark:bg-zinc-800', className)}
    {...props}
  />
))

SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export { SelectSeparator }
