import { Check, Clipboard } from '@untitled-ui/icons-react'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button } from '@/client/components'
import { toast } from '@/client/components/Toast'

const CopyButton = ({ text, icon }: { text: string; icon?: React.FunctionComponent }) => {
  const [copied, setCopied] = React.useState(false)

  const handleCopy = () => {
    toast.info({
      title: 'Copied',
      description: `"${text}" copied to clipboard`,
    })
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <div className="flex items-center">
      <CopyToClipboard text={text} onCopy={handleCopy}>
        <Button variant="ghost" size="xs" icon={copied ? Check : icon || Clipboard} />
      </CopyToClipboard>
    </div>
  )
}

export { CopyButton }
