import { useOthersMapped } from '@liveblocks/react/suspense'
import type { User } from 'next-auth'
import { Avatar, AvatarImage } from '@/client/components'
import { cn } from '@/client/utils'
import { useUser } from '@/common/hooks'

export function UserAvatar({ user, className }: { user: User; className?: string }) {
  const { user: currentUser } = useUser()

  const onlineUserIds = useOthersMapped((other) => other.info.userId).map(
    ([_connectionId, userId]) => userId
  )
  const isOnline = onlineUserIds.includes(user.id) || user.id === currentUser?.id

  function getInitials() {
    if (user.name) {
      return user.name
        .split(' ')
        .map((n) => n[0])
        .join('')
        .toUpperCase()
    } else if (user.email) {
      const emailFirstPart = user.email?.split('@')[0]
      if (!emailFirstPart) return ''
      return emailFirstPart
        .split('.')
        .map((n) => n[0])
        .join('')
        .toUpperCase()
    }
    return ''
  }

  const initials = getInitials()

  if (!user.image) {
    return (
      <div
        className={cn(
          'relative flex h-8 w-8 items-center justify-center rounded-full bg-grey-200 ring-1 ring-grey-600/50 ring-offset-1 ring-offset-white dark:ring-offset-zinc-800',
          className
        )}
      >
        <span className="text-grey-800 text-xs dark:text-zinc-500">{initials}</span>
        {isOnline && <OnlineIndicator />}
      </div>
    )
  }

  return (
    <Avatar
      className={cn(
        className,
        'rounded-full ring-1 ring-grey-600/50 ring-offset-1 ring-offset-white dark:ring-offset-zinc-800 z-auto'
      )}
    >
      <AvatarImage alt={user.name || user.email || 'Klu user'} src={user.image} />
      {/* <AvatarFallback>{initials}</AvatarFallback> */}
      {isOnline && <OnlineIndicator />}
    </Avatar>
  )
}

const OnlineIndicator = () => (
  <span className="absolute bottom-0 right-0 block h-[25%] w-[25%] rounded-full ring-2 ring-white bg-green-500"></span>
)
