import type { Workspace } from '@prisma/client'
import Link from 'next/link'
import { Button, Dialog } from '@/client/components'

export function FeatureGate({
  open,
  setOpen,
  workspace,
  loading,
}: {
  workspace: Workspace
  open: boolean
  setOpen: (val: boolean) => void
  loading: boolean
}) {
  const onOpenChange = (open: boolean) => {
    setOpen(open)
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content>
        {loading && <div className="hidden">Actions Loading ...</div>}
        {!loading && (
          <>
            <Dialog.Header className="px-6 pt-6">
              <Dialog.Title>Actions Limit Reached</Dialog.Title>

              <Dialog.Description>
                You&apos;ve reached the Actions limit available on your current plan. Please upgrade
                your plan to create more Actions.
              </Dialog.Description>
            </Dialog.Header>
            <div className="flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
              <Button
                variant="outline"
                onClick={() => {
                  onOpenChange(false)
                }}
              >
                Close
              </Button>
              <Link href={`${workspace.slug}/settings/billing`}>
                <Button variant="primary">Upgrade</Button>
              </Link>
            </div>
          </>
        )}
      </Dialog.Content>
    </Dialog.Root>
  )
}
