import * as TabsPrimitive from '@radix-ui/react-tabs'
import React from 'react'
import { cn } from '@/client/utils'

const SwitcherList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      `
        body2
        inline-flex
        items-center
        justify-center
        gap-2
        rounded-lg
        bg-grey-100
        p-1
        font-medium
        dark:bg-zinc-800
      `,
      className
    )}
    {...props}
  />
))

SwitcherList.displayName = TabsPrimitive.List.displayName

export { SwitcherList }
