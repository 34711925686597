import type { Extension } from '@codemirror/state'
import { githubDarkInit, githubLightInit } from '@uiw/codemirror-theme-github'
import type { ReactCodeMirrorProps } from '@uiw/react-codemirror'
import React from 'react'
import { useTheme } from '@/common/components/ui/context'

const useCodeMirrorTheme = (props: { extensions: Extension[] }) => {
  const theme = useTheme()
  const extensions = React.useMemo(() => {
    return [
      ...props.extensions,
      ...(theme?.state.isDark ? [githubDarkInit()] : []),
      ...(theme?.state.isLight ? [githubLightInit()] : []),
    ]
  }, [props.extensions, theme])

  return {
    extensions,
    theme: (theme?.state.isDark ? 'dark' : 'light') as ReactCodeMirrorProps['theme'],
  }
}

export default useCodeMirrorTheme
