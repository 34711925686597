import type { Action, ActionSession, App, Workspace } from '@prisma/client'
import { AlertTriangle } from '@untitled-ui/icons-react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function DeleteActionSessionForm({
  setOpen,
  actionSession,
  workspace,
  app,
  action,
}: {
  workspace: Workspace
  actionSession: ActionSession
  app: App
  action: Action
  setOpen: (val: boolean) => void
}) {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const deleteActionSession = api.actionSession.delete.useMutation()
  const { user } = useUser()

  async function onSuccess() {
    toast.success({
      title: `${actionSession?.name ?? 'Session'} deleted`,
      description: 'Successfully deleted the session',
    })
    setOpen(false)
    await router.push(`/${workspace.slug}/apps/${app.slug}/actions/${action.slug}/conversations`)
  }

  async function onDelete() {
    setLoading(true)
    await deleteActionSession.mutateAsync(
      { sessionId: actionSession.id },
      {
        onSuccess: () => {
          void onSuccess()
          if (user) {
            const id = user.id ? user.id : ''
            const email = user.email ? user.email : ''
            const uname = user.name ? user.name : ''
            identify_and_group(id, email, uname, workspace?.slug)
          }
          track(EVENT_NAMES.chat_deleted, {
            'User ID': user?.id,
            'User Email': user?.email,
            'User Name': user?.name,
            'ActionSession Name': actionSession.name,
            'Workspace ID': workspace.id,
            'Workspace Name': workspace.name,
          })
        },
        onSettled: () => {
          setLoading(false)
        },
        onError: (error) => {
          toast.error({
            title: 'Error',
            description: error.message,
          })
        },
      }
    )
  }

  return (
    <>
      <div className="bg-white dark:bg-black px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 dark:bg-red-900 ring-red-300 dark:ring-red-700 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <AlertTriangle className="w-6 h-6 text-red-600 dark:text-red-300" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-black dark:text-white">
              Delete Session
            </h3>
            <div className="mt-2">
              <p className="text-sm text-grey-700 dark:text-zinc-400">
                Are you sure you want to delete {actionSession.name}?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5 sm:flex">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="destructive"
          disabled={loading}
          loading={loading}
          onClick={() => void onDelete()}
        >
          {loading ? 'Deleting...' : `Delete ${actionSession?.name ?? 'Session'}`}
        </Button>
      </div>
    </>
  )
}

export const DeleteActionSessionModal = ({
  open,
  setOpen,
  actionSession,
  workspace,
  app,
  action,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  actionSession: ActionSession | null
  workspace: Workspace
  app: App
  action: Action
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          {actionSession && (
            <DeleteActionSessionForm
              app={app}
              action={action}
              setOpen={setOpen}
              actionSession={actionSession}
              workspace={workspace}
            />
          )}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
