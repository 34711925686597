import { Scales02 } from '@untitled-ui/icons-react'
import { useState } from 'react'
import {
  Select,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Tabs,
} from '@/client/components'
import { useFetchEvalRunItemsForEvalPaginated } from '@/common/hooks'
import type { DetailedEval } from '@/common/types/eval'
import { EvalCharts } from '@/pages/[workspaceSlug]/apps/[slug]/evaluate/evals/[evalSlug]'
import { EvalRunItemsGrid } from '../../evalRuns/grid/EvalRunItemsGrid'
import { useWorkspace } from '../../ui/context'
import { MonitorSettings } from './MonitorSettings'

export const MonitorDrawer = ({
  evalRecord,
  setOpen,
  open,
  initiallySelectedTab,
}: {
  evalRecord: DetailedEval
  open: boolean
  setOpen: (val: boolean) => void
  initiallySelectedTab?: string
}) => {
  const workspace = useWorkspace()
  const [selectedTab, setSelectedTab] = useState(initiallySelectedTab || 'overview')

  const { evalRunItems, isLoading } = useFetchEvalRunItemsForEvalPaginated({
    evalId: evalRecord.id,
    page: 1,
    perPage: 500,
  })

  const tabs = [
    {
      name: 'Overview',
      id: 'overview',
    },
  ]

  tabs.push({
    name: 'Results',
    id: 'evalRunItems',
  })
  tabs.push({
    name: 'Settings',
    id: 'settings',
  })

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent className="max-w-4xl sm:max-w-5xl">
        <SheetHeader>
          <SheetTitle
            className="text-grey-700 font-medium text-md flex gap-1 items-center 
          "
          >
            <Scales02 className="h-4 w-4" />
            Evaluate {evalRecord.name}
          </SheetTitle>
          <SheetDescription>
            <div>
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                <Select.Field
                  id="tabs"
                  onChange={(id) => setSelectedTab(id)}
                  value={selectedTab}
                  options={tabs.map((x) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                />
              </div>
              <div className="hidden sm:block">
                {workspace ? (
                  <Tabs.Root
                    className="grow flex border-b  border-grey-300 dark:border-zinc-800"
                    value={selectedTab}
                    onValueChange={(id) => setSelectedTab(id)}
                  >
                    <Tabs.List className="gap-5">
                      {tabs.map((x) => (
                        <Tabs.Trigger key={x.id} className="text-sm flex items-center" value={x.id}>
                          {x.name}
                        </Tabs.Trigger>
                      ))}
                    </Tabs.List>
                  </Tabs.Root>
                ) : null}
              </div>
            </div>

            <div className="py-6">
              {selectedTab == 'overview' && <EvalCharts evalRecord={evalRecord} />}
              {!isLoading && evalRunItems && selectedTab == 'evalRunItems' && (
                <EvalRunItemsGrid evalRecord={evalRecord} evalRunItems={evalRunItems} />
              )}
              {selectedTab == 'settings' && <MonitorSettings evalRecord={evalRecord} />}
            </div>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}
