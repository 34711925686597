import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { Context, ContextVersion, User } from '@prisma/client';
import { ArrowCircleLeft, ChevronRight } from '@untitled-ui/icons-react';
import { type NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button, ConfirmActionDialog, toast } from '@/client/components';
import DiffViewer from '@/client/components/DiffViewer';
import { OpenMobileSidebarButton, Sidebar, UserAvatar } from '@/common/components';
import { Context404 } from '@/common/components/notFound/404';
import { FullLoaderPage } from '@/common/components/ui/FullLoaderPage';
import { useCurrentWorkspace, useFetchContextBySlug, useFetchContextVersions } from '@/common/hooks';
import { withContext } from '@/common/lib';
import type { WorkspaceContextIParams } from '@/common/types/queryparams';
import { api, dateAgo } from '@/utils';
const viewableKeys = (['name', 'description', 'responseMode', 'responseLength', 'similarityTopK', 'chunkSize', 'chunkOverlap', 'splitterConfig'] as const);
type ViewableKeys = (typeof viewableKeys)[number];
type ObjectToDiff = { [K in ViewableKeys]?: unknown };
export function ContextVersionsTable({
  context
}: {
  context: Context & {
    createdBy: User;
  };
}) {
  const {
    versions,
    isLoading
  } = useFetchContextVersions(context.id);
  if (isLoading) {
    return <FullLoaderPage />;
  }
  const currentVersionObject: ObjectToDiff = {};
  viewableKeys.forEach(key => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    currentVersionObject[key] = context[key] || null;
  });
  return <div className="flex items-center justify-between">
      <div className="w-full">
        <div className="inline-block w-full align-middle">
          <div className="">
            <table className="w-full min-w-full divide-y divide-grey-300 dark:divide-zinc-800">
              <thead className="bg-grey-50 dark:bg-zinc-900">
                <tr>
                  <th scope="col" className="w-1/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 whitespace-nowrap">
                    Version #
                  </th>
                  <th scope="col" className="hidden w-2/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 whitespace-nowrap sm:table-cell">
                    Created By
                  </th>
                  <th scope="col" className="py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 whitespace-nowrap">
                    Changes
                  </th>
                  <th scope="col" className="hidden w-2/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 sm:table-cell">
                    Date Created
                  </th>
                  <th scope="col" className="py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grey-200 dark:divide-zinc-800">
                <tr className="group bg-white dark:bg-black hover:bg-grey-25 dark:hover:bg-zinc-900/50">
                  <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium">
                    <div className="mt-2 flex flex-row space-x-2 items-center">
                      <span className="text-xs bg-grey-50 ring-1 ring-black/10 dark:bg-zinc-900 dark:text-white dark:ring-zinc-800 rounded-md p-2">
                        Current Version
                      </span>
                    </div>
                  </td>

                  <td className="hidden whitespace-nowrap py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600 sm:table-cell">
                    <div className="flex flex-col items-start">
                      <UserAvatar className="w-6 h-6" user={context.createdBy} />
                    </div>
                  </td>

                  <td className="whitespace-pre py-4 pl-6 pr-3 text-xs text-grey-600 dark:text-zinc-600">
                    <ContextVersionDifference currentObject={currentVersionObject} previousObject={versions && (versions[0]?.object as ObjectToDiff)} />
                  </td>

                  <td className="hidden whitespace-nowrap py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600 sm:table-cell">
                    {dateAgo(new Date(context.updatedAt))}
                  </td>
                  <td className="whitespace-nowrap w-36 py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600">
                    <div className="flex space-x-3 justify-end">
                      <div className="hidden group-hover:inline-flex space-x-3 transition-all duration-300"></div>
                    </div>
                  </td>
                </tr>

                {versions?.map((version, i) => <ContextVersionRow key={version.id} version={version} previousVersion={versions[i + 1]} i={versions.length - i} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>;
}
function ContextVersionRow({
  version,
  previousVersion,
  i
}: {
  version: ContextVersion & {
    createdBy: User;
  };
  previousVersion?: ContextVersion & {
    createdBy: User;
  };
  i: number;
}) {
  const utils = api.useContext();
  const restoreVersion = api.contextVersion.restoreVersion.useMutation();
  const handleRestoreVersion = async (versionId: number) => {
    await restoreVersion.mutateAsync({
      versionId
    });
    toast.success({
      title: 'Version restored',
      description: 'Successfully restored the version'
    });
    void utils.context.get.invalidate({
      id: version.contextId
    });
    void utils.contextVersion.getAllForContext.invalidate({
      contextId: version.contextId
    });
  };
  return <>
      <tr key={version.id} className="group bg-white dark:bg-black hover:bg-grey-25 dark:hover:bg-zinc-900/50">
        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium">
          <div className="mt-2 flex flex-row space-x-2 items-center">
            <span className="text-xs bg-grey-50 ring-1 ring-black/10 dark:bg-zinc-900 dark:text-white dark:ring-zinc-800 rounded-md p-2">
              Version {i}
            </span>
          </div>
        </td>

        <td className="hidden whitespace-nowrap py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600 sm:table-cell">
          <div className="flex flex-col items-start">
            <UserAvatar className="w-6 h-6" user={version.createdBy} />
          </div>
        </td>

        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-xs text-grey-600 dark:text-zinc-600">
          <ContextVersionDifference currentObject={(version.object as ObjectToDiff)} previousObject={(previousVersion?.object as ObjectToDiff)} />
        </td>

        <td className="hidden whitespace-nowrap py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600 sm:table-cell">
          {dateAgo(new Date(version.updatedAt))}
        </td>
        <td className="whitespace-nowrap w-36 py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600">
          <div className="flex space-x-3 justify-end">
            <ConfirmActionDialog header={`Restore version ${i}`} message={'Are you sure you want to restore this version?'} onConfirm={async () => await handleRestoreVersion(version.id)}>
              {({
              setOpen
            }) => <Button variant="outline" onClick={() => setOpen(true)} startIcon={ArrowCircleLeft}>
                  Restore this version
                </Button>}
            </ConfirmActionDialog>
          </div>
        </td>
      </tr>
    </>;
}
function ContextVersionDifference({
  currentObject,
  previousObject
}: {
  currentObject: ObjectToDiff;
  previousObject?: ObjectToDiff;
}) {
  const previousObjectToDiff: Record<string, unknown> = {};
  const currentObjectToDiff: Record<string, unknown> = {};
  viewableKeys.forEach(key => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    previousObjectToDiff[key] = previousObject ? previousObject[key] : null;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    currentObjectToDiff[key] = currentObject ? currentObject[key] : null;
  });
  return <DiffViewer title={{
    old: 'Previous Version',
    new: 'Current Version'
  }} data={{
    old: previousObject ? previousObjectToDiff : '',
    new: previousObject ? currentObjectToDiff : JSON.stringify(currentObjectToDiff, null, 2)
  }} />;
}

// function ContextVersionDifference({
//   version,
//   previousVersion,
// }: {
//   version: ContextVersion & { createdBy: User }
//   previousVersion?: ContextVersion & { createdBy: User }
// }) {
//   const previousObject: Record<string, unknown> = {}
//   const currentObject: Record<string, unknown> = {}

//   viewableKeys.forEach((key) => {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
//     previousObject[key] =
//       previousVersion && previousVersion.object
//         ? (previousVersion.object as Record<string, unknown>)[key]
//         : null
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
//     currentObject[key] = version.object ? (version.object as Record<string, unknown>)[key] : null
//   })

//   return (
//     <ReactDiffViewer
//       oldValue={previousVersion ? previousObject : ''}
//       newValue={previousVersion ? currentObject : JSON.stringify(currentObject, null, 2)}
//       splitView={false}
//       showDiffOnly={true}
//       hideLineNumbers={true}
//       extraLinesSurroundingDiff={0}
//       compareMethod={previousVersion ? DiffMethod.JSON : DiffMethod.CHARS}
//       codeFoldMessageRenderer={() => {
//         return <></>
//       }}
//       styles={{
//         variables: {
//           light: {
//             codeFoldBackground: '#fff',
//           },
//         },
//       }}
//     />
//   )
// }

const ContextVersionsPage: NextPage = () => {
  const router = useRouter();
  const {
    contextSlug
  } = (router.query as WorkspaceContextIParams);
  const {
    context,
    isLoading
  } = useFetchContextBySlug(contextSlug);
  const {
    workspace
  } = useCurrentWorkspace();
  if (isLoading) {
    return <FullLoaderPage />;
  }
  if (!context || !workspace) {
    return null;
  }
  return <Sidebar>
      <Head>
        <title>{context.name} / Versions</title>
        <meta name="description" content="Skill Update" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="sticky top-0 flex h-16 flex-shrink-0 border-b border-grey-200 dark:border-zinc-800 bg-white dark:bg-black bg-opacity-50 backdrop-blur">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-6">
            <OpenMobileSidebarButton />
            {workspace && <div className="flex flex-1 items-center">
                <Link href={`/${workspace.slug}/context`}>
                  <h1 className="text-xl font-semibold text-grey-800 dark:text-white">
                    Context Library
                  </h1>
                </Link>
                <ChevronRight className="h-5 w-5 mx-2 text-grey-400" aria-hidden="true" />
                <h2 className="text-xl font-semibold text-grey-800 dark:text-white">
                  <Link href={`/${workspace.slug}/context/${context.slug}`}>{context.name}</Link>
                </h2>
              </div>}
          </div>
        </div>
        <main>
          <div>
            <div className="max-w-8xl mx-auto mt-4 px-4">
              <ContextVersionsTable context={context} />
            </div>
          </div>
        </main>
      </div>
    </Sidebar>;
};
const Context404Wrapper = ({
  dne,
  ...props
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <Context404 />;
  }
  return <ContextVersionsPage {...props} />;
};
export default _withSuperJSONPage(Context404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withContext(), []);