import type { Action, App, Dataset } from '@prisma/client'
import { useState } from 'react'
import { getProviderIcon } from '@/client/assets/icons/providers/getProviderIcon'
import { Icon, Sheet, SheetContent } from '@/client/components'
import { useFetchAppActions } from '@/common/hooks'
import { CreateEvalForm } from '../../evals/CreateEvalForm'
import { useWorkspace } from '../../ui/context'

export function CreateEvalDrawer({
  open,
  setOpen,
  app,
  dataset,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  app: App
  dataset?: Dataset
}) {
  const { actions } = useFetchAppActions(app.id)
  const workspace = useWorkspace()
  const [selectedAction, setSelectedAction] = useState<Action | undefined>(undefined)

  if (!actions) {
    return null
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent className="max-w-4xl sm:max-w-5xl p-0">
        {!selectedAction && actions && (
          <div className="flex flex-col gap-1 p-6">
            <div className="pb-3 text-lg font-medium">
              {!selectedAction && 'Select an Action to evaluate'}
            </div>
            <div className="flex items-center justify-between overflow-clip rounded-lg border border-grey-200 dark:border-zinc-800">
              <table className="w-full min-w-full divide-y divide-grey-300 dark:divide-zinc-800">
                <thead className="bg-grey-50 dark:bg-zinc-900">
                  <tr>
                    <th
                      scope="col"
                      className="w-1/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600"
                    >
                      Action
                    </th>
                    <th
                      scope="col"
                      className="hidden w-1/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 whitespace-nowrap md:table-cell"
                    >
                      Model / Provider
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-grey-200 dark:divide-zinc-800">
                  {actions?.map((action) => (
                    <tr
                      key={action.id}
                      className="group bg-white dark:bg-black hover:bg-grey-25 dark:hover:bg-zinc-900/50 cursor-pointer"
                      onClick={() => {
                        setSelectedAction(actions.find((a) => a.id == action.id))
                      }}
                    >
                      <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium">
                        <div className="transition-colors ease-in-out group-hover:text-blue-700">
                          {action.name}
                        </div>
                      </td>
                      <td className="hidden whitespace-nowrap py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600 md:table-cell">
                        <div className={'flex flex-row items-center gap-2'}>
                          {getProviderIcon(action.model.workspaceModelProvider.provider.id) && (
                            <Icon
                              className="text-grey-900 dark:text-white"
                              size="lg"
                              component={
                                getProviderIcon(action.model.workspaceModelProvider.provider.id)!
                              }
                            />
                          )}
                          <div className="flex flex-col items-start">
                            <span className="font-mono text-xs uppercase text-grey-800 dark:text-zinc-500">
                              {action.model.llm}
                            </span>
                            <span className="font-mono text-xs text-grey-500">
                              {action.model.workspaceModelProvider.provider.name}
                              {action.model.workspaceModelProvider.name &&
                                ` (${action.model.workspaceModelProvider.name})`}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {selectedAction && (
          <CreateEvalForm
            action={selectedAction}
            app={app}
            workspace={workspace}
            setOpen={(val) => {
              setSelectedAction(undefined)
              setOpen(val)
            }}
            dataset={dataset}
          />
        )}
      </SheetContent>
    </Sheet>
  )
}
