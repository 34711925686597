import { cva } from 'class-variance-authority'

export const badgeVariants = cva('capitalize py-1 text-xs ring-1 whitespace-nowrap', {
  variants: {
    variant: {
      default: 'inline-flex space-x-1 w-fit',
      delta: 'flex items-center gap-x-1 w-fit',
    },
    color: {
      default:
        'bg-zinc-100 dark:bg-zinc-900 text-zinc-700 dark:text-zinc-300 ring-zinc-300 dark:ring-zinc-700 group-hover:ring-zinc-400 dark:group-hover:ring-zinc-600',
      green:
        'bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300 ring-green-300 dark:ring-green-700 group-hover:ring-green-400 dark:group-hover:ring-green-600',
      blue: 'bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-300 ring-blue-300 dark:ring-blue-700 group-hover:ring-blue-400 dark:group-hover:ring-blue-600',
      orange:
        'bg-orange-100 dark:bg-orange-900 text-orange-700 dark:text-orange-300 ring-orange-300 dark:ring-orange-700 group-hover:ring-orange-400 dark:group-hover:ring-orange-600',
      red: 'bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-300 ring-red-300 dark:ring-red-700 group-hover:ring-red-400 dark:group-hover:ring-red-600',
    },
    corner: {
      squared: 'rounded-md px-2',
      rounded: 'rounded-full px-2.5',
    },
  },
  defaultVariants: {
    color: 'default',
    corner: 'squared',
    variant: 'default',
  },
})
