import { Command as CommandPrimitive } from 'cmdk'
import React from 'react'
import { cn } from '@/client/utils'
import type { CommandSeparatorProps } from './types'

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  CommandSeparatorProps
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 h-px bg-grey-200 dark:bg-zinc-800', className)}
    {...props}
  />
))

CommandSeparator.displayName = CommandPrimitive.Separator.displayName

export { CommandSeparator }
