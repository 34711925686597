import type { ContextSource, Workspace } from '@prisma/client'
import { DownloadCloud01, Edit05, RefreshCw02, Trash03 } from '@untitled-ui/icons-react'
import Link from 'next/link'
import { useState } from 'react'
import { Checkbox, ConfirmActionDialog, toast } from '@/client/components'
import { Room } from '@/common/Room'
import type { DetailedContext } from '@/common/types/context'
import { api } from '@/utils/api'
import { ContextSourceDrawer } from './Drawer'
import { ProcessingStatus } from './ProcessingStatus'

export const ContextSourceRow = ({
  contextSource,
  workspace,
  context,
  selected,
  toggleSelected,
}: {
  contextSource: ContextSource
  workspace: Workspace
  context: DetailedContext
  selected: boolean
  toggleSelected: (id: number) => void
}) => {
  const refreshIndex = api.contextSource.process.useMutation()
  const deleteContextSource = api.contextSource.delete.useMutation()
  const utils = api.useContext()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  const invalidate = async () => {
    await utils.contextSource.getAll.invalidate()
    await utils.contextSource.getAll.invalidate({ contextId: context.id })
    await utils.context.getBySlug.invalidate({ slug: context.slug })
    await utils.contextSource.getStatus.invalidate({ id: contextSource.id })
  }

  const handleProcessFile = async function (contextSource: ContextSource) {
    await refreshIndex.mutateAsync({ guid: contextSource.guid, workspaceId: workspace.id })
    await invalidate()
  }

  const date = contextSource.indexedAt ? new Date(contextSource.indexedAt) : null

  return (
    <>
      <ContextSourceDrawer
        contextSource={contextSource}
        workspace={workspace}
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
      />

      <tr className="group bg-white dark:bg-black hover:bg-grey-25 dark:hover:bg-zinc-900">
        <td className="pl-3">
          <Checkbox
            className={`${selected ? '' : 'opacity-30'} group-hover:opacity-100`}
            checked={selected}
            onCheckedChange={() => {
              toggleSelected(contextSource.id)
            }}
          />
        </td>
        <td className="max-w-xs whitespace-nowrap py-6 pl-6 pr-3 text-sm font-medium">
          <div className="flex flex-row items-center space-x-2">
            <span
              className="cursor-pointer hover:text-blue-700 hover:underline "
              onClick={() => setDrawerIsOpen((prev) => !prev)}
            >
              {contextSource.name}
            </span>
            {contextSource.url && (
              <Link
                download
                target="_blank"
                href={`/api/indices/file-download?fileUrl=${contextSource.url}&workspaceGuid=${workspace.project_guid}`}
              >
                <DownloadCloud01 className="h-5 w-5 hover:text-primary-300" />
              </Link>
            )}
          </div>
        </td>

        <td className="px-3">
          <div className="flex flex-row items-center space-x-3 justify-end">
            <Room id={`contextSource-${contextSource.id}`} hideLoader={true}>
              <ProcessingStatus
                contextSource={contextSource}
                key={`${contextSource.guid}-${date ? date.getTime() : ''}`}
              />
            </Room>
            {contextSource.indexingStatus !== 'PROCESSING' && (
              <button
                type="button"
                title="Reindex File"
                style={{
                  cursor: contextSource.indexingStatus === 'PROCESSING' ? 'not-allowed' : 'pointer',
                }}
                disabled={contextSource.indexingStatus === 'PROCESSING'}
                onClick={() => {
                  void handleProcessFile(contextSource)
                }}
              >
                <RefreshCw02 className="h-5 w-5 hover:text-primary-300" />
              </button>
            )}

            <button
              type="button"
              title="Edit"
              onClick={() => {
                void setDrawerIsOpen(!drawerIsOpen)
              }}
            >
              <Edit05 className="h-5 w-5 hover:text-primary-300"></Edit05>
            </button>
            <ConfirmActionDialog
              header="Delete context source"
              message="Are you sure you want to delete this source?"
              onConfirm={async () => {
                await deleteContextSource.mutateAsync(
                  { id: contextSource.id },
                  {
                    onSuccess: () => {
                      toast.success({
                        title: 'Context source deleted',
                        description: "Successfully deleted the context's source",
                      })
                      void invalidate()
                    },
                    onError: (error) => {
                      toast.error({
                        title: 'Error',
                        description: error.message,
                      })
                    },
                  }
                )
              }}
            >
              {({ setOpen }) => (
                <button type="button" title="Remove Source" onClick={() => setOpen(true)}>
                  <Trash03 className="h-5 w-5 hover:text-red-500" />
                </button>
              )}
            </ConfirmActionDialog>
          </div>
        </td>
      </tr>
    </>
  )
}
