import type { EvalsOnEvalTypes } from '@prisma/client'
import { Card, MarkerBar } from '@tremor/react'
import { CalendarDate } from '@untitled-ui/icons-react'
import Link from 'next/link'
import { ClockIcon, DatasetIcon, EvalRunIcon, StudioIcon } from '@/client/assets/icons/icons'
import { Badge } from '@/client/components'
import { Loader } from '@/common/components'
import { useWorkspace } from '@/common/components/ui/context'
import { useCurrentApp, useFetchEvalsOnEvalTypesForEval } from '@/common/hooks'
import { useFetchDatasetItemsCount } from '@/common/hooks/datasets'
import { useFetchEvalRunsDelta } from '@/common/hooks/evalRuns'
import type { DetailedEval } from '@/common/types/eval'
import { calculateEvalDelta, dateAgo } from '@/utils'
import type { DeltaOutput, ScoreData } from '@/utils/calculateEvalDelta'
import { EvalActionName } from './EvalActionName'
import { EvalLatencyByRunChart } from './EvalLatencyByRunChart'

export function EvalOverview({ evalRecord }: { evalRecord: DetailedEval }) {
  const { app } = useCurrentApp()
  const { slug } = useWorkspace()

  const { totalCount, evalRuns, isLoading } = useFetchEvalRunsDelta({
    evalId: evalRecord.id,
    workspaceId: evalRecord.workspaceId,
  })
  const { evalsOnEvalTypes } = useFetchEvalsOnEvalTypesForEval({
    evalId: evalRecord.id,
  })

  const { data: dataCount } = useFetchDatasetItemsCount(
    app?.workspaceId || 0,
    evalRecord.datasetId || undefined
  )

  const resultMetadata = evalRuns?.map((run) => run.metadata) as ScoreData[]

  let scoresDelta: DeltaOutput[] = []
  if (resultMetadata) {
    scoresDelta = calculateEvalDelta(resultMetadata, totalCount)
  }

  if (!app) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader className={`h-6 w-6 text-grey-400`} />
      </div>
    )
  }

  return (
    <div>
      <div className="grid grid-cols-1 xl:grid-cols-4 gap-6">
        <Card className="group">
          <div className="inline-flex items-center space-x-1">
            <StudioIcon className="h-4 w-4" />
            {evalRecord.action && (
              <Link
                className="hover:text-primary-600"
                href={`/${slug}/apps/${app.slug}/actions/${evalRecord.action?.slug}/studio`}
              >
                <EvalActionName evalRecord={evalRecord} />
              </Link>
            )}
          </div>
          <div className="text-grey-600 dark:text-zinc-500 text-sm">
            {/* this updated date is suspect, it's the same time as the last run */}
            Action updated {dateAgo(new Date(evalRecord.action?.updatedAt || new Date()))}
          </div>
          {evalRecord.dataset && (
            <>
              <div className="inline-flex items-center space-x-1 mt-4">
                <DatasetIcon className="h-4 w-4" />
                <Link
                  className="hover:text-primary-600"
                  href={`/${slug}/apps/${app.slug}/datasets/${evalRecord.dataset.guid}`}
                >
                  <span>{evalRecord.dataset?.name}</span>
                </Link>
              </div>
              <div className="text-grey-600 dark:text-zinc-500 text-sm space-y-2">
                <span>
                  Dataset updated {dateAgo(new Date(evalRecord.dataset?.updatedAt || new Date()))}
                </span>
                <br />
                {dataCount?.evaluationItemsCount ? (
                  <span>
                    {dataCount.evaluationItemsCount}{' '}
                    {dataCount.evaluationItemsCount === 1 ? 'item' : 'items'}
                  </span>
                ) : (
                  <span>No data</span>
                )}
              </div>
            </>
          )}
        </Card>

        {!isLoading && scoresDelta.length > 0 && (
          <>
            <Card>
              <div className="inline-flex items-center space-x-1">
                <ClockIcon className="h-4 w-4" />
                <span>Latency</span>
              </div>
              <EvalLatencyByRunChart evalId={evalRecord.id} />
            </Card>
            <Card>
              <div className="inline-flex items-center space-x-1">
                <EvalRunIcon className="h-4 w-4" />
                <span>Latest Performance</span>
              </div>
              <div className="mt-2">
                {evalsOnEvalTypes &&
                  scoresDelta?.map((scoreDelta, index) => (
                    <PerformanceDelta
                      key={index}
                      scoreDelta={scoreDelta}
                      evalsOnEvalTypes={evalsOnEvalTypes}
                    />
                  ))}
                <div className="text-grey-500 dark:text-zinc-500 text-xs">
                  Compared to previous run
                </div>
              </div>
            </Card>

            <Card>
              <div className="inline-flex items-center space-x-1">
                <CalendarDate className="h-4 w-4" />
                <span>Historical Performance</span>
              </div>
              <div className="mt-2">
                {evalsOnEvalTypes &&
                  scoresDelta
                    ?.filter((s) => Boolean(s.latestScore))
                    .map((scoreDelta, index) => (
                      <div key={index} className="py-2 space-x-2 flex">
                        {scoreDelta.latestScore && (
                          <div className="w-40">
                            <MarkerBar
                              value={scoreDelta.latestScore}
                              minValue={Math.max(
                                0,
                                Math.min(
                                  scoreDelta.historicalScore || 0,
                                  scoreDelta.recentScore || 0
                                ) - 10
                              )}
                              maxValue={Math.min(
                                100,
                                Math.max(
                                  scoreDelta.historicalScore || 0,
                                  scoreDelta.recentScore || 0
                                ) + 10
                              )}
                              color={
                                scoreDelta.latestScore >
                                Math.max(
                                  scoreDelta.historicalScore || 0,
                                  scoreDelta.recentScore || 0
                                )
                                  ? 'green'
                                  : 'red'
                              }
                              className="mt-1.5"
                            />
                          </div>
                        )}
                        <span className="pl-4 text-sm whitespace-nowrap">
                          {evalsOnEvalTypes?.find(
                            (evalTypeOnEval) => evalTypeOnEval.id === scoreDelta.evalOnEvalTypeId
                          )?.name || ''}
                        </span>
                      </div>
                    ))}
                <div className="text-grey-500 dark:text-zinc-500 text-xs">
                  Compared to first and previous run
                </div>
              </div>
            </Card>
          </>
        )}
      </div>
    </div>
  )
}
function PerformanceDelta({
  scoreDelta,
  evalsOnEvalTypes,
}: {
  scoreDelta: DeltaOutput
  evalsOnEvalTypes?: EvalsOnEvalTypes[]
}) {
  if (!evalsOnEvalTypes) {
    return null
  }

  let label =
    evalsOnEvalTypes.find((evalTypeOnEval) => evalTypeOnEval.id === scoreDelta.evalOnEvalTypeId)
      ?.name || ''

  label = label.replace('Review', '')

  return (
    scoreDelta.deltaRecent !== undefined &&
    scoreDelta.deltaRecent !== null && (
      <>
        <div className="py-2 space-x-2 flex flex-wrap">
          <Badge
            variant="delta"
            delta={
              parseFloat(scoreDelta.deltaRecent.toFixed(2)) > 0.0
                ? 'moderateIncrease'
                : parseFloat(scoreDelta.deltaRecent.toFixed(2)) < 0.0
                  ? 'moderateDecrease'
                  : 'unchanged'
            }
          >
            <span>{Math.abs(Number(scoreDelta.deltaRecent)).toFixed(0)}% </span>
            <span>{label}</span>
          </Badge>
        </div>
      </>
    )
  )
}
