import type { Workspace } from '@prisma/client'
import { useRouter } from 'next/router'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

export const useDeleteWorkspace = ({ workspace }: { workspace: Workspace }) => {
  const router = useRouter()
  const deleteWorkspace = api.workspace.delete.useMutation()
  const utils = api.useContext()
  const { user } = useUser()

  async function handleDelete() {
    try {
      await deleteWorkspace.mutateAsync({ workspaceId: workspace.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.workspace_deleted, {
        'Workspace ID': workspace.id,
        'Workspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })
      await utils.workspace.getAll.invalidate()
      toast.success({
        title: 'Workspace deleted',
        description: 'Successfully deleted the workspace',
      })
      await router.push(`/`)
    } catch (e) {
      console.error(e)
    }
  }

  return { handleDelete }
}
