import Link from 'next/link'
import { useRouter } from 'next/router'
import type {
  ChatCompletionAssistantMessageParam,
  ChatCompletionMessageParam,
} from 'openai/resources'
import { ToolCallsRenderer } from '@/client/containers/views/Studio/components/Tabs/Chat/components/Main/components/Messages/components/ChatMessage'
import { MessagePromptRenderer } from '@/common/components/tables/v2/drawer'
import { useWorkspace } from '@/common/components/ui/context'
import type { DetailedEvalRunItem } from '@/common/types/eval'
import MarkdownRenderer from '../../../tables/markdown'

export const ActualOutputCell = ({ value: evalRunItem }: { value: DetailedEvalRunItem }) => {
  const workspace = useWorkspace()
  const router = useRouter()
  if (!evalRunItem) return null
  const { slug: appSlug } = router.query as { slug: string }

  let message: ChatCompletionMessageParam | undefined
  try {
    message = JSON.parse(evalRunItem.output!) as ChatCompletionMessageParam
  } catch (error) {}

  return (
    <div
      key={`${evalRunItem.id}-data`}
      className="whitespace-pre-wrap max-h-[200px] overflow-y-auto overflow-x-hidden leading-normal"
    >
      {!evalRunItem.data && (
        <div className="text-xs text-red-500 font-semibold py-3">Generation failed</div>
      )}

      {evalRunItem.data && (
        <>
          <div className="text-sm">
            {message?.role ? (
              <div className="whitespace-pre-wrap py-3 max-h-[200px] overflow-y-auto overflow-x-hidden leading-normal">
                <MessagePromptRenderer role={message.role} content={message.content!} />
                {(message as ChatCompletionAssistantMessageParam).tool_calls && (
                  <div className="flex flex-col gap-2">
                    <ToolCallsRenderer
                      toolCalls={(message as ChatCompletionAssistantMessageParam).tool_calls}
                    />
                  </div>
                )}
              </div>
            ) : (
              <MarkdownRenderer markdownText={evalRunItem.output!} className="py-3" />
            )}
          </div>
          {workspace ? (
            <Link
              href={`/${workspace.slug}/apps/${appSlug}/data?guid=${evalRunItem.data.guid}`}
              className="text-xs text-grey-400 dark:text-zinc-400"
            >
              {evalRunItem.data.guid}
            </Link>
          ) : (
            <div className="text-xs text-grey-400 dark:text-zinc-400">{evalRunItem.data.guid}</div>
          )}
        </>
      )}
    </div>
  )
}
