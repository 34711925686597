import { cn } from '@/client/utils'
import type { SkeletonProps } from './types'

function Skeleton({ className, ...props }: SkeletonProps) {
  return (
    <div
      className={cn('h-3 animate-pulse rounded bg-grey-200 dark:bg-zinc-800', className)}
      {...props}
    />
  )
}

export { Skeleton }
