import { getMimeType } from '@/common/lib/getMimeType'
import { env } from '@/env.mjs'

export const sanitizeImageURL = (url: string) => {
  if (url?.match(/https?:\/\//)) {
    return url.includes('amazonaws.com') ? `/api/files/download?fileUrl=${url}` : url
  } else if (!url.includes('data:')) {
    return 'data:' + (getMimeType(url.slice(0, 50) || '') || '') + ';base64, ' + url
  } else {
    return url
  }
}

export const getProxiedImageURL = (url: string) => {
  const apiBaseUrl = env.NEXT_PUBLIC_API_URL

  if (url?.match(/https?:\/\//)) {
    // proxy images on s3
    return url.includes('amazonaws.com') ? `${apiBaseUrl}/api/files/proxy?url=${url}` : url
  } else if (!url.includes('data:')) {
    // add data/mime to base64 images that don't have it
    return 'data:' + (getMimeType(url.slice(0, 50) || '') || '') + ';base64, ' + url
  } else {
    // return other b64 as is
    return url
  }
}
