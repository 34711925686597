import type { Action, Experiment, User } from '@prisma/client'
import { useState } from 'react'
import { Button, Dialog } from '@/client/components'
import MarkdownRenderer from '@/common/components/tables/markdown'
import { useFetchActionBySlug } from '@/common/hooks'
import { classNames } from '@/utils'

function ExperimentCodeForm({
  setOpenAPIModal,
  experiment,
}: {
  setOpenAPIModal: (val: boolean) => void
  experiment: Experiment & { actionPrimary: Action; actionSecondary: Action }
}) {
  const [contentType, setContentType] = useState('cURL')

  const { action: action1 } = useFetchActionBySlug(experiment.actionPrimary.slug)
  const { action: action2 } = useFetchActionBySlug(experiment.actionSecondary.slug)
  interface actionInputs {
    [key: string]: string
  }
  const actionInputs: actionInputs = {}
  if (action1 && action2) {
    const promptVariables1 = action1.prompt.match(/{{(.*?)}}/g)?.map((match) => match.slice(2, -2))
    const promptVariables2 = action2.prompt.match(/{{(.*?)}}/g)?.map((match) => match.slice(2, -2))
    const promptVariables = [...new Set([...(promptVariables1 || []), ...(promptVariables2 || [])])]

    if (promptVariables && promptVariables?.length > 0) {
      promptVariables.forEach((variable) => {
        actionInputs[variable] = `${variable.toUpperCase()} VALUE`
      })
    } else {
      actionInputs['input'] = 'YOUR PROMPT GOES HERE'
    }
  } else {
    actionInputs['input'] = 'YOUR PROMPT GOES HERE'
  }

  const curlActionInputs = actionInputs['input']
    ? `"INPUT VALUE"`
    : `{${Object.keys(actionInputs)
        .map((k) => `  "${k}": "${k.toUpperCase()} VALUE"`)
        .join(',\n')}`

  const pythonActionInputs = actionInputs['input']
    ? `"INPUT VALUE"`
    : `{\n${Object.keys(actionInputs)
        .map((k) => `        "${k}": "${k.toUpperCase()} VALUE"`)
        .join(',\n')}\n    }`

  const curlCommand = `curl --location --request POST 'https://api.klu.ai/v1/experiments/${experiment.guid}/prompt' \\
--header 'Authorization: Bearer YOUR_TOKEN' \\
--header 'Content-Type: application/json' \\
--data-raw '{"input": ${curlActionInputs} }'`

  const pythonSDKLine = `from klu import Klu 
 
client = Klu('apikey') 
result = await client.experiments.prompt(
    '${experiment.guid}', 
    ${pythonActionInputs}
)
`
  const tsActionInputs = actionInputs['input']
    ? `"INPUT VALUE"`
    : `{\n${Object.keys(actionInputs)
        .map((k) => `        "${k}": "${k.toUpperCase()} VALUE"`)
        .join(',\n')}\n    }`
  const typescriptString = `import Klu from '@kluai/core';

const klu = new Klu('apikey');

async function prompt() {
  const result = await klu.experiments.prompt(
    '${experiment.guid}', 
    ${tsActionInputs}
  )
  console.log(result.msg);
};

prompt();
`

  const getContent = () => {
    if (contentType === 'cURL') {
      return curlCommand
    } else if (contentType === 'Python') {
      return pythonSDKLine
    } else if (contentType === 'TypeScript') {
      return typescriptString
    }
  }
  const GUID = experiment.guid

  const getGUID = () => {
    return `\`\`\`md\n${GUID}`
  }

  const getMarkdownContent = () => {
    const content = getContent()
    if (!content) {
      return ''
    }

    return `
\`\`\`${contentType.toLocaleLowerCase()}
${content}
\`\`\`
`
  }

  return (
    <>
      <div className="rounded-lg bg-white dark:bg-black p-4 shadow-sm">
        <div className="mb-4 flex items-center border-b border-grey-200 dark:border-zinc-800 pb-4">
          <h3 className="w-full items-center pt-1 text-lg font-medium">
            Deploy <span className="text-grey-900 dark:text-white">{experiment.name}</span>
          </h3>
          <div className="ml-auto w-full">
            <select
              className="mt-2 w-full rounded-md border border-grey-300 bg-grey-50 p-2 px-4 py-2 text-sm focus:border-grey-500 focus:ring-grey-500"
              value={contentType}
              onChange={(e) => setContentType(e.target.value)}
            >
              <option value="Python">Python SDK</option>
              <option value="TypeScript">TypeScript Fetch</option>
              <option value="cURL">cURL Request</option>
            </select>
          </div>
        </div>

        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <div className="">
            <div className="mt-3">
              {contentType === 'url' && (
                <div className="mt-3">
                  <p className="pl-3 font-mono text-xs">Public URL for {experiment.name}</p>
                </div>
              )}
              {contentType === 'Python' && (
                <div className="mt-3">
                  <p className="pl-3 font-mono text-xs">Python SDK for {experiment.name}</p>
                </div>
              )}
              <MarkdownRenderer markdownText={getMarkdownContent()} />

              {/* <pre
                id="key"
                className="mt-3 block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-xs p-2 bg-grey-100 overflow-auto"
              >
                <code className={`language-${contentType.toLowerCase()}`}>{getContent()}</code>
              </pre> */}
            </div>
            <div className="mt-4 border-t border-grey-200 dark:border-zinc-800 pt-4">
              <div className="pl-3 font-mono text-xs">{experiment.name} Action GUID</div>
              <span className="w-full items-center">
                <MarkdownRenderer markdownText={getGUID()} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div className="items-center justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-4 py-5 sm:flex sm:px-6">
        <Button
          onClick={() => setOpenAPIModal(false)}
          className={classNames(
            'flex w-full justify-center rounded-md border border-transparent bg-black px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-grey-800 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2 sm:text-sm'
          )}
        >
          Close
        </Button>
      </div>
    </>
  )
}

export const ExperimentAPIDetailsModal = ({
  open,
  setOpen,
  experiment,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  experiment:
    | (Experiment & { createdBy: User; actionPrimary: Action; actionSecondary: Action })
    | null
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          {experiment && <ExperimentCodeForm experiment={experiment} setOpenAPIModal={setOpen} />}{' '}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
