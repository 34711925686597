import type { Action, ActionSession, Workspace } from '@prisma/client'
import {
  ChevronSelectorVertical,
  MessageSquare02,
  PlayCircle,
  Variable,
} from '@untitled-ui/icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Popover, Select, toast } from '@/client/components'
import { Loader } from '@/common/components'
import { useStreamStore } from '@/common/stream/stream/streamStore'
import { api, useTokenCount } from '@/utils'
import { RenderFromDataRecordGuid } from './workflows/RenderFromDataGuid'

export type PromptStyle = 'default' | 'widget'

export default function ActionConversationPromptForm({
  action,
  workspace,
  session,
  style = 'default',
  state,
  hideRunButton,
}: {
  action: Action
  workspace: Workspace
  session?: ActionSession
  style?: PromptStyle
  state?: string[]
  hideRunButton?: boolean
}) {
  const prompt = api.action.prompt.useMutation()
  const [dataRecordGuid, setDataRecord] = useState<string | null>(null)

  const [templateVars, setTemplateVars] = useState<string[]>([])
  const [values, setValues] = useState({})

  const [query, setQuery] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const util = api.useContext()

  const updateQuery = useCallback((value: string) => {
    setQuery(value)
  }, [])

  const update = useCallback(
    (name: string, value: string) => {
      const updatedValues = { ...values, [name]: value }
      setValues(updatedValues)
    },
    [values]
  )
  const { addStream } = useStreamStore()

  const handlePrompt = useCallback(async () => {
    if (query.length < 1 && Object.keys(values).length < 1) {
      return
    }

    if ((!query && !values) || loading) return
    setLoading(true)
    await prompt.mutateAsync(
      {
        prompt: Object.keys(values).length > 0 ? values : query,
        agentGuid: action && action.guid,
        sessionGuid: session && session.guid,
      },
      {
        onSettled: () => {
          if (session) {
            void util.data.getAllForSession.invalidate({
              sessionId: session.id,
            })
          }

          if (action) {
            void util.data.getInfiniteAllForApp.invalidate({
              appId: action.appId,
            })
          }

          setLoading(false)
          setQuery('')
        },
        onError: (err) => {
          toast.error({
            title: 'Something went wrong',
            description: err.message,
          })
        },
        onSuccess: (res) => {
          if (res.dataGuid) {
            setDataRecord(res.dataGuid)

            if (res.streaming_url) {
              addStream({ dataGuid: res.dataGuid, streamingUrl: res.streaming_url })
              // addStreamingUrl({ dataGuid: res.data_guid, streamingUrl: res.streaming_url })
            }
          }
        },
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query,
    values,
    loading,
    prompt,
    workspace.project_guid,
    action,
    session,
    util.data.getAllForSession,
    util.data.getInfiniteAllForApp,
  ])

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (hideRunButton) return
      if ((e.metaKey && e.key === 'Enter') || e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        void handlePrompt()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePrompt])

  useEffect(() => {
    const regex = /{{(.*?)}}/g
    const promptMatches = action.prompt.match(regex) || []
    const systemMessageMatches = action.systemMessage?.match(regex) || []
    const matches = Array.from(new Set([...promptMatches, ...systemMessageMatches]))

    const newTemplateVars: string[] = []

    if (matches) {
      matches.forEach((match) => {
        const name = match.replace('{{', '').replace('}}', '')
        if (name) {
          newTemplateVars.push(name)
        }
      })
    }
    setTemplateVars([...new Set(newTemplateVars)])
  }, [action.prompt, action.systemMessage])

  const promptValues = templateVars.reduce(
    (acc, key) => {
      if (key in values) {
        acc[key] = values[key as keyof typeof values]
      }
      return acc
    },
    {} as Record<string, string>
  )

  // computed query based on the values

  const computedQuery =
    templateVars.length > 0
      ? action.prompt.replace(/{{(.*?)}}/g, (match: string, name: string) => {
          return promptValues[name] || `${match}`
        })
      : `${action.prompt}${query}`

  const { tokenCount } = useTokenCount(computedQuery, 'text-davinci-003')

  // const promptString = agent.prompt.replace(/\{\{/g, '%%').replace(/\}\}/g, '%%')
  // const matches = [...new Set(agent.prompt.match(/{{(.*?)}}/g))]

  if (style === 'widget') {
    return (
      <>
        <div className="">
          {templateVars.length > 0 && (
            <>
              <div className="mb-5">
                {templateVars.map((var_name, i) => {
                  return (
                    <PromptDataItem
                      key={i}
                      name={var_name}
                      value={values[var_name as keyof typeof values] || ''}
                      update={update}
                      state={state}
                      style="widget"
                    />
                  )
                })}
              </div>
              <div className="flex items-center justify-end">
                <Button onClick={() => void handlePrompt()}>
                  <PlayCircle className="h-5 w-5" aria-hidden="true" />
                  <span className="ml-2">Test</span>
                </Button>
              </div>
            </>
          )}
          {templateVars.length < 1 && (
            <div className="mt-2 flex">
              {/* <AutocompleteInput
                value={query}
                onChange={(value) => updateQuery(value)}
                state={state}
                placeholder="Enter your next message to the action..."
                className="block w-full text-sm rounded-none rounded-l-md border-0 border-grey-100 px-3 py-1.5 text-grey-900 ring-1 ring-inset ring-grey-400 placeholder:text-grey-400 focus:ring-1 focus:ring-inset focus:ring-grey-400 focus:border-grey-400 sm:text-sm sm:leading-6"
              /> */}
              <input
                type="text"
                name="prompt"
                id="prompt"
                value={query}
                onChange={(e) => updateQuery(e.target.value)}
                className="mr-4 block w-full rounded-md border-grey-300 shadow-sm focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                placeholder="Enter your next message to the action..."
              />
              <Button onClick={() => void handlePrompt()}>
                <PlayCircle className="h-5 w-5" aria-hidden="true" />
                <span className="ml-2">Test</span>
              </Button>
            </div>
          )}
          <details className="mt-2 px-2 text-sm">
            <summary className="cursor-pointer">
              Show full prompt{' '}
              <span className="text-xs uppercase text-grey-500 dark:text-zinc-500">
                {tokenCount} tokens{' '}
                <span className="text-grey-400 dark:text-zinc-600">(estimated)</span>
              </span>
            </summary>
            <div className="mt-2 rounded-md bg-grey-50 p-3 px-4 text-grey-800 dark:bg-zinc-900 dark:text-zinc-300">
              <pre className="max-w-fit whitespace-pre-wrap text-xs">{computedQuery}</pre>
            </div>
          </details>
        </div>
        {error && (
          <>
            <p className="text-red-600">{error}</p>
            <div className="mt-6">
              <button
                className="rounded bg-grey-600 px-4 py-2 shadow"
                onClick={() => setError(null)}
              >
                Clear
              </button>
            </div>
          </>
        )}
        {dataRecordGuid && <RenderFromDataRecordGuid dataRecordGuid={dataRecordGuid} />}
      </>
    )
  }

  return (
    <div className="bg-grey-50 dark:bg-zinc-900">
      <div className="sticky border-b px-6 py-6">
        {templateVars.length > 0 && (
          <div className="mb-5">
            {templateVars.map((var_name, i) => {
              return (
                <PromptDataItem
                  key={i}
                  name={var_name}
                  value={values[var_name as keyof typeof values] || ''}
                  update={update}
                />
              )
            })}
          </div>
        )}
        <div className="flex">
          {templateVars.length < 1 && (
            <>
              <label htmlFor="prompt" className="sr-only">
                Prompt
              </label>
              <input
                type="text"
                name="prompt"
                id="prompt"
                autoFocus={true}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="mr-4 block w-full rounded-md border-grey-300 shadow-sm focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                placeholder="Enter your next message to the action..."
              />
            </>
          )}

          <Button onClick={() => void handlePrompt()}>
            <MessageSquare02 className="h-6 w-6 font-semibold" />
            <span className="ml-2">Send</span>
          </Button>
        </div>
        <details className="mt-2 px-2 text-sm">
          <summary className="cursor-pointer">
            Show full prompt{' '}
            <span className="text-xs uppercase text-grey-500 dark:text-zinc-500">
              {tokenCount} tokens{' '}
              <span className="text-grey-400 dark:text-zinc-600">(estimated)</span>
            </span>
          </summary>
          <div className="mt-2 rounded-md bg-grey-50 p-3 px-4 text-grey-800 dark:bg-zinc-900 dark:text-zinc-300">
            <pre className="max-w-fit whitespace-pre-wrap text-xs">{computedQuery}</pre>
          </div>
        </details>
      </div>

      {loading && (
        <div className="flex items-center justify-center pt-6">
          <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
        </div>
      )}

      {error && (
        <div className="px-6 py-6">
          <p className="text-red-600">{error}</p>
          <div className="mt-6">
            <button className="rounded bg-grey-600 px-4 py-2 shadow" onClick={() => setError(null)}>
              Clear
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

function StateDropdown({
  state,
  selected,
  onSelect,
}: {
  state: string[]
  selected: string
  onSelect: (value: string) => void
}) {
  const [open, setOpen] = useState(false)
  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <input
          onChange={(e) => onSelect(e.target.value)}
          value={selected}
          type="text"
          className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-grey-900 ring-1 ring-inset ring-grey-300 placeholder:text-grey-400 focus:ring-grey-400 focus:ring-inset focus:outline-none sm:text-sm"
          placeholder="Select an input"
        />
        <Popover.Trigger asChild>
          <button className="-ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-grey-900 ring-1 ring-inset ring-grey-300  hover:bg-grey-50 dark:ring-zinc-800 dark:bg-black dark:hover:bg-zinc-900 dark:text-zinc-600">
            <ChevronSelectorVertical className="h-5 w-5 text-grey-400" aria-hidden="true" />
          </button>
        </Popover.Trigger>
      </div>

      <Popover.Content className="max-w-md w-screen">
        <div className="p-0">
          <Select.Field
            id="state"
            options={state.map((option) => ({ label: option, value: option }))}
            value={selected}
            onChange={(newValue) => onSelect(newValue)}
            hideLabel
          />
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

function StateEditor({
  state,
  value,
  onChange,
}: {
  state: string[]
  value: string
  onChange: (value: string) => void
}) {
  if (state.length > 0) {
    return <StateDropdown state={state} selected={value} onSelect={(value) => onChange(value)} />
  }

  return (
    <input
      placeholder="Enter the variable value"
      className="block w-full rounded-md border-0 border-grey-100 px-3 py-1.5 text-sm text-grey-900 ring-0 ring-inset focus:border-none ring-grey-300 focus:ring-grey-400 focus:ring-inset placeholder:text-grey-400 focus:border-grey-400 focus:ring-1 sm:text-sm sm:leading-6"
      onChange={(e) => onChange(e.target.value)}
      value={value}
    />
  )
}

function PromptDataItem({
  name,
  value,
  update,
  state,
  style,
}: {
  name: string
  value: string
  update: (name: string, value: string) => void
  state?: string[]
  style?: PromptStyle
}) {
  if (style === 'widget') {
    return (
      <div className="">
        <div className="flex flex-row items-center space-x-4 text-sm">
          <div className="w-1/6 text-sm capitalize leading-6 text-grey-600 dark:text-zinc-600 font-inter flex">
            <Variable className="w-5 h-5 pt-1" />
            <span>{name}</span>
          </div>
          <div className="w-5/6">
            <StateEditor
              state={state ? state : []}
              value={value}
              onChange={(value) => update(name, value)}
            />
            {/* <input
              type="text"
              name="prompt"
              id="prompt"

              className="block w-full text-sm rounded-md border-0 border-grey-100 px-3 py-1.5 text-grey-900 ring-1 ring-inset ring-grey-400 placeholder:text-grey-400 focus:ring-1 focus:ring-inset focus:ring-grey-400 focus:border-grey-400 sm:text-sm sm:leading-6"
              placeholder="Enter your next message to the action..."
            /> */}
            {/* <AutocompleteInput
              onChange={(value) => update(name, value)}
              value={value}
              state={state}
              placeholder="Enter a variable"
              className="block w-full text-sm rounded-md border-0 border-grey-100 px-3 py-1.5 text-grey-900 ring-1 ring-inset ring-grey-400 placeholder:text-grey-400 focus:ring-1 focus:ring-inset focus:ring-grey-400 focus:border-grey-400 sm:text-sm sm:leading-6"
            /> */}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="">
      <div className="flex flex-row items-center space-x-4 text-sm">
        <div className="w-1/6">
          <p>{name}</p>
        </div>
        <div className="w-5/6">
          <input
            className="ml-3 block w-full rounded-md border-0 border-grey-100 px-3 py-1.5 text-sm text-grey-900 ring-1 ring-inset ring-grey-300 placeholder:text-grey-400 focus:border-grey-400 focus:ring-1 focus:ring-inset focus:ring-grey-400 sm:text-sm sm:leading-6"
            onChange={(e) => update(name, e.target.value)}
            value={value}
            placeholder="Enter a variable"
          />
        </div>
      </div>
    </div>
  )
}
