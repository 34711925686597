import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { CodeBlock } from './CodeBlock'
import type { MarkdownRendererProps } from './types'

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  markdownText,
}): React.ReactElement => (
  <ReactMarkdown
    remarkPlugins={[remarkGfm]}
    className={'flex flex-col gap-4 select-text font-inter'}
    components={{
      code: CodeBlock,
      p: ({ ...props }) => <p {...props} className="m-0 p-0" />,
      ul: ({ ...props }) => <ul {...props} className="m-0 list-outside ml-4 list-disc p-0" />,
      ol: ({ ...props }) => <ol {...props} className="m-0 list-decimal pl-5 pr-0 py-0" />,
      li: ({ ...props }) => <li {...props} className="leading-1 px-2" />,
      a: ({ ...props }) => <a {...props} className="text-primary hover:underline" />,
      strong: ({ ...props }) => <strong {...props} className="font-bold" />,
      em: ({ ...props }) => <em {...props} className="italic" />,
      del: ({ ...props }) => <del {...props} className="line-through" />,
      h1: ({ ...props }) => <h1 {...props} className="my-1 text-2xl" />,
      h2: ({ ...props }) => <h2 {...props} className="my-1 text-xl" />,
      h3: ({ ...props }) => <h3 {...props} className="my-1 text-lg" />,
      h4: ({ ...props }) => <h4 {...props} className="text-md my-1" />,
      h5: ({ ...props }) => <h5 {...props} className="my-1 text-base" />,
      h6: ({ ...props }) => <h6 {...props} className="my-1 text-sm" />,
      hr: ({ ...props }) => (
        <hr {...props} className="dark:!border-transparent dark:!border-none" />
      ),
    }}
  >
    {markdownText.trim()}
  </ReactMarkdown>
)

export { MarkdownRenderer }
