import { AlertTriangle } from '@untitled-ui/icons-react'
import { useMemo } from 'react'
import { Button, Label } from '@/client/components'
import { FieldError, Loader, Select } from '@/common/components/forms'
import { SelectAllRows } from '../../context'
import { useTransformDatasetItemsForm } from './form'
import type { FormProps } from './types'

export const TransformDatasetItemsForm = ({
  onIsOpenChange,
  isOpen,
  onUpdateSuccess,
}: FormProps & {
  onDeleteSuccess?: () => void
}) => {
  const { formik, loading, selectedIds, selectAllRows, dataCount } = useTransformDatasetItemsForm({
    onIsOpenChange,
    isOpen,
    onUpdateSuccess,
  })

  const transformationOptions = useMemo(() => {
    return [
      {
        id: 'llm_rewrite',
        name: 'LLM Rewrite',
        config: {
          name: 'Instructions',
          description: (
            <>
              Instructions for how to modify the data. You can use variables{' '}
              <span className="font-mono text-xs">{`{{input}}`}</span> and{' '}
              <span className="font-mono text-xs">{`{{output}}`}</span>
            </>
          ),
        },
      },

      // {
      //   id: 'redact_pii',
      //   name: 'Redact PII',
      // },
      // {
      //   id: 'strip_whitespace',
      //   name: 'Strip Whitespace',
      // },
    ]
  }, [])

  const attributeOptions = [
    {
      id: 'input',
      name: 'Input',
    },
    {
      id: 'output',
      name: 'Output',
    },
  ]

  const transformationConfigOptions = useMemo(() => {
    const selectedTransformation = transformationOptions.find(
      (option) => option.id === formik.values.transformation
    )
    return selectedTransformation?.config
  }, [formik.values.transformation, transformationOptions])

  return (
    <div className="flex flex-col space-y-4">
      <div className="px-6 flex flex-col space-y-4">
        <div>
          <div className="mt-1 flex items-center justify-between pb-2">
            <label htmlFor="user" className="block text-sm text-grey-600 dark:text-zinc-600">
              {selectAllRows ? (
                <span className="text-grey-600 dark:text-zinc-600">
                  All rows selected ({dataCount} items)
                </span>
              ) : (
                <>
                  {selectedIds && (
                    <>
                      <span className="font-medium text-grey-800 dark:text-zinc-500">
                        {selectedIds.length}
                      </span>{' '}
                      currently selected.
                    </>
                  )}
                </>
              )}
            </label>
          </div>
          <div className="mt-4 flex flex-col space-y-2 border-t border-grey-200 dark:border-zinc-800 pt-4">
            {!loading && (
              <div className="py-3 flex flex-col space-y-6">
                <div className="space-y-3">
                  <Label
                    htmlFor="attribute"
                    title="Attribute"
                    description="Which dataset item attribute to update"
                  />

                  <Select.Single
                    defaultOption={{ name: 'Input', id: 'input' }}
                    fieldName="attribute"
                    placeholder="Select attribute"
                    position="below"
                    options={attributeOptions}
                    showAvatars={false}
                    onSelect={(val) => {
                      if (val) {
                        void formik.setFieldValue('attribute', val.id)
                      }
                    }}
                  />

                  <FieldError fieldName="attribute" formik={formik} />
                </div>

                {transformationOptions && Object.keys(transformationOptions).length > 1 && (
                  <div className="space-y-3">
                    <Label
                      htmlFor="transformation"
                      title="Transformation"
                      description="Choose the transformation to apply to the selected items"
                    />
                    <Select.Single
                      defaultOption={null}
                      fieldName="transformation"
                      placeholder="Select transformation"
                      position="below"
                      options={transformationOptions}
                      showAvatars={false}
                      onSelect={(val) => {
                        if (val) {
                          void formik.setFieldValue('transformation', val.id)
                        }
                      }}
                    />

                    <FieldError fieldName="transformation" formik={formik} />
                  </div>
                )}

                {transformationConfigOptions && (
                  <div className="space-y-3">
                    <Label
                      htmlFor="transformation"
                      title={transformationConfigOptions.name}
                      description={transformationConfigOptions.description}
                    />

                    <textarea
                      className="w-full h-24 p-2 border border-grey-200 dark:border-zinc-800 dark:bg-transparent dark:text-white dark:placeholder:text-zinc-600 dark:focus:ring-white rounded-lg font-mono text-sm"
                      placeholder="Enter config"
                      value={formik.values.config as string}
                      onChange={(e) => {
                        formik.setFieldValue('config', e.target.value).catch(console.error)
                      }}
                    />

                    <FieldError fieldName="config" formik={formik} />
                  </div>
                )}

                <div className="flex items-center space-x-3 p-3 rounded-md ring-1 bg-orange-100 dark:bg-orange-900 text-orange-700 dark:text-orange-300 ring-orange-300 dark:ring-orange-700">
                  <AlertTriangle className="w-4 h-4 " />
                  <p className="font-mono text-xs">This cannot be undone, proceed with caution</p>
                </div>
              </div>
            )}
          </div>

          <div className="mt-4 flex flex-col space-y-2 border-t border-grey-200 dark:border-zinc-800 pt-4">
            <SelectAllRows />

            {loading && <Loader className="h-6 w-6" />}
          </div>
        </div>
      </div>
      <div className="mt-3 flex items-center space-x-4 justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button className="w-full" onClick={() => onIsOpenChange(false)} variant="outline">
          Cancel
        </Button>
        <Button className="w-full" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </div>
    </div>
  )
}
