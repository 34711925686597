import { Button, Select } from '@/client/components'
import { api } from '@/utils'
import { useDeleteFeedbackBehaviourForm } from './form'
import type { FormProps } from './types'

export const Form = ({ workspace, onIsOpenChange, feedbackBehaviour }: FormProps) => {
  const { formik } = useDeleteFeedbackBehaviourForm({
    workspace,
    onIsOpenChange,
    feedbackBehaviour,
  })
  const { handleSubmit } = formik
  const { data: behaviourValues, isLoading } = api.feedback.getBehaviourFeedbackValues.useQuery()
  const actionOptions = [
    { value: 'saved', label: 'Saved' },
    { value: 'deleted', label: 'Deleted' },
    { value: 'selected', label: 'Selected' },
    { value: 'copied', label: 'Copied' },
    { value: 'shared', label: 'Shared' },
  ]

  const behaviourTypeOptions = behaviourValues
    ? behaviourValues?.map((behaviour) => ({
        value: behaviour,
        label: behaviour,
      }))
    : []

  return (
    <div className="flex flex-col">
      <div className="px-6 pb-3">
        <p className="py-3 text-sm text-grey-600 dark:text-zinc-600">
          Are you sure you want to delete {feedbackBehaviour}?
        </p>
        <div className="flex flex-col space-y-4">
          <Select.Field
            disabled={isLoading}
            id="map-to"
            label={`Select an option to map ${feedbackBehaviour} to (optional)`}
            placeholder={isLoading ? 'Loading...' : `Select an option`}
            className="hover:bg-grey-25"
            options={[...actionOptions, ...behaviourTypeOptions]}
            value={formik.values.mapTo}
            onChange={(newValue) => {
              void formik.setFieldValue('mapTo', newValue)
            }}
          />
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => {
            handleSubmit()
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}
