import type { Workspace } from '@prisma/client'
import { useRouter } from 'next/router'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

export const useLeaveWorkspace = ({ workspace }: { workspace: Workspace }) => {
  const router = useRouter()
  const leaveWorkspace = api.workspace.leave.useMutation()
  const utils = api.useContext()
  const { user } = useUser()

  async function handleLeave() {
    await leaveWorkspace.mutateAsync(
      { workspaceId: workspace.id },
      {
        onSuccess: () => {
          void utils.workspace.getAll.invalidate()
          toast.success({
            title: 'Successfully left Workspace',
            description: `You've left ${workspace.name}`,
          })
          if (user) {
            const id = user.id ? user.id : ''
            const email = user.email ? user.email : ''
            const uname = user.name ? user.name : ''
            identify_and_group(id, email, uname, workspace?.slug)
          }
          track(EVENT_NAMES.workspace_left, {
            'Workspace ID': workspace.id,
            'Workspace Name': workspace.name,
            'User ID': user?.id,
            'User Email': user?.email,
            'User Name': user?.name,
          })
          void router.push(`/`)
        },
        onError: (e) => {
          toast.error({
            title: 'Error leaving Workspace',
            description: e.message,
          })
        },
      }
    )
  }

  return { handleLeave }
}
