import { ClientSideSuspense } from '@liveblocks/react'
import { RoomProvider } from '@liveblocks/react/suspense'
import type { Workspace } from '@prisma/client'
import type { ReactNode } from 'react'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { subscriptionNotRequiredPaths } from '@/common/config/constants'
import { useCheckWorkspaceSubscription } from '@/common/hooks'
import { FullLoaderPage } from './FullLoaderPage'

export type Theme = 'light' | 'dark' | 'system'

export const themeOptions: Theme[] = ['system', 'dark', 'light']

interface ThemeContextType {
  theme: Theme
  setTheme: (theme: Theme) => void
  systemTheme?: Exclude<Theme, 'system'>
  state: {
    isDark: boolean
    isLight: boolean
  }
}

const ThemeContext = React.createContext<ThemeContextType | null>(null)

export const useTheme = () => React.useContext(ThemeContext)

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = React.useState<Theme>(() => {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      return (localStorage.getItem('klu-theme') as Theme) || 'light'
    }
    return 'light'
  })

  const [systemTheme, setSystemTheme] = React.useState<Exclude<Theme, 'system'>>('light')

  React.useEffect(() => {
    if (typeof window === 'undefined') return

    const media = window.matchMedia('(prefers-color-scheme: dark)')

    const applyTheme = () => {
      if (theme === 'dark' || (theme === 'system' && media?.matches)) {
        setSystemTheme('dark')
        document.documentElement.classList.add('dark')
      } else {
        setSystemTheme('light')
        document.documentElement.classList.remove('dark')
      }
    }

    applyTheme()
    window.addEventListener('DOMContentLoaded', applyTheme)
    media?.addEventListener('change', applyTheme)

    return () => {
      media?.removeEventListener('change', applyTheme)
      window.removeEventListener('DOMContentLoaded', applyTheme)
    }
  }, [theme])

  const handleSetTheme = (newTheme: Theme) => {
    setTheme(newTheme)
    if (newTheme === 'system') {
      localStorage.removeItem('klu-theme')
    } else {
      localStorage.setItem('klu-theme', newTheme)
    }
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme: handleSetTheme,
        systemTheme,
        state: {
          isLight: theme === 'light' || (theme === 'system' && systemTheme === 'light'),
          isDark: theme === 'dark' || (theme === 'system' && systemTheme === 'dark'),
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

interface SidebarContextType {
  sidebarOpen: boolean
  setSidebarOpen: (value: boolean) => void
}

export const SidebarContext = React.createContext<SidebarContextType>({
  sidebarOpen: false,
  setSidebarOpen: () => null,
})

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false)

  return (
    <SidebarContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => React.useContext(SidebarContext)

interface ApplicationContextType {
  workspace: Workspace
  createWorkspaceModalOpen: boolean
  setCreateWorkspaceModalOpen: (value: boolean) => void
  billing: {
    hasScaleLLMAccess: boolean
    hasAccess: boolean
    canStartFreeTrial: boolean
    isFlaggedAsRisky: boolean
  }
}

export const ApplicationContext = React.createContext<ApplicationContextType | null>(null)

export const ApplicationProvider = ({
  workspace,
  children,
}: {
  workspace: Workspace
  children: ReactNode
}) => {
  const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = React.useState(false)
  const { data, isLoading } = useCheckWorkspaceSubscription(workspace.id)

  React.useEffect(() => {
    if (subscriptionNotRequiredPaths.some((path) => window.location.pathname.endsWith(path))) {
      return
    }

    const billingUrl = `/${workspace.slug}/settings/plans`
    if (data !== undefined && !data?.hasAccess) {
      window.location.href = billingUrl
    }
  }, [data, workspace.slug])

  if (isLoading) {
    return <FullLoaderPage />
  }

  if (!data) {
    return null
  }

  return (
    <ApplicationContext.Provider
      value={{
        workspace,
        createWorkspaceModalOpen,
        setCreateWorkspaceModalOpen,
        billing: {
          hasAccess: data.hasAccess,
          hasScaleLLMAccess: data.hasScaleLlmsAccess,
          canStartFreeTrial: data.canStartFreeTrial,
          isFlaggedAsRisky: data.isFlaggedAsRisky,
        },
      }}
    >
      <RoomProvider id={`${workspace.project_guid}:application`} initialPresence={{}}>
        <ErrorBoundary fallback={<>{children}</>}>
          <ClientSideSuspense fallback={<FullLoaderPage />}>{() => children}</ClientSideSuspense>
        </ErrorBoundary>
      </RoomProvider>
    </ApplicationContext.Provider>
  )
}

export const useApplication = () => {
  const context = React.useContext(ApplicationContext)
  if (!context) {
    throw new Error('useApplication must be used within an ApplicationProvider')
  }
  return context
}

export const useWorkspace = () => {
  const { workspace } = useApplication()
  if (!workspace) {
    throw new Error('Workspace not found')
  }
  return workspace
}
