import { CpuChip02 } from '@untitled-ui/icons-react'
import { MySQLIcon } from '../icons'
import Ai21Icon from './ai21.svg'
import AnthropicIcon from './anthropic.svg'
import AwsBedrockIcon from './awsBedrock.svg'
import AzureIcon from './azure.svg'
import CloudflareIcon from './cloudflare.svg'
import CohereIcon from './cohere.svg'
import GcpIcon from './gcp.svg'
import GoogleAIIcon from './googleAiIcon.svg'
import GooseAiIcon from './gooseAi.svg'
import GroqIcon from './groq.svg'
import HuggingFaceIcon from './huggingFace.svg'
import KluIcon from './klu.svg'
import MistralIcon from './mistral.svg'
import NlpCloudIcon from './nlpCloud.svg'
import OpenAiIcon from './openAi.svg'
import PostgresIcon from './postgres.svg'
import PerplexityIcon from './pplx-svg.svg'
import ReplicateIcon from './replicate.svg'
import TogetherAiIcon from './together.svg'

export const getProviderIcon = (providerId: number) => {
  switch (providerId) {
    case 1:
      return OpenAiIcon
    case 2:
      return Ai21Icon
    case 3:
      return AnthropicIcon
    case 5:
      return CohereIcon
    case 6:
      return GooseAiIcon
    case 8:
      return HuggingFaceIcon
    case 10:
      return NlpCloudIcon
    case 12:
      return AzureIcon
    case 13:
      return GcpIcon
    case 14:
      return KluIcon
    case 15:
      return ReplicateIcon
    case 16:
      return AwsBedrockIcon
    case 17:
      return CloudflareIcon
    case 18:
      return PerplexityIcon
    case 19:
      return GoogleAIIcon
    case 20:
      return TogetherAiIcon
    case 21:
      return MistralIcon
    case 22:
      return AzureIcon
    case 23:
      return GroqIcon
    case 24:
      return CpuChip02
    default:
      return undefined
  }
}

export const getDatabaseIcon = (name: string) => {
  switch (name) {
    case 'PostgreSQL':
      return PostgresIcon
    case 'MySQL':
      return MySQLIcon
    default:
      return null
  }
}
