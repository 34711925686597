import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { Context, ContextType, User, Workspace } from '@prisma/client';
import { ArrowNarrowUpRight } from '@untitled-ui/icons-react';
import { escapeRegExp } from 'lodash';
import { type NextPage } from 'next';
import { useCallback, useEffect, useState } from 'react';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, Switch } from '@/client/components';
import { Loader } from '@/common/components';
import { Context404 } from '@/common/components/notFound/404';
import { Pagination } from '@/common/components/pagination/pagination';
import { useCurrentWorkspace, useFetchDocumenByGuid, useFetchDocumentsPaginated } from '@/common/hooks';
import { withContext } from '@/common/lib';
import type { Document } from '@/common/types/document';
import type { SearchContextResponseSchema } from '@/server/engine/context';
import { api } from '@/utils';
import { UpdateDocumentForm } from './[documentId]/edit';
type DetailedContext = Context & {
  type: ContextType;
  createdBy: User;
  // loader: LoaderType
};
export function SearchForm({
  query,
  onChange,
  isVectorSearch,
  setIsVectorSearch
}: {
  query: string;
  onChange: ({
    query,
    isVectorSearch
  }: {
    query: string;
    isVectorSearch: boolean;
  }) => void;
  isVectorSearch: boolean;
  setIsVectorSearch: (isVectorSearch: boolean) => void;
}) {
  const handleToggle = () => {
    setIsVectorSearch(!isVectorSearch);
  };
  const handleQueryChange = (query: string) => {
    onChange({
      query,
      isVectorSearch
    });
  };
  return <div>
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="flex gap-4 items-center">
        <input id="search" name="search" type="text" className="rounded-md w-4/5 border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus-ring-white" placeholder="Search" value={query} onChange={e => handleQueryChange(e.target.value)} />
        <div className="flex gap-2 items-center">
          <Switch id="isVectorSearch" checked={isVectorSearch} onCheckedChange={handleToggle} />
          <label htmlFor="isVectorSearch" className="text-sm text-grey-500 dark:text-zinc-500">
            Semantic search
          </label>
        </div>
      </div>
    </div>;
}
export function DocumentsTable({
  context,
  query,
  initialPage
}: {
  context: DetailedContext;
  query: string;
  initialPage: number;
}) {
  const {
    workspace
  } = useCurrentWorkspace();
  const [page, setPage] = useState(initialPage || 1);
  const [perPage, setPerPage] = useState(50);
  const {
    documents,
    totalDocuments,
    isLoading
  } = useFetchDocumentsPaginated({
    indexId: context.id,
    page,
    perPage,
    query
  });
  const changePage = (page: number) => {
    setPage(page);
  };
  return <div className="">
      {isLoading && <div className="flex items-center justify-center py-12">
          <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
        </div>}
      {documents && totalDocuments != undefined && workspace && !isLoading && <>
          <div className="min-w-full overflow-clip rounded-b-md align-middle border-t-grey-200 dark:border-t-zinc-800 border-t">
            <table className="w-full min-w-full table-fixed divide-y divide-grey-200 dark:divide-zinc-800">
              <thead className="bg-grey-50 dark:bg-zinc-900">
                <tr>
                  <th scope="col" className="
                  mw-1/2 w-full px-6 py-3 text-left text-xs font-medium uppercase
                  tracking-wider text-grey-900 dark:text-white
                ">
                    Contents
                  </th>
                  <th scope="col" className="
                w-3/6 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider
                text-grey-900">
                    Metadata
                  </th>
                  <th className="w-20"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grey-200 align-top dark:divide-zinc-800">
                {documents.length < 1 && <tr>
                    <td colSpan={2} className="px-6 py-4 align-top text-xs text-grey-500">
                      No documents found
                    </td>
                  </tr>}
                {documents.map(document => <DocumentsTableRow key={document.guid} document={document} workspace={workspace} context={context} highlight={query} />)}
              </tbody>
            </table>
            <Pagination onPageChange={changePage} total={totalDocuments || 0} perPage={perPage} setPerPage={setPerPage} currentPage={page} />
          </div>
        </>}
    </div>;
}
function DocumentsTableRow({
  document,
  workspace,
  context,
  highlight
}: {
  document: Document;
  workspace: Workspace;
  context: Context;
  highlight: string;
}) {
  return <tr key={document.id} className="bg-white hover:bg-grey-50 dark:bg-black dark:hover:bg-zinc-800">
      <td className="text-sm text-grey-900 dark:text-zinc-600">
        <div className="
                    h-[200px]
                    overflow-y-scroll whitespace-pre-wrap
                    px-6
                    py-6
                    font-mono
                    text-xs">
          <Highlighted text={document.content} highlight={highlight} />
        </div>
      </td>
      <td className="overflow-scroll px-6 py-4 align-top text-xs text-grey-500 ">
        <div className="flex h-[200px] flex-col gap-2 overflow-y-scroll">
          {document.metadata && Object.entries((document.metadata as Record<string, unknown>)).filter(([key, _]) => key !== 'contextSourceId').map(([key, value]) => <MetadataItem key={`metadata-${key}`} name={key} value={value} />)}
        </div>
      </td>
      <td className="table-cell whitespace-nowrap py-4 pl-6 pr-3 text-right text-sm text-grey-600 dark:text-zinc-600">
        <DocumentsTableDrawer document={document} context={context} workspace={workspace} />
      </td>
    </tr>;
}
const Highlighted = ({
  text = '',
  highlight = ''
}) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);
  return <span>
      {parts.filter(part => part).map((part, i) => regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>)}
    </span>;
};
export function DocumentsTableDrawer({
  document,
  context,
  workspace
}: {
  document: Document;
  context: Context;
  workspace: Workspace;
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  return <>
      <Sheet open={openDrawer} onOpenChange={setOpenDrawer}>
        <SheetTrigger onClick={() => setOpenDrawer(true)}>
          <ArrowNarrowUpRight className="h-5 w-5 text-grey-600 dark:text-zinc-600" />
        </SheetTrigger>
        <SheetContent className="max-w-4xl sm:max-w-5xl">
          <SheetTitle className="p-4">Edit Doc Embedding</SheetTitle>
          <SheetHeader>
            <SheetDescription>
              <UpdateDocumentForm workspace={workspace} document={document} context={context} onDeletedCallback={() => setOpenDrawer(false)} />
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>;
}
export const VectorSearchDocumentsTable = ({
  query,
  context
}: {
  query: string;
  context: DetailedContext;
}) => {
  const searchDataIndex = api.context.search.useMutation();
  const {
    workspace
  } = useCurrentWorkspace();
  const [isLoading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<SearchContextResponseSchema[]>([]);
  const handleDataIndexInput = useCallback(async () => {
    if (!workspace) return;
    await searchDataIndex.mutateAsync({
      workspaceGuid: workspace.project_guid,
      indexGuid: context.guid,
      prompt: query || '',
      number_of_results: 10
    }, {
      onSettled: () => void setLoading(false),
      onError: err => {
        console.error(err);
        setDocuments([]);
      },
      onSuccess: res => {
        setDocuments(res);
      }
    }).catch(console.error);
  }, [context, workspace, query, searchDataIndex]);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await handleDataIndexInput();
    }
    if (query && workspace) {
      fetchData().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  if (!workspace || !context) {
    return <></>;
  }
  if (isLoading) {
    return <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
      </div>;
  }
  return <>
      <div className="flex flex-col">
        {documents && workspace && !isLoading && <>
            <div className="min-w-full overflow-clip rounded-b-md border-t border-t-grey-200">
              <table className="w-full min-w-full table-fixed divide-y divide-grey-200 dark:divide-zinc-800">
                <thead className="bg-grey-50 dark:bg-zinc-900">
                  <tr>
                    <th scope="col" className="
                  mw-1/2 w-full px-6 py-3 text-left text-xs font-medium uppercase
                  tracking-wider text-grey-900 dark:text-white
                ">
                      Contents
                    </th>
                    <th scope="col" className="
                w-3/6 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider
                text-grey-900">
                      Metadata
                    </th>
                    <th className="w-20"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-grey-200 align-top dark:divide-zinc-800">
                  {documents.length < 1 && <tr>
                      <td colSpan={2} className="px-6 py-4 align-top text-xs text-grey-500">
                        No documents found
                      </td>
                    </tr>}
                  {documents.map(document => <VectorDocumentRow key={document.guid} document={document} workspace={workspace} context={context} />)}
                </tbody>
              </table>
            </div>
          </>}
      </div>
    </>;
};
function VectorDocumentRow({
  document,
  workspace,
  context
}: {
  document: SearchContextResponseSchema;
  workspace: Workspace;
  context: DetailedContext;
}) {
  return <tr key={document.guid} className="bg-white hover:bg-grey-50 dark:bg-black hover:bg-zinc-800">
      <td className="text-sm text-grey-900 dark:text-zinc-600">
        <div className="
                    h-[200px]
                    overflow-y-scroll whitespace-pre-wrap
                    px-6
                    py-6
                    font-mono
                    text-xs">
          {document.content}
        </div>
      </td>
      <td className="overflow-scroll px-6 py-4 align-top text-xs text-grey-500 ">
        <div className="flex h-[200px] flex-col gap-2 overflow-y-scroll">
          {document.metadata && Object.entries(document.metadata).map(([key, value]) => <MetadataItem key={`metadata-${key}`} name={key} value={value} />)}
          {document.score && <MetadataItem name="score" value={document.score} />}
        </div>
      </td>
      <td className="table-cell whitespace-nowrap py-4 pl-6 pr-3 text-right text-sm text-grey-600 dark:text-zinc-600">
        <VectorDocumentDrawer guid={document.guid} workspace={workspace} context={context} />
      </td>
    </tr>;
}
export function MetadataItem({
  name,
  value
}: {
  name: string;
  value: string | unknown;
}) {
  return <div className="flex flex-col gap-1">
      <span className="text-xs font-medium text-grey-900 capitalize">{name.replace('_', ' ')}</span>
      <span className="text-xs text-grey-900">
        {typeof value == 'string' ? value : <pre className="whitespace-pre-wrap">{JSON.stringify(value, null, 2)}</pre>}
      </span>
    </div>;
}
const VectorDocumentDrawer = ({
  context,
  workspace,
  guid
}: {
  context: DetailedContext;
  workspace: Workspace;
  guid: string;
}) => {
  const {
    document,
    isLoading
  } = useFetchDocumenByGuid(guid);
  if (!document || isLoading) return null;
  return <DocumentsTableDrawer document={document} context={context} workspace={workspace} />;
};
const Context404Wrapper: NextPage = ({
  dne
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <Context404 />;
  }
  return <></>;
};
export default _withSuperJSONPage(Context404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withContext(), []);