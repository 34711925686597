import type { App, Workspace } from '@prisma/client'
import { useRouter } from 'next/router'
import { toast } from '@/client/components'
import type { DetailedEval } from '@/common/types/eval'
import { api } from '@/utils'

export const useDeleteEval = ({
  workspace,
  evalRecord,
  app,
}: {
  workspace: Workspace
  evalRecord: DetailedEval
  app: App
}) => {
  const router = useRouter()
  const deleteEval = api.eval.delete.useMutation()
  const utils = api.useContext()

  async function handleDelete() {
    try {
      await deleteEval.mutateAsync({ evalId: evalRecord.id })
      await utils.eval.getAll.invalidate({ appId: app.id })
      toast.success({
        title: `${evalRecord.name} deleted`,
        description: 'Evaluation deleted successfully',
      })
      await router.push(`/${workspace.slug}/apps/${app.slug}/evaluate`)
    } catch (e) {
      console.error(e)
    }
  }
  return { handleDelete }
}
