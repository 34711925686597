import { Check, ChevronSelectorVertical, Monitor02, Moon02, Sun } from '@untitled-ui/icons-react'
import React from 'react'
import { Button, Command, Popover } from '@/client/components'
import { cn } from '@/client/utils'
import { themeOptions, useTheme } from '../context'

export const Switcher = ({ className }: { className?: string } = {}) => {
  const [isOpen, setOpen] = React.useState(false)
  const theme = useTheme()

  return theme ? (
    <Popover.Root open={isOpen} onOpenChange={setOpen} modal={false}>
      <Popover.Trigger asChild>
        <Button
          size="sm"
          variant="ghost"
          role="combobox"
          aria-label="Select a theme"
          className={cn(
            'w-4/5 md:w-full justify-between font-normal truncate capitalize dark:text-zinc-400 hover:dark:text-zinc-300',
            className
          )}
        >
          <div className="flex items-center justify-center">
            <div className="flex-shrink-0">
              {theme.theme === 'system' ? (
                <Monitor02 className="h-5 w-5" />
              ) : theme.theme === 'light' ? (
                <Sun className="h-5 w-5" />
              ) : (
                <Moon02 className="h-5 w-5" />
              )}
            </div>
            <span className="ml-4">{theme.theme}</span>
          </div>
          <ChevronSelectorVertical className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </Popover.Trigger>
      <Popover.Content className="w-48 p-0">
        <Command.Root>
          <Command.List>
            <Command.Empty>No workspace found.</Command.Empty>
            <Command.Group>
              {themeOptions.map((t) => (
                <Command.Item
                  key={t}
                  onSelect={() => {
                    theme.setTheme(t)
                  }}
                  className="text-sm capitalize gap-2"
                >
                  {t === 'system' ? (
                    <Monitor02 className="h-4 w-4" />
                  ) : t === 'light' ? (
                    <Sun className="h-4 w-4" />
                  ) : (
                    <Moon02 className="h-4 w-4" />
                  )}
                  {t}
                  <Check
                    className={cn(
                      'ml-auto h-4 w-4',
                      theme.theme === t ? 'opacity-50' : 'opacity-0'
                    )}
                  />
                </Command.Item>
              ))}
            </Command.Group>
          </Command.List>
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  ) : null
}
