// THIS MODAL IS NO LONGER ACCESSIBLE
// POTENTIALLY BRING IT BACK?

import { AlertTriangle } from '@untitled-ui/icons-react'
import { useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import { api } from '@/utils'
import type { DetailedData } from '../../tables/types'

function DeleteDataForm({
  setOpen,
  data,
  callback,
}: {
  data: DetailedData
  setOpen: (val: boolean) => void
  callback?: () => void
}) {
  const [loading, setLoading] = useState(false)
  const utils = api.useContext()
  const deleteData = api.data.delete.useMutation({
    onSuccess: () => {
      // This is a full reload of the cache - regardless of the page you're on
      // This is a paginated request so it's difficult to invalidate the exact cache
      // Maybe there's a way to optimize this by optimistically updating the cache
      void utils.data.getInfiniteAllForApp.invalidate()
      toast.success({
        title: `Log deleted`,
        description: `${data.guid.substring(0, 8)} removed from logs`,
      })
      return
    },
  })

  async function onDelete() {
    setLoading(true)
    await deleteData.mutateAsync(
      { id: data.id },
      {
        onSuccess: () => {
          callback && callback()
          setOpen(false)
        },
        onError: (e) => {
          toast.error({
            title: 'Something went wrong',
            description: e.message,
          })
        },
        onSettled: () => {
          setLoading(false)
        },
      }
    )
  }

  return (
    <>
      <div className="bg-white dark:bg-black px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 dark:bg-red-900 ring-red-300 dark:ring-red-700 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <AlertTriangle className="w-6 h-6 text-red-600 dark:text-red-300" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-black dark:text-white">
              Delete {data?.guid}
            </h3>
            <div className="mt-2">
              <p className="truncate text-sm text-grey-500 dark:text-zinc-500">
                {data.input.slice(0, 200)}
              </p>
            </div>
            <div className="mt-2">
              <p className="text-sm text-grey-500 dark:text-zinc-500">
                Are you sure you want to delete {data?.guid}?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5 sm:flex">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => void onDelete()}
          disabled={loading}
          loading={loading}
        >
          {loading ? 'Deleting...' : 'Delete Data'}
        </Button>
      </div>
    </>
  )
}

export const DeleteDataModal = ({
  open,
  setOpen,
  data,
  callback,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  data: DetailedData | null
  callback?: () => void
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          {data && <DeleteDataForm setOpen={setOpen} data={data} callback={callback} />}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
