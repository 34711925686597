import type { Workspace } from '@prisma/client'
import { Check, ChevronDown } from '@untitled-ui/icons-react'
import { usePresignedUpload } from 'next-s3-upload'
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, ConfirmActionDialog, DropdownMenu, toast } from '@/client/components'
import { FileUpload } from '@/common/components'
import type { DetailedContext } from '@/common/types/context'
import type { SingleContextMetadataCache } from '@/server/service/types'
import { api } from '@/utils'
import { BatchJobStatus } from '../batchJobs'
import { ContextSourceRow } from '../contextSources/ContextSourceRow'
import { CreateContextSourceModal } from '../contextSources/CreateContextSource'

export function ContextSources({
  context,
  workspace,
}: {
  context: DetailedContext
  workspace: Workspace
}) {
  const deleteContextSource = api.contextSource.delete.useMutation()
  const processMany = api.contextSource.processMany.useMutation()
  const utils = api.useContext()
  const { data: contextSources } = api.contextSource.getAll.useQuery({ contextId: context.id })
  const addContextSources = api.contextSource.createMany.useMutation()
  const [selectedSourcesIds, setSelectedSourcesIds] = useState<number[]>([])
  const [filter, setFilter] = useState<string | null>('')
  const { batchJobIds } = context.metadata as SingleContextMetadataCache

  const [isLoading, setLoading] = useState(false)
  const [createContextModalOpen, setCreateContextModalOpen] = useState(false)

  const [fileUploadKey, setFileUploadKey] = useState('')

  const { uploadToS3 } = usePresignedUpload()

  useEffect(() => {
    setSelectedSourcesIds([])
  }, [filter])

  const uploadFilesToS3 = async (files: File[]) => {
    const uploadedFileUrls: string[] = []
    for (const file of files) {
      const { url } = await uploadToS3(file, {
        endpoint: {
          request: {
            body: {
              workspaceGuid: workspace.project_guid,
            },
          },
        },
      })
      uploadedFileUrls.push(url)
    }
    return uploadedFileUrls
  }

  const addFiles = async (files: File[]) => {
    setLoading(true)
    const uploadedFileUrls = await uploadFilesToS3(files)

    await addContextSources.mutateAsync({
      urls: uploadedFileUrls,
      contextId: context.id,
      workspaceId: workspace.id,
    })

    await utils.context.get.invalidate({ id: context.id })
    await utils.contextSource.getAll.invalidate({ contextId: context.id })

    setFileUploadKey(Date.now().toString()) //gross

    setLoading(false)
  }

  const onChangeFiles = async (files: File[]) => {
    await addFiles(files)

    toast.info({
      title: 'File added',
      description: "Successfully added the file to the context's sources",
    })
  }

  const toggleSelected = (id: number) => {
    if (selectedSourcesIds.includes(id)) {
      setSelectedSourcesIds(selectedSourcesIds.filter((sourceId) => sourceId !== id))
    } else {
      setSelectedSourcesIds([...selectedSourcesIds, id])
    }
  }

  const processSelected = async function () {
    await processMany.mutateAsync({ ids: selectedSourcesIds, workspaceId: workspace.id })
    await Promise.all(
      selectedSourcesIds.map(async (id) => {
        await utils.contextSource.getAll.invalidate({ contextId: context.id })
        await utils.context.get.invalidate({ id: context.id })
        await utils.contextSource.getStatus.invalidate({ id })
      })
    )
    setSelectedSourcesIds([])
    setFilter(null)
  }

  const deleteSelected = async () => {
    await Promise.all(
      selectedSourcesIds.map(async (id) => {
        await deleteContextSource.mutateAsync({ id })
        await utils.contextSource.getAll.invalidate({ contextId: context.id })
        await utils.context.get.invalidate({ id: context.id })
        await utils.contextSource.getStatus.invalidate({ id })
      })
    ).then(() => {
      setSelectedSourcesIds([])
      toast.info({
        title: 'Files removed',
        description: 'Your files have been removed',
      })
    })
  }

  const toggleAll = () => {
    if (contextSources) {
      if (selectedSourcesIds.length == contextSources.length) {
        setSelectedSourcesIds([])
      } else {
        setSelectedSourcesIds(contextSources.map((source) => source.id))
      }
    }
  }

  const contextSourcesList =
    contextSources && contextSources.length > 0
      ? contextSources
          .filter((source) => {
            if (!filter) return true
            return source.indexingStatus?.toLowerCase() === filter.toLowerCase()
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      : []

  const statuses = [
    ...new Set((contextSources || []).map((source) => source.indexingStatus || 'UNPROCESSED')),
  ]

  return (
    <React.Fragment>
      <div className="mt-3 text-center sm:mt-0 sm:text-left">
        <div className="flex w-full p-6">
          <div className="grid flex-grow place-items-center ">
            <FileUpload
              key={fileUploadKey}
              label={isLoading ? 'Saving...' : 'Add Files'}
              onChangeFiles={(files) => {
                void onChangeFiles(files)
              }}
              disabled={isLoading}
              allowDrop={true}
            />
          </div>
          <div className="flex items-center p-6 text-grey-300">or</div>
          <div className="grid flex-grow  place-items-center">
            <CreateContextSourceModal
              workspace={workspace}
              context={context}
              open={createContextModalOpen}
              setOpen={setCreateContextModalOpen}
            />
            <Button
              className="w-full h-full"
              variant="outline"
              onClick={() => setCreateContextModalOpen(true)}
            >
              Add Source
            </Button>
          </div>
        </div>

        {batchJobIds && (
          <div className="p-6">
            <BatchJobStatus batchJobIds={batchJobIds} />
          </div>
        )}

        {contextSourcesList?.length > 0 && (
          <div className="flow-root">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden rounded-b-lg border-t border-t-grey-300 dark:border-t-zinc-800">
                <table className="group/table min-w-full  divide-y divide-grey-300 dark:divide-zinc-800">
                  <thead className="bg-grey-50 dark:bg-zinc-900">
                    <tr>
                      <th className="pl-3">
                        {contextSourcesList?.length > 0 && (
                          <Checkbox
                            className={`${
                              selectedSourcesIds.length == contextSourcesList.length
                                ? ''
                                : 'opacity-30'
                            } group-hover/table:opacity-100`}
                            checked={selectedSourcesIds.length == contextSourcesList.length}
                            onCheckedChange={() => {
                              toggleAll()
                            }}
                          />
                        )}
                      </th>
                      <th
                        scope="col"
                        className="w-8/12 py-3.5 pl-6 pr-3 text-left text-xs font-light uppercase text-grey-900 dark:text-white"
                      >
                        Source
                      </th>

                      <th
                        scope="col"
                        className="w-4/12 px-3 py-3.5 text-right text-xs font-light uppercase text-grey-900"
                      >
                        <ConfirmActionDialog
                          variant={'info'}
                          header={'Remove context sources'}
                          message={`Are you sure you want to remove ${selectedSourcesIds.length} sources?`}
                          onConfirm={deleteSelected}
                        >
                          {({ setOpen }) => (
                            <BulkActions
                              {...{
                                processSelected,
                                deleteSelected: () => setOpen(true),
                                selectedSourcesIds,
                              }}
                            />
                          )}
                        </ConfirmActionDialog>
                        <Filters setFilter={setFilter} filter={filter} statuses={statuses} />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-grey-200 dark:divide-zinc-800">
                    {contextSourcesList?.map((contextSource) => (
                      <ContextSourceRow
                        selected={selectedSourcesIds.includes(contextSource.id)}
                        toggleSelected={toggleSelected}
                        contextSource={contextSource}
                        key={contextSource.id}
                        workspace={workspace}
                        context={context}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const BulkActions = ({
  processSelected,
  deleteSelected,
  selectedSourcesIds,
}: {
  processSelected: () => Promise<void>
  selectedSourcesIds: number[]
  deleteSelected: () => Promise<void> | void
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild disabled={selectedSourcesIds.length === 0}>
        <Button variant="ghost" endIcon={ChevronDown}>
          Bulk Actions
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>
        <DropdownMenu.Item
          onClick={() => {
            void processSelected()
          }}
        >
          Reindex Selected
        </DropdownMenu.Item>

        <DropdownMenu.Item
          onClick={() => {
            void deleteSelected()
          }}
        >
          Delete Selected
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

const Filters = ({
  statuses,
  filter,
  setFilter,
}: {
  statuses: string[] | null
  filter?: string | null
  setFilter: (filter: string | null) => void
}) => {
  if (!statuses) return null

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button variant="ghost" endIcon={ChevronDown}>
          <span className="capitalize">{filter ? filter : 'Filter'}</span>
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>
        <DropdownMenu.Item
          onClick={() => {
            void setFilter(null)
          }}
        >
          All
        </DropdownMenu.Item>
        {statuses.map((status) => (
          <DropdownMenu.Item
            key={status}
            onClick={() => {
              void setFilter(status)
            }}
          >
            {filter === status && <Check className="h-4 w-4 mr-2 text-primary-500"></Check>}
            <span className="capitalize">{status.toLocaleLowerCase()}</span>
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
