import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { Select, Tabs } from '@/client/components'
import { useCurrentWorkspace } from '@/common/hooks'
import { OpenMobileSidebarButton, Sidebar } from '../Sidebar'

function SettingsNav({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const { workspace } = useCurrentWorkspace()

  const [selected, setSelected] = React.useState('1')

  const tabs = [
    { id: '1', name: 'Workspace', href: '' },
    { id: '2', name: 'Members', href: '/members' },
    { id: '3', name: 'API Keys', href: '/developers' },
    { id: '4', name: 'Plans', href: '/plans' },
    { id: '5', name: 'Billing', href: '/billing' },
    { id: '6', name: 'Logs', href: '/logs' },
  ]

  React.useEffect(() => {
    if (workspace && router.isReady) {
      const tab = tabs.find((tab) => `/${workspace.slug}/settings${tab.href}` === router.asPath)
      if (tab) setSelected(tab.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace])

  return (
    <main>
      <div>
        <div className="pt-6 sm:hidden w-full">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {workspace && (
            <Select.Field
              id="tabs"
              onChange={(e) => {
                const tab = tabs.find((tab) => tab.id === e)
                if (!tab) return
                void router.push(`/${workspace.slug}/settings${tab.href}`)
              }}
              value={selected}
              options={tabs.map((x) => ({
                label: x.name,
                value: x.id,
              }))}
            />
          )}
        </div>
        {workspace ? (
          <div className="hidden sm:block">
            <Tabs.Root
              className="grow flex border-b  border-grey-300 dark:border-zinc-800"
              value={selected}
              onValueChange={(id) => {
                setSelected(id)
                const tab = tabs.find((tab) => tab.id === id)
                if (!tab) return
                void router.push(`/${workspace.slug}/settings${tab.href}`)
              }}
            >
              <Tabs.List className="gap-5">
                {tabs.map((x) => (
                  <Tabs.Trigger key={x.id} className="text-sm flex items-center" value={x.id}>
                    {x.name}
                  </Tabs.Trigger>
                ))}
              </Tabs.List>
            </Tabs.Root>
          </div>
        ) : null}
      </div>
      <div className="py-6">{children}</div>
    </main>
  )
}

export function WorkspaceSettingsWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Sidebar>
      <Head>
        <title>Settings</title>
        <meta name="description" content="Klu Settings" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {/* Background color split screen for large screens */}
      <div className="">
        {/* Left sidebar & main wrapper */}
        <div className="sticky z-10 top-0 flex h-16 flex-shrink-0 border-b border-grey-200 dark:border-zinc-800 bg-white dark:bg-black bg-opacity-50 backdrop-blur">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-4 sm:px-6">
            <OpenMobileSidebarButton />
            <div className="flex flex-1 items-center">
              <h1 className="text-xl font-semibold text-grey-800 dark:text-white">Settings</h1>
              <p className="hidden sm:flex ml-4 text-sm text-grey-600 dark:text-zinc-600">
                Configure your workspace, invite team members, and manage API access
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-8xl mx-auto px-6">
          <SettingsNav>{children}</SettingsNav>
        </div>
      </div>
    </Sidebar>
  )
}
