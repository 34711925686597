interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  title: string
  description?: string | React.ReactNode
}

const Label: React.FC<LabelProps> = (props) => {
  const { title, description, ...rest } = props
  return (
    <label {...rest}>
      <span className="block text-sm font-medium text-grey-700 dark:text-white">{title}</span>
      {description ? (
        <span className="text-xs text-grey-500 dark:text-zinc-600">{description}</span>
      ) : null}
    </label>
  )
}

export { Label }
