import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { cn } from '@/client/utils'

const alertVariants = cva(
  'relative w-full gap-3 items-start flex rounded-lg border p-4 [&>svg]:text-foreground duration-200 transition ease-in-out',
  {
    variants: {
      variant: {
        default:
          'bg-zinc-50 text-zinc-600 hover:bg-zinc-100 border-red-300 dark:bg-zinc-900 dark:text-zinc-200 dark:border-zinc-700',
        destructive:
          'bg-red-50 text-red-600 hover:bg-red-100 border-red-300 dark:bg-red-900 dark:text-red-200 dark:border-red-700',
        warning:
          'bg-orange-50 text-orange-600 hover:bg-orange-100 border-orange-300 dark:bg-orange-900 dark:text-orange-200 dark:border-orange-700',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

const Root = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
))

Root.displayName = 'Alert'

const Title = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h5
      ref={ref}
      className={cn('mb-1 font-medium leading-none tracking-tight', className)}
      {...props}
    />
  )
)

Title.displayName = 'AlertTitle'

const Description = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('text-sm [&_p]:leading-relaxed', className)} {...props} />
))
Description.displayName = 'AlertDescription'

export const Alert = {
  Root,
  Title,
  Description,
}
