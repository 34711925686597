import { Dialog } from '@/client/components'
import { GenerateMoreDatasetItemsForm } from './Form'

export const GenerateMoreDatasetItemsModal = ({
  isOpen,
  onIsOpenChange,
}: {
  isOpen: boolean
  onIsOpenChange: (val: boolean) => void
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          <div className="flex flex-col space-y-6">
            <div className="px-6 pt-6 flex items-center justify-between">
              <div className="flex flex-col">
                <Dialog.Title asChild>
                  <h3 className="title3 grow">Populate</h3>
                </Dialog.Title>
                <p className="text-grey-800 dark:text-zinc-500 mt-2 text-sm">
                  Create more dataset items based on the selected items
                </p>
              </div>
            </div>
            <GenerateMoreDatasetItemsForm onIsOpenChange={onIsOpenChange} isOpen={isOpen} />
          </div>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
