import { Check, PlusCircle } from '@untitled-ui/icons-react'
import { useCallback, useState } from 'react'
import { Badge, Button, Command, Popover, Separator } from '..'
import { cn } from '../../utils'
import type { ComboboxMultipleOption } from '../Combobox/Multiple/types'

interface MultiSelectProps {
  title?: string
  className?: string
  options: ComboboxMultipleOption[]
  selectedValues: ComboboxMultipleOption['value'][]
  onChange: (newSelectedItems: ComboboxMultipleOption['value'][]) => void
  onSelectCreate?: () => void
  labelForCreate?: string
}

export function MultiSelect({
  title,
  className,
  options,
  selectedValues,
  onChange,
  onSelectCreate,
  labelForCreate,
}: MultiSelectProps) {
  const [sortedOptions, setSortedOptions] = useState<ComboboxMultipleOption[]>(options)
  const recomputeSortedOptions = useCallback(() => {
    setSortedOptions(
      options.sort((option) => {
        const isSelected = selectedValues.some((selectedValue) => selectedValue === option.value)
        return isSelected ? -1 : 1
      })
    )
  }, [options, selectedValues])

  return (
    <Popover.Root
      onOpenChange={(value) => {
        if (value) {
          recomputeSortedOptions()
        }
      }}
    >
      <Popover.Trigger asChild>
        <Button variant="outline" size="sm" startIcon={PlusCircle} className={className}>
          <div className={'flex flex-row gap-1 items-center body2'}>
            {title}
            {selectedValues?.length > 0 && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />
                <Badge className="lg:hidden">{selectedValues.length}</Badge>
                <div className="hidden space-x-1 lg:flex">
                  {selectedValues.length > 2 ? (
                    <Badge>{selectedValues.length} selected</Badge>
                  ) : (
                    sortedOptions
                      .filter((option) =>
                        selectedValues.some((selectedValue) => selectedValue === option.value)
                      )
                      .map((option) => (
                        <Badge
                          key={option.value}
                          className="inline-block px-1 font-normal truncate max-w-[150px]"
                        >
                          {option.label}
                        </Badge>
                      ))
                  )}
                </div>
              </>
            )}
          </div>
        </Button>
      </Popover.Trigger>
      <Popover.Content className="w-[300px] p-0" align="start">
        <Command.Root>
          <Command.Input placeholder={title} />
          <Separator orientation={'horizontal'} />
          <Command.List>
            <Command.Empty>No results found.</Command.Empty>
            <Command.Group>
              {onSelectCreate && (
                <Command.Item
                  key="create"
                  onSelect={() => {
                    onSelectCreate()
                  }}
                >
                  <PlusCircle className={'mr-2 w-4 h-4'} />

                  <span className={'font-normal'}>{labelForCreate || `New ${title!}`}</span>
                </Command.Item>
              )}

              {sortedOptions.map((option) => {
                const isSelected = selectedValues.some(
                  (selectedValue) => selectedValue === option.value
                )
                return (
                  <Command.Item
                    key={option.value}
                    onSelect={() => {
                      if (isSelected) {
                        onChange(selectedValues.filter((sv) => sv !== option.value))
                      } else {
                        onChange(selectedValues.concat(option.value))
                      }
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 shrink-0 items-center justify-center rounded-sm border border-grey-700',
                        isSelected
                          ? 'bg-grey-900 border-grey-400 text-white'
                          : 'opacity-50 [&_svg]:invisible'
                      )}
                    >
                      <Check />
                    </div>
                    {option.icon && <option.icon />}
                    <span className={'font-normal'}>{option.htmlLabel || option.label}</span>
                    <span className="hidden">{option.value}</span>
                    {/* to distinguish options with same name */}
                  </Command.Item>
                )
              })}
            </Command.Group>
          </Command.List>
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  )
}
