import { Button, Input } from '@/client/components'
import { useCreateEnvironmentForm } from './form'
import type { FormProps } from './types'

export const Form = ({ workspace, onIsOpenChange }: FormProps) => {
  const { formik, saveDisabled } = useCreateEnvironmentForm({ workspace, onIsOpenChange })
  const { values, errors, handleChange, handleSubmit } = formik

  return (
    <div className="flex flex-col">
      <div className="px-6 pb-4">
        <Input
          id="name"
          name="name"
          label="Environment Name"
          message={errors.name}
          hasError={!!errors.name}
          value={values.name}
          onChange={handleChange}
        />
      </div>

      <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          disabled={saveDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
