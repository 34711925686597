import { Tool01, Variable } from '@untitled-ui/icons-react'
import type { ChatCompletionTool } from 'openai/resources'
import { ToolCallsRenderer } from '@/client/containers/views/Studio/components/Tabs/Chat/components/Main/components/Messages/components/ChatMessage'
import type { Message } from '@/common/types/messages'
import type { DatasetItemInput } from '@/server/service/dataset'
import { InputFilesRenderer } from '../../../tables/v2/drawer'

type Messages = Message[]
type JsonInput = Record<string, unknown> | Messages | DatasetItemInput
type Input = string | JsonInput | undefined

export const InputCell = ({
  value: input,
  className = 'max-h-[200px]',
}: {
  value: Input
  className?: string
}) => {
  let maybeJson: Input = input
  let messages: Messages | undefined
  let promptVariables: Record<string, unknown> = {}
  let tools: ChatCompletionTool[] | undefined
  let stringInput: string | undefined

  if (typeof input === 'string') {
    try {
      maybeJson = JSON.parse(input) as JsonInput
      if (typeof maybeJson == 'string') {
        maybeJson = undefined
        stringInput = input
      }
    } catch (error) {
      stringInput = input
    }
  }

  if (Array.isArray(maybeJson)) {
    messages = maybeJson
  } else if (maybeJson && typeof maybeJson === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;({ messages, tools, ...promptVariables } = maybeJson as {
      tools: ChatCompletionTool[]
      messages: Messages
    })
  }

  return (
    <div
      className={`whitespace-pre-wrap py-3 overflow-y-auto overflow-x-hidden leading-normal ${className}`}
    >
      {stringInput ? (
        <StringInputWithImagePreviews value={stringInput} />
      ) : (
        <div className="flex flex-col gap-2">
          {messages && <MessagesInput messages={messages} />}

          {Object.entries(promptVariables).map(([key, value]) => (
            <div key={key} className="flex flex-col">
              <div className="text-sm capitalize leading-6 text-grey-600 dark:text-zinc-600 font-inter flex">
                <Variable className="w-5 h-5 pt-1" />
                <span>{key}</span>
              </div>
              <span className="text-sm text-grey-900 dark:text-zinc-400 select-text font-inter">
                {key == 'files' ? (
                  <InputFilesRenderer value={value as string[]} />
                ) : (
                  <>
                    {typeof value === 'string' ? (
                      <StringInputWithImagePreviews value={value} />
                    ) : (
                      JSON.stringify(value)
                    )}
                  </>
                )}
              </span>
            </div>
          ))}

          {tools && <ChatCompletionTools tools={tools} />}
        </div>
      )}
    </div>
  )
}

export const ChatCompletionTools = ({ tools }: { tools: ChatCompletionTool[] }) => {
  return (
    <div className="flex flex-col gap-2">
      {tools.map((tool, i) => (
        <div
          key={i}
          className="grid grid-cols-1 border divide-y dark:divide-zinc-800 rounded-md border-grey-300 dark:border-zinc-800"
        >
          <div className="p-2 text-xs text-grey-600 dark:text-zinc-600 flex gap-1">
            <Tool01 className="inline-block w-4 h-4 mr-2" />
            <div className="flex flex-col gap-1">
              {tool.function.name}
              <div className="italic text-grey-400 dark:text-zinc-400">
                {tool.function.description}
              </div>
            </div>
          </div>
          <div className="p-2 text-grey-600 dark:text-zinc-600 space-y-2">
            {Object.entries(tool.function.parameters?.properties || {}).map(([key, value]) => (
              <div key={key} className="grid grid-cols-4">
                <span className="col-span-1 break-all">{key}:</span>
                <span className="col-span-3 font-mono text-xs p-2">
                  {JSON.stringify(value, null, 2)}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export const StringInputWithImagePreviews = ({ value }: { value: string }) => {
  const regex = /(https:\/\/[\w\-\.\/]*amazonaws\.com[^\s]*)/gm
  const parts = value
    .split(regex)
    ?.map((part) =>
      part.includes('amazonaws.com') ? (
        <InputFilesRenderer key={part} value={[part]} />
      ) : (
        <>{part}</>
      )
    )

  return <>{parts ? parts : value}</>
}

const MessagesInput = ({ messages }: { messages: Message[] }) => {
  return (
    <div className="flex flex-col gap-2">
      {messages.map((message, i) => (
        <div key={`message-${i}`} className="flex flex-row gap-1">
          <div className="capitalize text-sm w-[70px] text-grey-500 dark:text-zinc-500">
            {message.role}
          </div>
          <div className="font-regular text-sm text-grey-800 dark:text-zinc-600 w-full">
            {Array.isArray(message.content) ? (
              message.content.map((item, idx) => (
                <div key={idx}>
                  {item.type === 'text' && (
                    <pre className="text-sm whitespace-pre-wrap leading-6 text-grey-900 dark:text-zinc-400 select-text font-inter">
                      {item.text}
                    </pre>
                  )}
                  {item.type === 'image_url' && item.image_url && (
                    <InputFilesRenderer value={[item.image_url.url || '']} />
                  )}
                </div>
              ))
            ) : (
              <pre className="text-sm whitespace-pre-wrap font-inter text-grey-900 dark:text-zinc-400">
                {message.content}
              </pre>
            )}
            {message.role == 'assistant' && message.tool_calls && (
              <ToolCallsRenderer toolCalls={message.tool_calls} />
            )}
            {message.role == 'tool' && (
              <span className="text-xs text-grey-400 dark:text-zinc-400">
                Tool Call ID: {message.tool_call_id}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
