import type { Workspace } from '@prisma/client'
import { XClose } from '@untitled-ui/icons-react'
import { ErrorMessage, Field, Form, Formik, type FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import {
  Button,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  toast,
} from '@/client/components'
import { FieldError, FileUpload } from '@/common/components/forms'
import { TIER_CONTEXTS_FEATURE_ID } from '@/common/config/tierConstants'
import { useCheckWorkspaceFeatureAccess, useFetchContext } from '@/common/hooks'
import type { DetailedContext } from '@/common/types/context'
import { ContextOverview } from '@/pages/[workspaceSlug]/context/[contextSlug]'
import { api, classNames } from '@/utils'
import { ContextSources } from '../../contexts/ContextSources'
import { FeatureGate } from './FeatureGate'

const contextSchema = z.object({
  name: z.string(),
  description: z.string().optional().default(''),
  typeId: z.number(),
})

type ContextFormSchema = z.infer<typeof contextSchema>

export function ContextForm({
  workspace,
  setOpen,
  setNewContext,
}: {
  workspace: Workspace
  setOpen: (val: boolean) => void
  setNewContext?: (context: DetailedContext | null) => void
}) {
  const router = useRouter()
  const createIndex = api.context.create.useMutation()
  const [isLoading, setLoading] = useState(false)
  const utils = api.useContext()

  const initialValues: ContextFormSchema = {
    name: '',
    description: '',
    typeId: 1, // Simple
  }

  const formikConfig = {
    initialValues,
    validationSchema: toFormikValidationSchema(contextSchema),
    onSubmit: async (
      values: ContextFormSchema,
      { resetForm }: FormikHelpers<ContextFormSchema>
    ) => {
      setLoading(true)

      await createIndex.mutateAsync(
        { ...values },
        {
          onSettled: () => {
            setLoading(false)
            resetForm()
          },
          onSuccess: (res) => {
            toast.success({
              title: 'Context created',
              description: 'Next, add sources to the library',
            })
            if (setNewContext) {
              setNewContext(res as DetailedContext)
            } else {
              router.push(`/${workspace.slug}/context/${res.slug}?tab=sources`).catch(console.error)
            }
          },
          onError: (err) => {
            toast.error({
              title: 'Something went wrong',
              description: err.message,
            })
          },
        }
      )
      await utils.context.getAll.invalidate()
    },
  }

  return (
    <Formik
      initialValues={formikConfig.initialValues}
      onSubmit={formikConfig.onSubmit}
      validationSchema={formikConfig.validationSchema}
      validateOnBlur={false}
    >
      {(formik) => (
        <Form>
          <div className="rounded-md bg-white dark:bg-black px-6 pt-0">
            <div>
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <div className="mt-6">
                  <p className="text-sm text-grey-700 dark:text-zinc-400">Context Name</p>
                  <div className="mt-2">
                    <div className="flex flex-row rounded-md">
                      <input
                        name="name"
                        placeholder="Jeff Bezos letters to Elon Musk"
                        autoComplete="off"
                        type="text"
                        id="key"
                        className="block font-inter w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus:ring-white"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                    </div>
                    <FieldError fieldName="name" formik={formik} />
                  </div>
                </div>
                <div className="mt-6">
                  <p className="text-sm text-grey-700 dark:text-zinc-400">Description (Optional)</p>
                  <div className="mt-2">
                    <div className="flex flex-row rounded-md">
                      <input
                        name="description"
                        autoComplete="off"
                        placeholder="Use these when understanding communication between Amazon (Jeff) and Tesla (Elon)"
                        type="text"
                        id="key"
                        className="block w-full font-inter min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus:ring-white"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                      />
                    </div>
                    <FieldError fieldName="description" formik={formik} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 bg-grey-50 px-4 py-5 dark:bg-black sm:flex sm:px-6">
            <Button
              variant="outline"
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-grey-300 bg-white px-4 py-2 text-base font-medium shadow-sm hover:border-grey-400 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading} loading={isLoading}>
              {isLoading ? 'Saving...' : 'Create Context'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export function MetadataItem({
  item,
  index,
  remove,
}: {
  item: {
    name: string
    value?: string
    files?: File[]
    type?: string
    required?: boolean
  }
  index: number
  remove: (index: number) => void
}) {
  const onChangeFiles = (files: File[]) => {
    item.files = files
  }
  return (
    <div className="mt-3 flex flex-row space-x-3">
      {item.type != 'files' && (
        <div className="w-1/2 align-top">
          <label
            htmlFor={`metadata.${index}.name`}
            className="text-sm text-grey-500 dark:text-zinc-500"
          >
            Key
          </label>
          {item.required ? (
            <input
              name={`metadata.${index}.name`}
              type="text"
              placeholder="API_KEY"
              className="block w-full min-w-0 flex-1 cursor-not-allowed rounded-md border-grey-300 bg-grey-100 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
              value={item.name}
              disabled
            />
          ) : (
            <Field
              name={`metadata.${index}.name`}
              type="text"
              placeholder="API_KEY"
              className="block w-full min-w-0 flex-1 rounded-md bg:white border-grey-300 focus:border-grey-500 focus:ring-grey-500 dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus-ring-white dark:focus:border-white sm:text-sm"
            />
          )}

          <ErrorMessage
            name={`metadata.${index}.name`}
            component="div"
            className="mt-2 text-xs text-red-500"
          />
        </div>
      )}

      <div className="flex w-1/2 flex-row space-x-2 align-top">
        <div>
          <label
            htmlFor={`metadata.${index}.value`}
            className={classNames('text-sm text-grey-500', item.type == 'files' ? 'hidden' : '')}
          >
            Value
          </label>
          {item.type === 'files' ? (
            <>
              <FileUpload onChangeFiles={onChangeFiles} />
            </>
          ) : (
            <Field
              name={`metadata.${index}.value`}
              placeholder="1234"
              type="text"
              className="block w-full min-w-0 flex-1 rounded-md bg:white border-grey-300 focus:border-grey-500 focus:ring-grey-500 dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus-ring-white dark:focus:border-white sm:text-sm"
            />
          )}

          <ErrorMessage
            name={`metadata.${index}.value`}
            component="div"
            className="mt-2 text-xs text-red-500"
          />
        </div>

        <div className="mt-6">
          {!item.required && (
            <button
              type="button"
              className="inline-flex items-center rounded-full bg-grey-100 border hover:bg-red-700 hover:text-white hover:border-white px-2 py-2"
              onClick={() => remove(index)}
            >
              <XClose className="h-5 w-5 " aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export const CreateContextDrawer = ({
  workspace,
  drawerIsOpen,
  setDrawerIsOpen,
  newContext,
  setNewContext,
}: {
  workspace: Workspace
  drawerIsOpen: boolean
  setDrawerIsOpen: (isOpen: boolean) => void
  newContext: DetailedContext | null
  setNewContext: (context: DetailedContext | null) => void
}) => {
  // const [context, setContext] = useState<DetailedContext | null>()
  const { hasFeatureAccess, isLoading: isLoadingFeatureAccess } = useCheckWorkspaceFeatureAccess({
    workspaceId: workspace.id,
    featureId: TIER_CONTEXTS_FEATURE_ID,
  })

  useEffect(() => {
    setNewContext(null)
  }, [drawerIsOpen, setNewContext])

  if (!hasFeatureAccess) {
    return (
      <FeatureGate open={drawerIsOpen} setOpen={setDrawerIsOpen} loading={isLoadingFeatureAccess} />
    )
  }

  return (
    <>
      <Sheet open={drawerIsOpen} onOpenChange={setDrawerIsOpen}>
        <SheetContent className="max-w-4xl sm:max-w-5xl">
          <SheetTitle className="p-4">Create Context</SheetTitle>
          <SheetHeader>
            <SheetDescription>
              {workspace && newContext && (
                <div className="flex flex-col gap-10">
                  <ContextOverview context={newContext} workspace={workspace} />
                  <ContextSources context={newContext} workspace={workspace} />
                </div>
              )}
              {workspace && !newContext && (
                <ContextForm
                  setNewContext={setNewContext}
                  workspace={workspace}
                  setOpen={setDrawerIsOpen}
                />
              )}
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  )
}

export const EditContextDrawer = ({
  workspace,
  drawerIsOpen,
  setDrawerIsOpen,
  contextId,
}: {
  workspace: Workspace
  drawerIsOpen: boolean
  setDrawerIsOpen: (isOpen: boolean) => void
  contextId: string
}) => {
  const { context: context } = useFetchContext(parseInt(contextId))

  if (!context) {
    return null
  }

  return (
    <>
      <Sheet open={drawerIsOpen} onOpenChange={setDrawerIsOpen}>
        <SheetContent className="max-w-4xl sm:max-w-5xl">
          <SheetTitle className="p-4">Edit Context</SheetTitle>
          <SheetHeader>
            <SheetDescription>
              {workspace && context && (
                <div className="flex flex-col gap-10">
                  <ContextOverview context={context} workspace={workspace} />
                  <ContextSources context={context} workspace={workspace} />
                </div>
              )}
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  )
}
