import {
  ArrowDown,
  ArrowDownRight,
  ArrowRight,
  ArrowUp,
  ArrowUpRight,
} from '@untitled-ui/icons-react'
import type { SVGProps } from 'react'
import React from 'react'
import { cn } from '@/client/utils'
import type { BadgeProps, BadgeVariants, DeltaBadgeProps, NormalBadgeProps } from './types'
import { badgeVariants } from './variants'

const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  ({ className, variant, isLoading, ...props }) => {
    if (variant === 'delta') {
      const { delta, corner, isReverse = false, className } = props as DeltaBadgeProps

      const getVariant = (
        type: typeof delta,
        reverse: typeof isReverse
      ): BadgeVariants['color'] => {
        switch (type) {
          case 'increase':
            return reverse ? 'red' : 'green'
          case 'moderateIncrease':
            return reverse ? 'red' : 'green'
          case 'unchanged':
            return 'orange'
          case 'moderateDecrease':
            return reverse ? 'green' : 'red'
          case 'decrease':
            return reverse ? 'green' : 'red'
          default:
            return 'default'
        }
      }

      const getIcon = (
        type: typeof delta,
        reverse: typeof isReverse,
        className?: string
      ): React.JSX.Element => {
        const props = {
          className: cn('w-4 h-4', className),
        } satisfies SVGProps<SVGSVGElement>

        switch (type) {
          case 'increase':
            return reverse ? <ArrowDown {...props} /> : <ArrowUp {...props} />
          case 'moderateIncrease':
            return reverse ? <ArrowDownRight {...props} /> : <ArrowUpRight {...props} />
          case 'unchanged':
            return <ArrowRight {...props} />
          case 'moderateDecrease':
            return reverse ? <ArrowUpRight {...props} /> : <ArrowDownRight {...props} />
          case 'decrease':
            return reverse ? <ArrowUp {...props} /> : <ArrowDown {...props} />
        }
      }

      return (
        <span
          {...props}
          className={cn(
            badgeVariants({
              color: isLoading ? 'default' : getVariant(delta, isReverse),
              corner,
              variant: 'delta',
            }),
            className?.root
          )}
        >
          {isLoading ? null : getIcon(delta, isReverse, className?.icon)}
          {props.children}
        </span>
      )
    }
    const { color, corner } = props as NormalBadgeProps
    return (
      <span
        {...props}
        className={cn(badgeVariants({ color: isLoading ? 'default' : color, corner }), className)}
      >
        {props.children}
      </span>
    )
  }
)

Badge.displayName = 'Badge'

export { Badge }
