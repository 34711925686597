import { useEventListener } from '@liveblocks/react/suspense'
import type { BatchJob } from '@prisma/client'
import { CheckCircle, XClose } from '@untitled-ui/icons-react'
import { useEffect, useRef, useState } from 'react'
import { DeleteDataIcon } from '@/client/assets/icons/icons'
import { Button, Progress } from '@/client/components'
import { InfoTooltip } from '@/client/components/InfoTooltip/InfoTooltip'
import { useFetchBatchJobs } from '@/common/hooks'
import type { BatchJobMetadata } from '@/server/service/jobs'
import { api, humanize } from '@/utils'

export const BatchJobStatus = ({ batchJobIds }: { batchJobIds?: number[] }) => {
  const utils = api.useContext()
  const [progress, setProgress] = useState<{ [key: string]: number }>({})

  const timer = useRef<{ [key: string]: NodeJS.Timeout | undefined }>({})

  const { batchJobs } = useFetchBatchJobs(batchJobIds || [])

  useEventListener(({ event }) => {
    const { progress, name, batchJobId } = event as {
      progress: number
      name: string
      batchJobId: number
    }
    if (batchJobIds?.includes(batchJobId) && name == 'batchJobProgress') {
      setProgress((prev) => ({ ...prev, [batchJobId.toString()]: progress }))
    }
  })

  useEffect(() => {
    Object.entries(progress).forEach(([key, value]) => {
      if (value >= 100 && !Object.keys(timer.current).includes(key)) {
        void utils.batchJob.getByIds.invalidate({ ids: batchJobIds || [] })

        timer.current[key] = setTimeout(() => {
          setProgress({ ...progress, [key]: 0 })
          timer.current[key] = undefined
        }, 5000)
      }
    })
  }, [batchJobIds, progress, utils.batchJob.getByIds])

  return (
    <div className="flex flex-col space-y-1">
      {batchJobs &&
        batchJobs.map((batchJob, i) => (
          <BatchJobInfo
            key={i}
            batchJob={batchJob}
            progress={progress[batchJob.id.toString()] || 0}
          />
        ))}
    </div>
  )
}

const BatchJobDetails = ({ batchJob }: { batchJob: BatchJob }) => {
  const { errorDetails } = batchJob.metadata as unknown as BatchJobMetadata

  return (
    <InfoTooltip icon={<XClose className="h-4 w-4 text-red-500" />}>
      {errorDetails && (
        <div className="flex flex-col space-y-2 text-sm text-grey-500 maxh-[250px] overflow-auto">
          {errorDetails.map(({ error, jobId, jobName }) => (
            <div key={jobId}>
              {jobName}: {error.toString()}
            </div>
          ))}
        </div>
      )}
    </InfoTooltip>
  )
}

const BatchJobInfo = ({ batchJob, progress }: { batchJob: BatchJob; progress: number }) => {
  const utils = api.useContext()
  const deleteBatchJob = api.batchJob.delete.useMutation()
  const { label } = batchJob.metadata as unknown as BatchJobMetadata

  const handleDelete = async (batchJobId: number) => {
    await deleteBatchJob.mutateAsync({ id: batchJobId })
    void utils.batchJob.getByIds.invalidate({})
  }
  return (
    <div key={batchJob.id} className="text-grey-500 text-sm group flex items-center gap-x-1">
      <hr className="w-[10px] border-t border-grey-300 dark:border-zinc-800 my-2" />

      {humanize(label || batchJob.name)}

      {batchJob.status == 'completed' && (
        <CheckCircle className="h-4 w-4 text-green-500 group-hover:hidden" />
      )}

      {progress ? <Progress value={progress} className="h-3 w-[80px]" /> : null}
      {batchJob.status == 'failed' ? (
        <>
          <BatchJobDetails batchJob={batchJob} />
          <div className="text-red-500 text-xs">{batchJob.failedCount} failed</div>
        </>
      ) : null}
      <Button
        icon={DeleteDataIcon}
        variant="ghost"
        size="xs"
        className="cursor-pointer hidden group-hover:flex"
        onClick={() => void handleDelete(batchJob.id)}
      />
    </div>
  )
}
