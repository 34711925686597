import { Button } from '@/client/components'
import { useDeleteEnvironmentForm } from './form'
import type { FormProps } from './types'

export const Form = ({ workspace, onIsOpenChange, environment }: FormProps) => {
  const { formik } = useDeleteEnvironmentForm({
    workspace,
    onIsOpenChange,
    environment,
  })
  const { handleSubmit } = formik

  return (
    <div className="flex flex-col">
      <div className="px-6 pb-3">
        <p className="text-sm text-grey-600 dark:text-zinc-600">
          Are you sure you want to delete {environment.name}?
        </p>
      </div>
      <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => {
            handleSubmit()
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}
