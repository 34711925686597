import { Dialog } from '@/client/components'
import { Form } from './Form'
import type { UseAddDataFormProps } from './Form/types'

export const AddDataModal = ({
  workspace,
  app,
  dataIds,
  onIsOpenChange,
  isOpen,
  onAdded,
}: UseAddDataFormProps & {
  isOpen: boolean
  onAdded: () => void
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          <div className="flex flex-col space-y-6">
            <Dialog.Title asChild>
              <h3 className="title3 grow px-6 pt-6">
                Add {dataIds.length > 1 ? 'logs' : 'log'} to a dataset
              </h3>
            </Dialog.Title>
            <Form
              workspace={workspace}
              app={app}
              dataIds={dataIds}
              onIsOpenChange={onIsOpenChange}
              onAdded={onAdded}
            />
          </div>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
