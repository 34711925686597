import React from 'react'
import { Button, ConfirmActionDialog, toast } from '@/client/components'
import type { ButtonProps } from '@/client/components/Button/types'
import type { DetailedContext } from '@/common/types/context'
import { api } from '@/utils'

export const RefreshContextButton = ({
  context,
  children,
  variant,
}: {
  context: DetailedContext
  children: React.ReactNode
  variant?: ButtonProps['variant']
}) => {
  const refreshContext = api.context.refresh.useMutation()
  const utils = api.useContext()

  const handleRefreshContext = async () => {
    await refreshContext.mutateAsync({ id: context.id })

    await utils.context.get.invalidate({
      id: context.id,
    })
    await utils.context.getStatus.invalidate({
      id: context.id,
    })

    toast.success({
      title: 'Context refresh started',
      description: 'Applying library settings and refreshing sources. Please wait a few minutes',
    })
  }

  return (
    <ConfirmActionDialog
      variant={'info'}
      header={`Refresh ${context.name}`}
      message={`Are you sure you want to refresh ${context.name}?`}
      onConfirm={handleRefreshContext}
    >
      {({ setOpen }) => (
        <Button variant={variant} onClick={() => setOpen(true)}>
          {children}
        </Button>
      )}
    </ConfirmActionDialog>
  )
}
