import { XClose } from '@untitled-ui/icons-react'
import { Badge, Icon } from '@/client/components'
import type { TagProps } from './types'

const Tag = ({ children, onRemove, ...props }: TagProps) => {
  return (
    <Badge {...props}>
      <div>{children}</div>

      {onRemove ? (
        <button aria-label="Remove tag" onClick={onRemove}>
          <Icon
            size="xs"
            className="opacity-50 hover:opacity-100 transition duration-200 ease-in-out"
            component={XClose}
          />
        </button>
      ) : null}
    </Badge>
  )
}

export { Tag }
