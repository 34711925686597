import { CheckCircle, Clipboard } from '@untitled-ui/icons-react'
import { useEffect, useState, type ReactElement } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import ReactMarkdown, { type Components } from 'react-markdown'
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm'
import kluCodeTheme from '@/client/components/MarkdownRenderer/CodeBlock/style'
import { cn } from '@/client/utils'
import { classNames } from '@/utils/classNames'
import { useTheme } from '../ui/context'

interface MarkdownRendererProps {
  markdownText: string
  className?: string
}

const CodeBlock: Components['code'] = ({ inline, className, children }) => {
  const match = /language-(\w+)/.exec(className || '')
  const theme = useTheme()
  return !inline && match && match.length === 2 ? (
    <div className="group relative whitespace-pre-wrap text-xs select-text font-inter">
      <div className="invisible absolute right-0 top-0 rounded-lg p-3 group-hover:visible">
        <CopyButton text={String(children).replace(/\n$/, '')} />
      </div>

      <SyntaxHighlighter
        style={theme?.state.isDark ? kluCodeTheme.darkTheme : kluCodeTheme.lightTheme}
        lineProps={{ style: { whiteSpace: 'pre-wrap' } }}
        wrapLines={true}
        language={match[1]}
        PreTag="div"
        className="rounded-lg !bg-grey-50 dark:!bg-black dark:border dark:border-zinc-800"
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    </div>
  ) : (
    // eslint-disable-next-line
    <code
      className={cn(
        className,
        'whitespace-pre-wrap rounded-lg bg-grey-100 dark:bg-transparent dark:text-white px-1.5 py-1 text-xs'
      )}
    >
      {children}
    </code>
  )
}

function CopyButton({ text }: { text: string }) {
  const [effect, setEffect] = useState(false)
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    setEffect(true)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  useEffect(() => {
    if (effect) {
      setTimeout(() => {
        setEffect(false)
      }, 1500)
    }
  }, [effect])

  return (
    <div className="flex items-center">
      <CopyToClipboard text={text} onCopy={handleCopy}>
        <button
          onClick={handleCopy}
          onAnimationEnd={() => setEffect(false)}
          className={classNames(
            effect ? ' text-grey-900' : 'hover:text-grey-900',
            'inline-flex items-center text-sm focus:outline-none'
          )}
        >
          {copied ? (
            <div className="absolute -ml-24 flex rounded-full bg-grey-50 p-2 text-xs text-green-800 transition duration-300 ease-in-out">
              Copied <CheckCircle className="ml-1 h-4 w-4" />
            </div>
          ) : (
            <span className="transition duration-300 ease-in-out"></span>
          )}
          <Clipboard className="h-4 w-4" />
        </button>
      </CopyToClipboard>
    </div>
  )
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  markdownText,
  className,
}): ReactElement => (
  <ReactMarkdown
    className={className}
    remarkPlugins={[remarkGfm]}
    // eslint-disable-next-line
    children={markdownText.trim()}
    components={{
      code: CodeBlock,
      p: ({ ...props }) => <p {...props} className="m-0 p-0" />,
      ul: ({ ...props }) => <ul {...props} className="m-0 list-outside ml-4 list-disc p-0" />,
      ol: ({ ...props }) => <ol {...props} className="m-0 list-inside list-decimal p-0" />,
      li: ({ ...props }) => <li {...props} className="leading-1 px-2" />,
      a: ({ ...props }) => <a {...props} className="text-blue-600 hover:underline" />,
      strong: ({ ...props }) => <strong {...props} className="font-bold" />,
      em: ({ ...props }) => <em {...props} className="italic" />,
      del: ({ ...props }) => <del {...props} className="line-through" />,
      h1: ({ ...props }) => <h1 {...props} className="my-1 text-2xl" />,
      h2: ({ ...props }) => <h2 {...props} className="my-1 text-xl" />,
      h3: ({ ...props }) => <h3 {...props} className="my-1 text-lg" />,
      h4: ({ ...props }) => <h4 {...props} className="text-md my-1" />,
      h5: ({ ...props }) => <h5 {...props} className="my-1 text-base" />,
      h6: ({ ...props }) => <h6 {...props} className="my-1 text-sm" />,
      hr: ({ ...props }) => (
        <hr {...props} className="dark:!border-transparent dark:!border-none" />
      ),
    }}
  />
)

export default MarkdownRenderer
