import { TremorValueEditor } from '@react-querybuilder/tremor'
import { TextInput } from '@tremor/react'
import Link from 'next/link'
import type { ValueEditorProps } from 'react-querybuilder'
import { standardClassnames } from 'react-querybuilder'
import { InfoTooltip } from '@/client/components/InfoTooltip/InfoTooltip'

export const CustomValueEditor = (props: ValueEditorProps) => {
  if (props.field === 'json_path') {
    return (
      <>
        <div className="-order-1 flex gap-1">
          <InfoTooltip>
            <span className="text-xs text-grey-400 dark:text-zinc-400">
              See:{' '}
              <Link href="https://jsonpath.com" target="_blank">
                JSONPath Documentation
              </Link>
            </span>
          </InfoTooltip>
          <TextInput
            className={standardClassnames.valueListItem}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            value={props.value?.key ?? ''}
            placeholder="$.path.to.key"
            defaultValue="$.path.to.key"
            onChange={(e) =>
              props.handleOnChange({
                ...props.value,
                key: e.target.value,
              })
            }
          />
        </div>

        <TextInput
          className={standardClassnames.valueListItem}
          placeholder="Value"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          value={props.value?.value ?? ''}
          onChange={(e) =>
            props.handleOnChange({
              ...props.value,
              value: e.target.value,
            })
          }
        />
      </>
    )
  }

  if (props.operator === 'contains_no_invalid_links') {
    return null
  }

  return <TremorValueEditor {...props} />
}
