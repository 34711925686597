import type { Action, Environment, Workspace } from '@prisma/client'
import { ArrowCircleLeft, ChevronDown } from '@untitled-ui/icons-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { StudioIcon, VersionComment } from '@/client/assets/icons/icons'
import { Button, Loader, ScrollArea } from '@/client/components'
import {
  ActionVersionDetails,
  ActionVersionEnvironmentBadge,
} from '@/client/containers/views/Studio/components/Topbar/components/PublishAction/components/PublishDialog/components/ActionVersionDetails'
import { UserAvatar } from '@/common/components'
import type {
  DetailedActionSession,
  DetailedActionVersion,
} from '@/common/components/actions/types'
import { type AppIncludingUser, type DetailedAgent } from '@/common/components/actions/types'
import { useFetchActionVersions, useFetchAgentSession, useFetchEnvironments } from '@/common/hooks'
import { dateAgo } from '@/utils'
import { EditVersion } from '../modals/versions/EditVersion'
import { RevertVersion } from '../modals/versions/RevertToVersion'
import { ActionConversationDetail } from '../sessions/ActionConversationDetail'
import { GuidCopyButton } from '../ui/GuidCopyButton'

function ActionVersionRow({
  action,
  version,
  workspace,
  environments,
  previousVersion,
  linkToVersions = false,
  openRow,
}: {
  action: Action
  version: DetailedActionVersion
  workspace: Workspace
  environments?: Environment[]
  previousVersion?: DetailedActionVersion
  linkToVersions?: boolean
  openRow?: boolean
}) {
  const router = useRouter()
  const { slug: appSlug } = router.query as { slug: string }
  const [open, toggle] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenRevert, setIsOpenRevert] = useState(false)

  useEffect(() => {
    if (openRow !== undefined) {
      toggle(openRow)
    }
  }, [openRow])

  return (
    <>
      <EditVersion
        workspace={workspace}
        version={version}
        isOpen={isOpenEdit}
        onIsOpenChange={setIsOpenEdit}
      />

      <RevertVersion
        workspace={workspace}
        version={version}
        isOpen={isOpenRevert}
        onIsOpenChange={setIsOpenRevert}
      />
      <tr
        key={version.id}
        className="group bg-white dark:bg-black hover:bg-grey-25 h-20 dark:hover:bg-zinc-900"
      >
        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium">
          <div className="flex flex-row space-x-3 items-center">
            <UserAvatar className="w-5 h-5" user={version.createdBy} /> {openRow}
            <span className="text-xs bg-grey-50 ring-1 ring-black/10 dark:bg-zinc-900 dark:text-white dark:ring-zinc-800 rounded-md p-2">
              Version {version.version_number}{' '}
              {version.name && version.name !== action.name ? `(${version.name})` : ``}
            </span>
          </div>
        </td>
        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm">
          <ActionVersionEnvironmentBadge version={version} />
        </td>
        {/* <td className="hidden whitespace-nowrap py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600 sm:table-cell">
          <div className="flex flex-col items-start"></div>
        </td> */}

        <td className="hidden whitespace-nowrap py-4 pl-6 pr-3 text-xs text-grey-600 dark:text-zinc-600 sm:table-cell">
          {dateAgo(new Date(version.createdAt))}
        </td>
        <td className="whitespace-nowrap w-48 py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600">
          <div className="flex space-x-3 justify-end">
            <div className="space-x-3 transition-all duration-300">
              <Button
                variant="outline"
                startIcon={VersionComment}
                onClick={() => {
                  setIsOpenEdit(true)
                }}
                tooltip={{
                  content: 'Edit version notes',
                  side: 'bottom',
                  delayDuration: 0,
                }}
              />

              {linkToVersions && (
                <Button
                  variant="outline"
                  startIcon={StudioIcon}
                  onClick={() => {
                    void router.push(
                      `/${workspace.slug}/apps/${appSlug}/actions/${action.slug}/studio?tab=chat&version=${version.guid}`
                    )
                  }}
                  tooltip={{
                    content: 'Open in Studio',
                    side: 'bottom',
                    delayDuration: 0,
                  }}
                />
              )}

              <Button
                disabled={openRow}
                variant="outline"
                startIcon={ArrowCircleLeft}
                onClick={() => {
                  setIsOpenRevert((prev) => !prev)
                }}
                tooltip={{
                  content: openRow ? null : 'Revert to this version',
                  side: 'bottom',
                  delayDuration: 0,
                }}
              />

              <Button
                variant="outline"
                startIcon={ChevronDown}
                onClick={() => {
                  toggle(!open)
                }}
              />
            </div>
          </div>
        </td>
      </tr>
      {open && (
        <tr className="group bg-white dark:bg-black hover:bg-grey-25 dark:hover:bg-zinc-900/50">
          <td className="py-4" colSpan={5}>
            <ScrollArea className="w-full" viewportClassName="w-full">
              <ActionVersionDetails
                version={version}
                action={action}
                workspace={workspace}
                onIsOpenChange={() => null}
                environments={environments ? environments : []}
                previousVersion={previousVersion}
              />
            </ScrollArea>
          </td>
        </tr>
      )}
    </>
  )
}

export function ActionVersionTable({
  action,
  workspace,
  linkToVersions,
  shortList,
  versions: versionsProp,
  ...rest
}: {
  action: Action
  workspace: Workspace
  linkToVersions?: boolean
  shortList?: boolean
  versions?: DetailedActionVersion[]
  openRow?: boolean
  openRowForLatest?: boolean
}) {
  const { versions: fetchVersions, isLoading } = useFetchActionVersions(action.id)
  const versions = versionsProp || fetchVersions
  const { environments, isLoading: isLoadingEnvironments } = useFetchEnvironments(workspace.id)

  if (isLoading || isLoadingEnvironments) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
      </div>
    )
  }

  return (
    <div className="flex items-center justify-between">
      <div className="w-full">
        <div className="inline-block w-full align-middle">
          <div className="">
            <table className="w-full min-w-full divide-y divide-grey-300 dark:divide-zinc-800">
              <thead className="bg-grey-50 dark:bg-zinc-900 hidden">
                <tr>
                  <th
                    scope="col"
                    className="w-1/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 whitespace-nowrap"
                  >
                    Version #
                  </th>
                  <th
                    scope="col"
                    className="w-2/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600"
                  >
                    Environment
                  </th>
                  {/* <th
                    scope="col"
                    className="hidden w-2/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 whitespace-nowrap sm:table-cell"
                  >
                    Created By
                  </th> */}
                  <th
                    scope="col"
                    className="hidden w-2/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 sm:table-cell"
                  >
                    Date Created
                  </th>
                  {/* <th
                    scope="col"
                    className="hidden w-2/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 sm:table-cell"
                  >
                    Last Request
                  </th> */}
                  <th
                    scope="col"
                    className="py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grey-200 dark:divide-zinc-800">
                {versions &&
                  (() => {
                    // Goal is to display up to 6 versions instead of dozens
                    // At most will display: production environments, 2 latest, 1 original
                    // This makes it easy to infer changes and who the OG creator was

                    // Find the first version by version number
                    const firstVersion = [...versions].sort(
                      (a, b) => a.version_number - b.version_number
                    )[0]!

                    // Find the three most recent versions
                    const threeMostRecentVersions = [...versions]
                      .sort(
                        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                      )
                      .slice(0, 3)

                    // Filter versions with environments
                    const versionsWithEnvironments = versions
                      .filter((version) => version.deployments.length > 0)
                      .slice(0, 5)
                    // Concatenate the first version with the other arrays, removing duplicates
                    const combinedVersions = [
                      ...versionsWithEnvironments,
                      ...threeMostRecentVersions,
                      firstVersion,
                    ]
                      .filter(
                        (version, index, self) =>
                          version && self.findIndex((v) => v.id === version.id) === index
                      )
                      .sort(
                        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                      )

                    return (shortList ? combinedVersions : versions).map((version, index, self) => {
                      const isLatestVersion = index === 0

                      return (
                        <ActionVersionRow
                          key={version.id}
                          action={action}
                          workspace={workspace}
                          version={version}
                          environments={environments}
                          previousVersion={self.find(
                            (v) => v.version_number === version.version_number - 1
                          )}
                          linkToVersions={linkToVersions}
                          openRow={rest.openRow ?? rest.openRowForLatest ? isLatestVersion : false} // Open the row only for the latest version
                        />
                      )
                    })
                  })()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

function ActionSessionDetails({ session }: { session: DetailedActionSession }) {
  const { actionSession, isLoading } = useFetchAgentSession(session.id)

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
      </div>
    )
  }

  if (!actionSession) {
    return null
  }

  return <ActionConversationDetail session={actionSession} />
}

function ActionSessionRow({
  action,
  session,
  workspace,
  appSlug,
}: {
  action: Action
  session: DetailedActionSession
  workspace: Workspace
  appSlug: string
  openRow?: boolean
}) {
  const router = useRouter()
  const [open, toggle] = useState(false)

  const data = session.Data
  if (data.length === 0) {
    return null
  }

  return (
    <>
      <tr className="group bg-white dark:bg-black hover:bg-grey-25 h-20 dark:hover:bg-zinc-900">
        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium">
          <div className="flex flex-row space-x-4 items-center">
            {session.createdBy ? (
              <UserAvatar className="w-5 h-5" user={session.createdBy} />
            ) : (
              <div>{session.extUserId}</div>
            )}
            <span className="text-xs bg-grey-50 dark:bg-zinc-900 dark:ring-zinc-800 flex flex-row gap-1 ring-1 ring-black/10 rounded-md p-2">
              <p>{session.name ?? 'Session'}</p>
              <GuidCopyButton guid={session.guid} alignment="left" />
            </span>
          </div>
        </td>
        <td className="hidden whitespace-nowrap py-4 pl-6 pr-3 text-xs text-grey-600 dark:text-zinc-600 sm:table-cell">
          Updated {dateAgo(new Date(session.updatedAt))} ⋅ Created{' '}
          {dateAgo(new Date(session.createdAt))}
        </td>
        <td className="whitespace-nowrap w-48 py-4 pl-6 pr-3 text-sm text-grey-600 dark:text-zinc-600">
          <div className="flex space-x-3 justify-end">
            <div className="space-x-3 transition-all duration-300">
              <Button
                variant="outline"
                startIcon={StudioIcon}
                onClick={() => {
                  void router.push(
                    `/${workspace.slug}/apps/${appSlug}/actions/${action.slug}/studio?tab=chat&sessionId=${data[0]!.sessionId!}`
                  )
                }}
                tooltip={{
                  content: 'Open in Studio',
                  side: 'bottom',
                  delayDuration: 0,
                }}
              />

              <Button
                variant="outline"
                startIcon={ChevronDown}
                onClick={() => {
                  toggle(!open)
                }}
              />
            </div>
          </div>
        </td>
      </tr>
      {open && (
        <tr className="group bg-white dark:bg-black hover:bg-grey-25 dark:hover:bg-zinc-900/50">
          <td className="py-4" colSpan={5}>
            <ScrollArea className="w-full">
              <ActionSessionDetails session={session} />
            </ScrollArea>
          </td>
        </tr>
      )}
    </>
  )
}

export function ActionSessionsTable({
  action,
  workspace,
  sessions,
  appSlug,
}: {
  action: Action
  workspace: Workspace
  sessions: DetailedActionSession[]
  appSlug: string
}) {
  return (
    <div className="flex items-center justify-between">
      <div className="w-full">
        <div className="inline-block w-full align-middle">
          <div className="">
            <table className="w-full min-w-full divide-y divide-grey-300 dark:divide-zinc-800">
              <thead className="bg-grey-50 dark:bg-zinc-900 hidden">
                <tr>
                  <th
                    scope="col"
                    className="w-1/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 whitespace-nowrap"
                  >
                    Session #
                  </th>
                  <th
                    scope="col"
                    className="hidden w-2/12 py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600 sm:table-cell"
                  >
                    Date Created
                  </th>
                  <th
                    scope="col"
                    className="py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grey-200 dark:divide-zinc-800">
                {sessions.map((session) => (
                  <ActionSessionRow
                    key={session.id}
                    action={action}
                    session={session}
                    workspace={workspace}
                    appSlug={appSlug}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ActionCard({
  action,
  app,
  workspace,
  linkToVersions,
}: {
  action: DetailedAgent
  app: AppIncludingUser
  workspace: Workspace
  linkToVersions?: boolean
}) {
  return (
    <>
      <div className="rounded-lg border dark:border-zinc-800 bg-white dark:bg-black shadow-sm">
        {/**
      {promptActionTypes.includes(action.action_type) && <PromptActionCard action={action} />}
      */}

        <div className="overflow-hidden shadow ring-1 ring-grey-200 dark:ring-zinc-800 sm:rounded-lg">
          <Link href={`/${workspace.slug}/apps/${app.slug}/actions/${action.slug}/versions`}>
            <p className="min-w-full divide-y divide-grey-300 rounded-t-md bg-grey-25 p-3 text-xs font-medium uppercase tracking-wider text-primary-800 dark:divide-zinc-800">
              Versions
            </p>
          </Link>
          <div className="bg-white dark:bg-black">
            <ActionVersionTable
              action={action}
              workspace={workspace}
              linkToVersions={linkToVersions}
            />
          </div>
        </div>
      </div>
    </>
  )
}
