import React from 'react'
import { ActionTypeEnum, type ActionContentType } from '@/client/types'
import { env } from '@/env.mjs'
import type { ActionInputs, UseActionContentProps } from './types'

export const useActionContent = ({ action }: Pick<UseActionContentProps, 'action'>) => {
  const contentTypeOptions = React.useMemo(() => {
    let options = []

    if (action.action_type !== ActionTypeEnum.WORKFLOW) {
      options.push({
        value: 'url',
        label: 'Shareable URL',
      })
    }

    options = options.concat(
      {
        value: 'Python',
        label: 'Python SDK',
      },
      {
        value: 'Typescript',
        label: 'Typescript SDK',
      },
      {
        value: 'bash',
        label: 'cURL Request',
      }
    )

    return options
  }, [action])

  const actionInputs: ActionInputs = {}

  const promptVariables = action.prompt.match(/{{(.*?)}}/g)?.map((match) => match.slice(2, -2))
  if (promptVariables && promptVariables?.length > 0) {
    promptVariables.forEach((variable) => {
      actionInputs[variable] = `${variable.toUpperCase()} VALUE`
    })
  } else {
    actionInputs['input'] = 'YOUR PROMPT GOES HERE'
  }

  const [contentType, setContentType] = React.useState<ActionContentType>('url')

  const curlActionInputs = actionInputs['input']
    ? `"INPUT VALUE"`
    : `{${Object.keys(actionInputs)
        .map((k) => `  "${k}": "${k.toUpperCase()} VALUE"`)
        .join(',\n')}}`

  const curlCommand = `curl --location --request POST '${env.NEXT_PUBLIC_API_URL}/v1/actions/${action.guid}/prompt' \\
--header 'Authorization: Bearer YOUR_TOKEN' \\
--header 'Content-Type: application/json' \\
--data-raw '{
  "input": ${curlActionInputs}
  }'`

  const pythonActionInputs = actionInputs['input']
    ? `"INPUT VALUE"`
    : `{\n${Object.keys(actionInputs)
        .map((k) => `        "${k}": "${k.toUpperCase()} VALUE"`)
        .join(',\n')}\n    }`

  const pythonSDKLine = `from klu import Klu
 
client = Klu('apikey') 
result = await client.actions.prompt(
    '${action.guid}', 
    ${pythonActionInputs}
)
`
  const tsActionInputs = actionInputs['input']
    ? `"INPUT VALUE"`
    : `{\n${Object.keys(actionInputs)
        .map((k) => `        "${k}": "${k.toUpperCase()} VALUE"`)
        .join(',\n')}\n    }`
  const typescriptString = `import Klu from '@kluai/core';

const klu = new Klu('apikey');

async function prompt() {
  const result = await klu.actions.prompt(
    '${action.guid}', 
    ${tsActionInputs}
  )
  console.log(result.msg);
};

prompt();
`

  const shareableUrl = `https://app.klu.ai/public/actions/${action.guid}`

  const CONTENT = {
    Python: pythonSDKLine,
    Typescript: typescriptString,
    bash: curlCommand,
    url: shareableUrl,
  }

  const getContent = () => {
    return CONTENT[contentType]
  }

  const getMarkdownContent = () => {
    const content = getContent()
    if (!content) {
      return ''
    }

    return `
\`\`\`${contentType.toLocaleLowerCase()}
${content}
\`\`\`
`
  }

  const getGUID = () => {
    return `\`\`\`md\n${action.guid}`
  }

  return {
    contentTypeOptions,
    contentType,
    setContentType,
    getMarkdownContent,
    getGUID,
  }
}
