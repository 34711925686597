import type { Workspace } from '@prisma/client'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Button, Dialog, toast } from '@/client/components'
import { Select } from '@/common/components/forms'
import { fetchUserById, useFetchMembers, useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function TransferWorkspaceForm({
  setOpen,
  workspace,
}: {
  workspace: Workspace
  setOpen: (val: boolean) => void
}) {
  const [loading, setLoading] = useState(false)
  const transferWorkspace = api.workspace.transfer.useMutation()
  const { members, isLoading: isLoadingMembers } = useFetchMembers(workspace.id)
  const utils = api.useContext()
  const { user } = useUser()

  async function onSuccess(newOwner: string) {
    await utils.workspace.getAll.invalidate()
    toast.success({
      title: `Workspace transfered to ${newOwner}`,
      description: 'Successfully transfered the workspace',
    })
    if (user) {
      const id = user.id ? user.id : ''
      const email = user.email ? user.email : ''
      const uname = user.name ? user.name : ''
      identify_and_group(id, email, uname, workspace?.slug)
    }
    track(EVENT_NAMES.workspace_transfered, {
      'Workspace ID': workspace.id,
      'Workspace Name': workspace.name,
      'User ID': user?.id,
      'User Email': user?.email,
      'User Name': user?.name,
    })
    setOpen(false)
    // await router.push(`/`)
  }

  const schema = z.object({
    newOwnerId: z.number().min(1, {
      message: 'Select a new owner',
    }),
  })

  type FormSchema = z.infer<typeof schema>

  const initialValues: FormSchema = {
    newOwnerId: 0,
  }

  const formikConfig = {
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values: FormSchema) => {
      setLoading(true)
      await transferWorkspace.mutateAsync(
        { workspaceId: workspace.id, ...values },
        {
          onSuccess: () => {
            const { user } = fetchUserById(values.newOwnerId.toString())
            void onSuccess(user?.name || 'member')
          },
          onError: (err) => {
            toast.error({
              title: 'Error',
              description: err.message,
            })
          },
          onSettled: () => {
            setLoading(false)
          },
        }
      )
    },
  }

  return (
    <Formik
      initialValues={formikConfig.initialValues}
      onSubmit={formikConfig.onSubmit}
      validationSchema={formikConfig.validationSchema}
      validateOnBlur={false}
    >
      {(formik) => (
        <Form>
          <div className="bg-white dark:bg-black px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <h3 className="text-lg font-medium leading-6 text-black dark:text-white">
                Transfer {workspace.name}
              </h3>
            </div>
            <div>
              <div className="text-center sm:mt-0 sm:text-left">
                <div className="mb-6 mt-6">
                  <p className="text-sm text-grey-700 dark:text-zinc-400">Select a new owner</p>
                  <div className="mt-2">
                    {isLoadingMembers ? (
                      <div className="flex animate-pulse space-x-4">
                        <div className="flex-1 space-y-2">
                          <div className="h-2 rounded bg-grey-700 dark:bg-zinc-800"></div>
                          <div className="h-2 rounded bg-grey-700 dark:bg-zinc-800"></div>
                        </div>
                      </div>
                    ) : (
                      <Select.Single
                        defaultOption={null}
                        fieldName="newOwnerId"
                        options={
                          members
                            ? members
                                .filter(
                                  (m) =>
                                    m.user.id !== workspace.createdById && m.user.id !== user?.id
                                )
                                .map((m) => {
                                  return {
                                    id: m.id,
                                    name: m.user.name
                                      ? m.user.name
                                      : m.user.email
                                        ? m.user.email
                                        : '',
                                    avatar_url: m.user.image ? m.user.image : '',
                                  }
                                })
                            : []
                        }
                        showAvatars
                        formik={formik}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 bg-grey-50 px-4 py-5 dark:bg-black sm:flex sm:px-6">
            <Button
              variant="outline"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-grey-300 bg-white px-4 py-2 text-base font-medium shadow-sm hover:border-grey-400 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={loading} loading={loading}>
              {loading ? 'Transfering...' : 'Transfer'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export const TransferWorkspaceModal = ({
  open,
  setOpen,
  workspace,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  workspace: Workspace
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          <TransferWorkspaceForm setOpen={setOpen} workspace={workspace} />
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
