import { Link03, Trash03 } from '@untitled-ui/icons-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import {
  Badge,
  Button,
  ConfirmActionDialog,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/client/components'
import { CopyButton } from '@/client/components/MarkdownRenderer/CodeBlock/components'
import { useFetchEvalRun } from '@/common/hooks/evalRuns'
import type { EvalRunMetadata } from '@/common/types/eval'
import { EvalRunDetails } from '@/pages/[workspaceSlug]/apps/[slug]/evaluate/evals/[evalSlug]/runs/[runId]'
import type { ActionVersionMetadata } from '@/server/service/types'
import { dateAgo } from '@/utils'
import { api } from '@/utils/api'

export const EvalRunDrawer = ({
  evalRunId,
  open,
  setOpen,
  workspaceId,
}: {
  evalRunId: number
  open: boolean
  setOpen: (val: boolean) => void
  workspaceId: number
}) => {
  const { evalRun, isLoading } = useFetchEvalRun(evalRunId, workspaceId)
  const deleteEvalRun = api.evalRun.delete.useMutation()
  const utils = api.useContext()
  const router = useRouter()
  useEffect(() => {
    if (open) {
      const query = { ...router.query, evalRunId }
      void router.push({ pathname: router.pathname, query })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evalRunId])

  if (!evalRun) return null

  const handleDelete = async (id: number, evalId: number) => {
    await deleteEvalRun.mutateAsync({ id })
    await utils.evalRun.getAll.invalidate({ evalId })
    setOpen(false)
  }

  const permalink = `${window.location.href}?evalRunId=${evalRun.id}`

  const handleOpenChange = (val: boolean) => {
    if (val) {
      const query = { ...router.query, evalRunId }

      void router.push({ pathname: router.pathname, query })
    } else {
      delete router.query.evalRunId
      void router.push(router)
    }
    setOpen(val)
  }

  const actionVersion = evalRun.actionVersion

  const { modelName } = actionVersion.metadata as ActionVersionMetadata

  const { evalTypesMetadata } = evalRun.metadata as EvalRunMetadata

  return (
    <Sheet open={open} onOpenChange={handleOpenChange}>
      <SheetContent side="eval">
        <SheetHeader>
          <SheetTitle className="flex flex-col gap-2">
            <div className="flex flex-col gap-3 group">
              <Link href={permalink}>Run #{evalRun.run_number}</Link>

              <div className="text-sm font-normal flex items-center gap-2">
                {modelName}

                <Badge>v{actionVersion.version_number}</Badge>

                {evalTypesMetadata &&
                  Object.entries(evalTypesMetadata).map(([key, value]) => {
                    return (
                      <Badge key={key} color="default">
                        {value.name}
                        {value.variables?.map((variable) => {
                          return (
                            <span key={variable.name} className="text-grey-400 dark:text-zinc-600">
                              {variable.name}: {variable.value as string}
                            </span>
                          )
                        })}
                      </Badge>
                    )
                  })}

                <div className="text-grey-400 dark:text-zinc-600">
                  {dateAgo(new Date(evalRun.createdAt))}
                </div>

                <div className="flex gap-2 opacity-50 hover:opacity-100 transition duration-300 ease-in-out">
                  <ConfirmActionDialog
                    header={`Delete ${evalRun.Eval.name}`}
                    message={`Are you sure you want to delete ${evalRun.Eval.name}?`}
                    onConfirm={async () => await handleDelete(evalRun.id, evalRun.evalId)}
                    text={{
                      confirm: 'Delete eval run',
                      loading: 'Deleting...',
                    }}
                  >
                    {({ setOpen }) => (
                      <Button
                        icon={Trash03}
                        variant="ghost"
                        size="xs"
                        onClick={() => setOpen(true)}
                      />
                    )}
                  </ConfirmActionDialog>
                  <div className="cursor-pointer items-center justify-center">
                    <CopyButton text={permalink} icon={Link03} />
                  </div>
                </div>
              </div>
            </div>
          </SheetTitle>
          <SheetDescription>
            {evalRun && !isLoading ? (
              <div className="pt-3">
                <EvalRunDetails evalRun={evalRun} />
              </div>
            ) : (
              <div className="animate-pulse rounded-full h-10 bg-grey-200 dark:bg-zinc-800 flex space-x-4" />
            )}
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}
