import { useFetchContextSource } from '@/common/hooks/contextSource'
import type { ContextNode } from '../tables/v2/types'

export const RetrievedDocumentNode = ({
  node,
  showContextLibraryName,
}: {
  node: ContextNode
  showContextLibraryName?: boolean
}) => {
  const { contextSource } = useFetchContextSource(node.contextSourceId)

  return (
    <div key={node.guid} className="flex flex-col gap-2">
      <div className="flex gap-2 items-center ">
        <dd className="text-xs text-grey-800 dark:text-zinc-600 col-span-2">
          {(node.score * 100).toFixed(2)}%
        </dd>
        <dt className="text-xs text-grey-700 dark:text-zinc-400 col-span-2">Similarity</dt>
      </div>

      <div className="flex gap-2 items-center ">
        <dd className="text-xs text-grey-700 dark:text-zinc-400 col-span-2 font-mono line-clamp-3">
          {node.text}
        </dd>
      </div>

      {showContextLibraryName && contextSource && (
        <div className="flex gap-2 items-center ">
          <dd className="text-xs text-grey-800 dark:text-zinc-600 col-span-2">Source</dd>
          <dt className="text-xs text-grey-700 dark:text-zinc-400 col-span-2">
            {contextSource.name}
          </dt>
        </div>
      )}
    </div>
  )
}
