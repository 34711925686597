/* eslint-disable @next/next/no-img-element */
import { Listbox, Transition } from '@headlessui/react'
import { Check, ChevronSelectorVertical } from '@untitled-ui/icons-react'
import type { FormikProps } from 'formik'
import { Fragment, useEffect, useState } from 'react'
import { classNames } from '@/utils'
import { Logo } from '../ui'
import type { SelectOption } from './SearchSelect'

export function Single({
  formik,
  options,
  fieldName,
  defaultOption,
  placeholder = 'Select an option',
  onSelect,
  position = 'below',
  showAvatars = false,
  disabled = false,
}: {
  options: SelectOption[]
  fieldName: string
  defaultOption: SelectOption | null
  showAvatars?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any
  placeholder?: string
  position?: 'above' | 'below'
  onSelect?: (option: SelectOption | null) => void
  disabled?: boolean
}) {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(defaultOption)

  useEffect(() => {
    if (selectedOption !== null || selectedOption === defaultOption) return
    setSelectedOption(defaultOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOption])

  function handleChangeOption(option: SelectOption | null) {
    setSelectedOption(option)
    onSelect && onSelect(option)
    if (formik) {
      if (option) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        formik.setFieldValue(fieldName, option.id)
      }
    }
  }

  return (
    <Listbox value={selectedOption} onChange={handleChangeOption} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="relative w-full">
            {showAvatars ? (
              <Listbox.Button
                className={classNames(
                  'w-full rounded-md bg-white dark:bg-black py-1.5 pl-3 pr-10 text-left text-grey-900 dark:text-zinc-300 shadow-sm ring-1 ring-inset ring-grey-300 dark:ring-zinc-700 hover:ring-grey-400 dark:hover:ring-white focus:outline-none focus:ring-2 focus:ring-grey-500 dark:focus:ring-white sm:text-sm sm:leading-6',
                  disabled
                    ? 'cursor-not-allowed bg-grey-100 dark:bg-zinc-800'
                    : 'cursor-pointer border-grey-400 bg-white dark:bg-black hover:border-grey-400 dark:hover:border-zinc-600'
                )}
              >
                <span className="flex items-center">
                  {selectedOption ? (
                    <>
                      {selectedOption?.avatar_url ? (
                        <img
                          src={selectedOption?.avatar_url}
                          alt=""
                          className="h-5 w-5 flex-shrink-0 rounded-full"
                        />
                      ) : (
                        <div className="flex flex-shrink-0 items-center rounded-full bg-black dark:bg-white p-1">
                          <Logo className="h-3 w-3" />
                        </div>
                      )}
                      <span className="ml-3 block truncate">{selectedOption.name}</span>
                    </>
                  ) : (
                    placeholder
                  )}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronSelectorVertical
                    className="h-5 w-5 text-grey-400 dark:text-zinc-600"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            ) : (
              <Listbox.Button
                className={classNames(
                  'relative w-full rounded-md py-1.5 pl-3 pr-10 text-left text-grey-900 dark:text-zinc-400 shadow-sm ring-1 ring-inset ring-grey-300 dark:ring-zinc-800 focus:outline-none focus:ring-2 focus:ring-grey-500 dark:focus:ring-white sm:text-sm sm:leading-6',
                  disabled
                    ? 'w-full cursor-not-allowed bg-grey-100 dark:bg-zinc-800'
                    : 'w-full cursor-pointer bg-white dark:bg-black'
                )}
              >
                <span className={'block truncate'}>
                  {selectedOption ? selectedOption.name : placeholder}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronSelectorVertical
                    className="h-5 w-5 text-grey-400 dark:text-zinc-600"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            )}

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  position === 'above' ? 'bottom-0 mb-12 ' : 'mt-1',
                  'absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-black py-1 text-base shadow-lg ring-1 ring-black dark:ring-zinc-700 ring-opacity-5 focus:outline-none sm:text-sm'
                )}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'bg-grey-50 dark:bg-zinc-800 text-grey-900 dark:text-zinc-400 hover:bg-grey-100 dark:hover:bg-zinc-900'
                          : 'text-grey-900 dark:text-zinc-400',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        {showAvatars ? (
                          <>
                            <div className="flex items-center">
                              {option.avatar_url ? (
                                <img
                                  src={option.avatar_url}
                                  alt=""
                                  className="h-5 w-5 flex-shrink-0 rounded-full"
                                />
                              ) : (
                                <div className="flex flex-shrink-0 items-center rounded-full bg-black dark:bg-zinc-800 p-1">
                                  <Logo className="h-3 w-3" />
                                </div>
                              )}
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'ml-3 block truncate'
                                )}
                              >
                                {option.name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active
                                    ? 'text-grey-900 dark:text-zinc-400'
                                    : 'text-grey-600 dark:text-zinc-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <Check className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {option.name}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white dark:text-zinc-400' : '',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <Check className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export function Multiple<T>({
  formik,
  options,
  fieldName,
  defaultOptions,
  placeholder = 'Select an option',
  onSelect,
  position = 'below',
  disabled = false,
}: {
  options: SelectOption[]
  fieldName: string
  defaultOptions: SelectOption[]
  formik?: FormikProps<T>
  placeholder?: string
  position?: 'above' | 'below'
  onSelect?: (option: SelectOption[] | null) => void
  disabled?: boolean
}) {
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(defaultOptions)

  function handleChangeOption(options: SelectOption[]) {
    setSelectedOptions(options)
    onSelect && onSelect(options)
    if (!formik) return

    void formik.setValues((prev: T) => ({ ...prev, [fieldName]: options.map((o) => o.id) }))
  }

  return (
    <Listbox
      value={selectedOptions}
      onChange={handleChangeOption}
      disabled={disabled}
      by={(a, b) => (a !== null && b !== null ? a.id === b.id && a.name === b.name : false)}
      multiple
    >
      {({ open }) => (
        <>
          <div className="relative w-full">
            <Listbox.Button
              className={classNames(
                'relative w-full rounded-md py-1.5 pl-3 pr-10 text-left text-grey-900 dark:text-zinc-300 shadow-sm ring-1 ring-inset ring-grey-300 dark:ring-zinc-700 focus:outline-none focus:ring-2 focus:ring-grey-500 dark:focus:ring-white sm:text-sm sm:leading-6',
                disabled
                  ? 'w-full cursor-not-allowed bg-grey-100 dark:bg-zinc-800'
                  : 'w-full cursor-pointer bg-white dark:bg-black'
              )}
            >
              <span className={'block truncate'}>
                {selectedOptions && selectedOptions.length > 0
                  ? selectedOptions.map((x) => x.name).join(', ')
                  : placeholder}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronSelectorVertical
                  className="w-5 h-5 text-grey-400 dark:text-zinc-600"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  position === 'above' ? 'bottom-0 mb-12 ' : 'mt-1',
                  'absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-black py-1 text-base shadow-lg ring-1 ring-black dark:ring-zinc-700 ring-opacity-5 focus:outline-none sm:text-sm'
                )}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'bg-grey-50 dark:bg-zinc-900 text-grey-900 dark:text-white hover:bg-grey-100 dark:hover:bg-zinc-700'
                          : 'text-grey-900 dark:text-zinc-400',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-black dark:text-zinc-400' : '',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <Check className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
