import { json } from '@codemirror/lang-json'
import type { EvalDataType } from '@prisma/client'
import CodeMirror from '@uiw/react-codemirror'
import { getIn } from 'formik'
import type { FormikProps } from 'formik'
import React, { useEffect } from 'react'
import type { RuleGroupType } from 'react-querybuilder'
import { Select, Slider } from '@/client/components'
import useCodeMirrorTheme from '@/client/components/Codemirror/hook'
import { cn } from '@/client/utils'
import type { EvalTypeVariable } from '@/common/types/eval'
import { EvalAssertionField } from '../../evals/AssertionsField'
import type { EvalSchema } from '../../evals/schema'

export const EvalVariableInput = ({
  evalTypeId,
  formik,
  definition,
  evalTypeIndex,
  variableIndex,
  evalDataType,
}: {
  evalTypeId: number
  formik: FormikProps<EvalSchema>
  definition: EvalTypeVariable
  evalTypeIndex: number
  variableIndex: number
  evalDataType?: EvalDataType
}) => {
  const { extensions, theme } = useCodeMirrorTheme({ extensions: [json()] })
  const variableKey = `${evalTypeIndex}:${evalTypeId}-${definition.name}`

  const value = formik.values.evalTypes?.[evalTypeIndex]?.metadata?.variables?.[variableIndex]
    ?.value as string

  let initialSliderValue: number[] = []
  if (definition.type === 'number') {
    initialSliderValue = [parseInt(value || '50')]
  }
  // infer the variable type if not set
  if (!definition.type) {
    if (typeof value === 'number') {
      definition.type = 'number'
    } else if (typeof value === 'string') {
      // if text includes line breaks, make it a textarea
      if (value?.includes('\n')) {
        definition.type = 'text'
      }
    } else if (typeof value === 'object') {
      // this is BS
      definition.type = 'assertions'
    }
  }

  useEffect(() => {
    if (definition.value) {
      formik
        .setFieldValue(
          `evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`,
          definition.value
        )
        .catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definition.value, variableKey])

  useEffect(() => {
    if (initialSliderValue.length > 0) {
      formik
        .setFieldValue(
          `evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`,
          initialSliderValue[0]
        )
        .catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (definition.type === 'select' && !value) {
      formik
        .setFieldValue(
          `evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`,
          'default'
        )
        .catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div key={variableKey}>
      <div className="flex flex-col gap-1 mt-2">
        <label className="text-sm text-gray-500">{definition.name}</label>
        {definition.type == 'text' ? (
          <div className="flex w-full max-w-lg rounded-md shadow-sm">
            <div className="border p-[1px] block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus:ring-white">
              <CodeMirror
                id={`evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`}
                height={'250px'}
                value={
                  formik.values.evalTypes?.[evalTypeIndex]?.metadata?.variables?.[variableIndex]
                    ?.value as string
                }
                onChange={(value: string) => {
                  formik.handleChange({
                    target: {
                      name: `evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`,
                      value,
                    },
                  })
                }}
                extensions={extensions}
                theme={theme}
              />
            </div>
          </div>
        ) : definition.type == 'assertions' ? (
          <>
            <EvalAssertionField
              evalDataType={evalDataType}
              value={
                formik.values.evalTypes?.[evalTypeIndex]?.metadata?.variables?.[variableIndex]
                  ?.value as unknown as RuleGroupType
              }
              onChange={(value) => {
                formik
                  .setFieldValue(
                    `evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`,
                    value
                  )
                  .catch(console.error)
              }}
            />
          </>
        ) : definition.type == 'select' && definition.options ? (
          <>
            <Select.Field
              id={`evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`}
              onChange={(value) => {
                formik
                  .setFieldValue(
                    `evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`,
                    value
                  )
                  .catch(console.error)
              }}
              value={
                (formik.values.evalTypes?.[evalTypeIndex]?.metadata?.variables?.[variableIndex]
                  ?.value as string) || 'default'
              }
              className="block w-full min-w-0 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
              options={definition.options}
            />
          </>
        ) : (
          <div className="flex max-w-lg rounded-md shadow-sm">
            <input
              type="text"
              autoComplete="off"
              name={`evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`}
              id={`evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={
                formik.values.evalTypes?.[evalTypeIndex]?.metadata?.variables?.[variableIndex]
                  ?.value as string
              }
              className="flex-1 block w-full min-w-0 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus:ring-white"
            />
          </div>
        )}
        {definition.type === 'number' && (
          <Slider
            min={definition.min}
            max={definition.max}
            step={1}
            value={initialSliderValue}
            onValueChange={([value]) => {
              formik
                .setFieldValue(
                  `evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`,
                  value
                )
                .catch(console.error)
            }}
          />
        )}
        {definition.description && (
          <p className="text-xs text-gray-500">{definition.description}</p>
        )}
        <EvalVariableFieldError
          fieldName={`evalTypes.${evalTypeIndex}.metadata.variables.${variableIndex}.value`}
          formik={formik}
        />
      </div>
    </div>
  )
}

// this is a workaround because when you add the EvalTypes to the formik.values.evalTypes,
// the evalType.metadata doesn't contain a `value`, so it breaks the formik touched logic
// and when submitting, the input value isn't marked as touched, so validation doesn't show
// below - we just check the submitCount and show the error if it's greater than 0
function EvalVariableFieldError({ fieldName, formik }: { fieldName: string; formik: any }) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const hasError =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (getIn(formik.errors, fieldName) && getIn(formik.touched, fieldName)) || formik.submitCount > 0

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const error = getIn(formik.errors, fieldName)
  const errorContent = typeof error === 'string' ? error : JSON.stringify(error)
  return (
    <>
      {hasError ? (
        <p
          className={cn('text-xs text-red-500 font-regular', {
            'mt-2': errorContent && errorContent.length > 0,
          })}
        >
          {errorContent}
        </p>
      ) : null}
    </>
  )
}
