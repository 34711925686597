import { toast } from '@/client/components'
import type { Document } from '@/common/types/document'
import { api } from '@/utils'

export const useDeleteDocument = ({
  document,
  callback,
}: {
  document: Document
  callback: {
    onSuccess?: () => void
  }
}) => {
  const deleteDocument = api.document.delete.useMutation()
  const utils = api.useContext()

  async function handleDelete() {
    try {
      await deleteDocument.mutateAsync({ documentId: document.id })
      await utils.document.getAllPaginated.invalidate()
      callback.onSuccess?.()
      toast.success({
        title: 'Document deleted from context',
        description: 'Document deleted successfully',
      })
    } catch (e) {
      console.error(e)
    }
  }
  return { handleDelete }
}
