import type { App, Dataset } from '@prisma/client'
import { useState } from 'react'
import { Button, Dialog } from '@/client/components'
import { useWorkspace } from '../../ui/context'
import { DeleteDatasetForm } from './Form'
import type { UseDeleteDatasetFormProps } from './Form/types'

export const DeleteDataset = ({ app, dataset }: { app: App; dataset: Dataset }) => {
  const [isOpen, onIsOpenChange] = useState(false)
  const workspace = useWorkspace()

  return (
    <>
      <Button
        variant={'outline'}
        onClick={() => {
          onIsOpenChange(true)
        }}
      >
        Delete this Dataset?
      </Button>
      <DeleteDatasetModal
        dataset={dataset}
        workspace={workspace}
        app={app}
        isOpen={isOpen}
        onIsOpenChange={onIsOpenChange}
      />
    </>
  )
}

export const DeleteDatasetModal = ({
  dataset,
  workspace,
  app,
  isOpen,
  onIsOpenChange,
}: UseDeleteDatasetFormProps) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-zinc-200 dark:bg-black dark:ring-zinc-800">
        <Dialog.Header>
          <div className="flex flex-col space-y-6">
            <div className="px-6 pt-6 flex items-center justify-between">
              <div className="flex flex-col">
                <Dialog.Title asChild>
                  <h3 className="title3 grow">Delete Dataset</h3>
                </Dialog.Title>
                <p className="text-grey-800 dark:text-zinc-500 mt-2 text-sm">
                  Are you sure you want to delete dataset {dataset.name}?
                </p>
              </div>
            </div>
            <DeleteDatasetForm
              dataset={dataset}
              workspace={workspace}
              app={app}
              onIsOpenChange={onIsOpenChange}
              isOpen={isOpen}
            />
          </div>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
