import type { Context, Workspace } from '@prisma/client'
import { useRouter } from 'next/router'
import { toast } from '@/client/components'
import { api } from '@/utils'

export const useDeleteContext = ({
  workspace,
  context,
}: {
  workspace: Workspace
  context: Context
}) => {
  const deleteIndex = api.context.delete.useMutation()
  const utils = api.useContext()
  const router = useRouter()

  async function handleDelete() {
    await deleteIndex.mutateAsync(
      { contextId: context.id },
      {
        onSuccess: () => {
          toast.success({
            title: `Context ${context.name} deleted`,
            description: 'Context deleted successfully',
          })
        },
        onError: (err) => {
          toast.error({
            title: 'Error',
            description: err.message,
          })
        },
      }
    )
    await utils.context.getAll.invalidate()
    await router.push(`/${workspace.slug}/context`)
  }

  return { handleDelete }
}
