import { Command as CommandPrimitive } from 'cmdk'
import React from 'react'
import { cn } from '@/client/utils'
import type { CommandGroupProps } from './types'

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  CommandGroupProps
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      `
        overflow-hidden
        p-1
        text-grey-800
        dark:text-grey-50
        [&_[cmdk-group-heading]]:body3
        [&_[cmdk-group-heading]]:px-2
        [&_[cmdk-group-heading]]:py-1.5
        [&_[cmdk-group-heading]]:font-medium
        [&_[cmdk-group-heading]]:text-grey-500
        dark:[&_[cmdk-group-heading]]:text-zinc-400
      `,
      className
    )}
    {...props}
  />
))

CommandGroup.displayName = CommandPrimitive.Group.displayName

export { CommandGroup }
