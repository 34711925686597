import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { App, EvalsOnEvalTypes, Workspace } from '@prisma/client';
import { ChevronRight, Plus } from '@untitled-ui/icons-react';
import { useFormik } from 'formik';
import { type NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Button, ConfirmActionDialog, Slider, Switch, toast } from '@/client/components';
import { Loader, OpenMobileSidebarButton, Sidebar } from '@/common/components';
import { evalSchemaDefinition, type EvalSchema } from '@/common/components/evals/schema';
import { VersionSelector } from '@/common/components/evals/VersionSelector';
import { AddEvalTypeInput } from '@/common/components/evalType/AddEvalTypeInput';
import { EvalTypeForm } from '@/common/components/evalType/EvalTypeForm';
import { FieldError } from '@/common/components/forms';
import { useDeleteEval } from '@/common/components/modals/evals/useDeleteEval';
import { App404 } from '@/common/components/notFound/404';
import { useWorkspace } from '@/common/components/ui/context';
import { useCurrentApp, useFetchEvalTypes } from '@/common/hooks';
import { useFetchEvalBySlug } from '@/common/hooks/evals';
import { withApp } from '@/common/lib/ssr';
import type { DetailedEval, EvalTypeMetadata } from '@/common/types/eval';
import type { WorkspaceAppEvalIParams } from '@/common/types/queryparams';
import { api } from '@/utils';
import { EvalNavTabs } from '.';
export function EvalUpdateForm({
  evalRecord,
  app
}: {
  evalRecord: DetailedEval;
  app: App;
}) {
  const updateEval = api.eval.update.useMutation();
  const removeEvalsOnEvalType = api.evalsOnEvalTypes.remove.useMutation();
  const utils = api.useContext();
  const [isLoading, setLoading] = useState(false);
  const [selectedEvalType, setSelectedEvalType] = useState<number | null>(null);
  const {
    evalTypes,
    isLoading: isLoadingEvalTypes
  } = useFetchEvalTypes({
    dataType: evalRecord.dataType
  });
  const formik = useFormik<EvalSchema>({
    initialValues: {
      name: evalRecord.name,
      dataType: evalRecord.dataType,
      actionId: evalRecord.actionId,
      evalTypes: evalRecord.evalTypes?.map(e => {
        return {
          id: e.id,
          evalId: e.evalId,
          evalTypeId: e.evalTypeId,
          name: e.name || e.evalType.name,
          metadata: (e.metadata as EvalTypeMetadata)
        };
      }) || [],
      datasetId: evalRecord.datasetId || undefined,
      samplingRate: evalRecord.samplingRate || 1,
      alertOnFail: evalRecord.alertOnFail,
      versionId: evalRecord.versionId || undefined
    },
    validationSchema: toFormikValidationSchema(evalSchemaDefinition),
    onSubmit: async values => {
      try {
        setLoading(true);
        const updatedEval = await updateEval.mutateAsync({
          ...values,
          evalId: evalRecord.id,
          appId: app.id
        });
        await utils.eval.get.invalidate({
          id: evalRecord.id
        });
        await utils.eval.getAllForAction.invalidate({});
        setLoading(false);
        formik.setFieldValue('evalTypes', updatedEval.evalTypes).catch(console.error);
        toast.success({
          title: 'Eval updated',
          description: "Successfully updated the eval's details"
        });
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  });
  const addEvalType = (evalTypeId: number) => {
    const evalType = evalTypes?.find(evalType => evalType.id === evalTypeId);
    if (!evalType) {
      console.log('evalType not found', evalTypeId);
      return;
    }
    formik.setFieldValue('evalTypes', [...formik.values.evalTypes, ...[{
      id: Math.random() * 100,
      newRecord: true,
      evalTypeId: evalType.id,
      evalId: evalRecord.id,
      metadata: (evalType.metadata as EvalTypeMetadata),
      name: evalType.name
    }]]).catch(console.error);
  };
  const removeEvalType = (id: number) => {
    const evalType = formik.values.evalTypes.find(e => e.id === id);
    const evalTypes = formik.values.evalTypes.filter(e => e.id !== id);
    formik.setFieldValue('evalTypes', evalTypes).catch(console.error);
    if (!evalType?.hasOwnProperty('newRecord')) {
      void removeEvalsOnEvalType.mutateAsync({
        id
      });
    }
  };
  if (isLoadingEvalTypes || !evalTypes) {
    return <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
      </div>;
  }
  return <form onSubmit={formik.handleSubmit}>
      <div className="rounded-md border border-grey-200 dark:border-zinc-800  bg-white p-6 shadow-sm dark:bg-black space-y-6">
        <h3 className="text-lg font-medium leading-6">Update {evalRecord.name}</h3>

        <div className="max-w-lg space-y-2">
          <p className="text-sm text-grey-700 dark:text-zinc-400">Name</p>
          <input type="text" name="name" id="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} placeholder="Name" className="block w-full min-w-0 flex-1 rounded-md bg:white border-grey-300 focus:border-grey-500 focus:ring-grey-500 dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus-ring-white dark:focus:border-white sm:text-sm" />
          <FieldError fieldName="name" formik={formik} />
        </div>

        {evalRecord.action && <div className="max-w-lg space-y-2">
            <p className="text-sm text-grey-700 dark:text-zinc-400">Action Version</p>

            <VersionSelector action={evalRecord.action} versionId={formik.values.versionId || undefined} setVersion={val => {
          void formik.setFieldValue('versionId', val).catch(console.error);
        }} />

            <FieldError fieldName="name" formik={formik} />
          </div>}

        <div className="grid max-w-full gap-3 place-items-stretch">
          {formik.values.evalTypes.map((evalType, i) => <EvalTypeForm key={`${evalType.evalTypeId}-${i}`} evalTypeIndex={i} evalType={(evalType as EvalsOnEvalTypes)} formik={formik} removeEvalType={removeEvalType} evalDataType={evalRecord.dataType} />)}
        </div>

        {evalRecord.dataType === 'TEST' && <div className="max-w-full flex items-center gap-3">
            <AddEvalTypeInput evalTypes={evalTypes} selectedEvalType={selectedEvalType} setSelectedEvalType={setSelectedEvalType} addEvalType={addEvalType}>
              <Button type="button" startIcon={Plus} variant="outline" className="w-full">
                Add evaluator
              </Button>
            </AddEvalTypeInput>
          </div>}

        {evalRecord.dataType === 'LIVE' && <div className="mt-6 mb-6">
            <p className="text-sm text-grey-700 dark:text-zinc-400">Sampling Rate</p>
            <p className="text-xs text-grey-500 dark:text-zinc-500">
              The percentage of generations that will be evaluated (0-100)
            </p>
            <div className="mt-2 flex gap-4 items-center">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <input type="number" name="samplingRate" id="samplingRate" max={100} min={0} onChange={e => {
              formik.setFieldValue(`samplingRate`, parseInt(e.target.value || '100') / 100).catch(console.error);
            }} onBlur={formik.handleBlur} value={(formik.values.samplingRate || 1) * 100} placeholder="Sampling Rate" className="block w-full min-w-0 flex-1 rounded-md bg:white border-grey-300 focus:border-grey-500 focus:ring-grey-500 dark:bg-black dark:border-zinc-800 dark:text-white dark:placeholder:text-zinc-600 dark:focus-ring-white dark:focus:border-white sm:text-sm" />
              </div>
              <Slider min={0} max={100} step={1} value={[(formik.values.samplingRate || 1) * 100]} onValueChange={([value]) => {
            formik.setFieldValue(`samplingRate`, (value || 100) / 100).catch(console.error);
          }} />

              <FieldError fieldName="samplingRate" formik={formik} />
            </div>

            <div className="mt-2 flex gap-2 items-center">
              <Switch id="alertOnFail" checked={formik.values.alertOnFail} onCheckedChange={() => {
            void formik.setFieldValue('alertOnFail', !formik.values.alertOnFail);
          }} />
              <label htmlFor="alertOnFail">Alert on failure?</label>
            </div>
          </div>}

        <div className="pt-3">
          <Button disabled={isLoading} type="submit">
            Save
          </Button>
        </div>
      </div>
    </form>;
}
function EvalDangerZone(props: {
  evalRecord: DetailedEval;
  app: App;
  workspace: Workspace;
}) {
  const {
    handleDelete
  } = useDeleteEval(props);
  return <div className="mt-3 pt-3">
      <div className="rounded-md border border-grey-200 dark:border-zinc-800 shadow-sm bg-white p-6 dark:bg-black">
        <div className="">
          <div className="">
            <div>
              <h3 className="text-lg font-medium leading-6">Danger Zone</h3>
              <p className="mt-1 max-w-2xl text-sm text-grey-500 dark:text-zinc-500">
                Destructive Eval options
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start pt-3">
          <ConfirmActionDialog header={`Delete ${props.evalRecord.name}`} message={`Are you sure you want to delete ${props.evalRecord.name}?`} onConfirm={handleDelete} text={{
          confirm: 'Delete',
          loading: 'Deleting...'
        }}>
            {({
            setOpen
          }) => <Button variant="outline" onClick={() => setOpen(true)}>
                {`Delete ${props.evalRecord.name}`}
              </Button>}
          </ConfirmActionDialog>
          <p className="ml-3 rounded-lg bg-grey-50 ring-1 ring-grey-100 dark:bg-zinc-900 dark:ring-zinc-800 dark:text-zinc-400 p-2 px-3 font-mono text-xs">
            Warning: This cannot be undone, proceed with caution
          </p>
        </div>
      </div>
    </div>;
}
const EvalUpdatePage: NextPage = () => {
  const router = useRouter();
  const {
    evalSlug,
    workspaceSlug
  } = (router.query as WorkspaceAppEvalIParams);
  const {
    app
  } = useCurrentApp();
  const {
    eval: evalRecord,
    isLoading
  } = useFetchEvalBySlug(evalSlug, workspaceSlug);
  const workspace = useWorkspace();
  if (isLoading) {
    return <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
      </div>;
  }
  if (!evalRecord || !app || !workspace) {
    return null;
  }
  return <>
      <Head>
        <title>{`${evalRecord.name}`} / Edit Details</title>
        <meta name="description" content={`${evalRecord.name}`} />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-grey-200 dark:border-zinc-800 bg-white dark:bg-black bg-opacity-50 backdrop-blur">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-4 sm:px-6">
            <OpenMobileSidebarButton />
            <div className="flex flex-1 items-center">
              <h1 className="min-w-fit text-xl font-semibold text-grey-800 dark:text-white items-center flex gap-1">
                <Link href={`/${workspace.slug}/apps/${app.slug}/evaluate`} className="">
                  Evaluate
                </Link>
                <ChevronRight className="h-5 w-5 mx-2 text-grey-400" aria-hidden="true" />
                {evalRecord?.name}
              </h1>
            </div>
            <div className="flex items-center space-x-4"></div>
          </div>
        </div>
        <main>
          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            <EvalNavTabs selected={'edit'} />
            <div className="py-6">
              <EvalUpdateForm evalRecord={evalRecord} app={app} />
              {workspace && app && <EvalDangerZone evalRecord={evalRecord} app={app} workspace={workspace} />}
            </div>
          </div>
        </main>
      </div>
    </>;
};
const EvalPageDetail404Wrapper: NextPage = ({
  dne,
  ...props
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <App404 />;
  }
  return <Sidebar>
      <EvalUpdatePage {...props} />
    </Sidebar>;
};
export default _withSuperJSONPage(EvalPageDetail404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withApp(), []);